export default {
    "name": "ModelsForManufacturer",
    "kind": "HoudiniQuery",
    "hash": "16cdf38206ee11050037ec5745cd9807f8897d068de9c4a4dfcbb8e6437eabc3",

    "raw": `query ModelsForManufacturer($manufacturerId: Int!, $inventoryTypeId: Int) {
  manufacturer(id: $manufacturerId) {
    models(inventoryTypeId: $inventoryTypeId) {
      id
      name
      active
      defaultShippingDimensions {
        height
        length
        measurementUnit
        weight
        weightUnit
        width
      }
      inventoryTypeId
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "manufacturer": {
                "type": "Manufacturer",
                "keyRaw": "manufacturer(id: $manufacturerId)",

                "selection": {
                    "fields": {
                        "models": {
                            "type": "Model",
                            "keyRaw": "models(inventoryTypeId: $inventoryTypeId)",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "defaultShippingDimensions": {
                                        "type": "Dimensions",
                                        "keyRaw": "defaultShippingDimensions",

                                        "selection": {
                                            "fields": {
                                                "height": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "height",
                                                    "visible": true
                                                },

                                                "length": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "length",
                                                    "visible": true
                                                },

                                                "measurementUnit": {
                                                    "type": "DimensionsMeasurementUnit",
                                                    "keyRaw": "measurementUnit",
                                                    "visible": true
                                                },

                                                "weight": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "weight",
                                                    "visible": true
                                                },

                                                "weightUnit": {
                                                    "type": "DimensionsWeightUnit",
                                                    "keyRaw": "weightUnit",
                                                    "visible": true
                                                },

                                                "width": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "width",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "inventoryTypeId": {
                                        "type": "UInt",
                                        "keyRaw": "inventoryTypeId",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "manufacturerId": "Int",
            "inventoryTypeId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=8506db4e1d697dad415d8fc7dfb423b7cc86b438ade0245c7b0f59019199f22c";