export default {
    "name": "LIB_settingValues",
    "kind": "HoudiniQuery",
    "hash": "30157c04c80ac52c9e2864c461c6463dc8333e8805d3764b713e5d77bcd106c8",

    "raw": `query LIB_settingValues {
  userSettings {
    parts {
      clearScreenOnSave
      defaultNewInventoryPrintTags
      showSkuField
      showTagNumberWhenCreating
    }
  }
  settingValues {
    inventory {
      defaultNonReplenishablePartsArePublic
      categoryEditable
      categoryRequired
      categoryVisible
      categoryLogsChanges
      conditionEditable
      conditionRequired
      conditionVisible
      conditionLogsChanges
      coreCostEditable
      coreCostRequired
      coreCostVisible
      coreCostLogsChanges
      costEditable
      costRequired
      costVisible
      costLogsChanges
      defaultDaysToReturn
      defaultDaysToReturnCore
      defaultDaysToReturnCoreToVendor
      defaultDaysToReturnToVendor
      defaultLocationName
      defaultQuantityForMisc
      defaultQuantityForReplenishable
      defaultQuantityForStandard
      defaultReplenishablePartsArePublic
      defaultReturnable
      defaultGlCategoryId
      defaultNonReplenishablePartsArePublic
      defaultReturnableToVendor
      distributorCorePriceEditable
      distributorCorePriceRequired
      distributorCorePriceVisible
      distributorCorePriceLogsChanges
      distributorPriceEditable
      distributorPriceRequired
      distributorPriceVisible
      distributorPriceLogsChanges
      glCategoryEditable
      glCategoryRequired
      glCategoryVisible
      glCategoryLogsChanges
      inventoryTypeEditable
      inventoryTypeRequired
      inventoryTypeVisible
      inventoryTypeLogsChanges
      jobberCorePriceEditable
      jobberCorePriceRequired
      jobberCorePriceVisible
      jobberCorePriceLogsChanges
      jobberPriceEditable
      jobberPriceRequired
      jobberPriceVisible
      jobberPriceLogsChanges
      listPriceEditable
      listPriceRequired
      listPriceVisible
      listPriceLogsChanges
      oemNumberEditable
      oemNumberRequired
      oemNumberVisible
      oemNumberLogsChanges
      retailPriceEditable
      retailPriceRequired
      retailPriceVisible
      retailPriceLogsChanges
      retailCorePriceEditable
      retailCorePriceRequired
      retailCorePriceVisible
      retailCorePriceLogsChanges
      sideEditable
      sideRequired
      sideVisible
      sideLogsChanges
      userStatusEditable
      userStatusRequired
      userStatusVisible
      userStatusLogsChanges
      varianceLocationName
      vendorEditable
      vendorRequired
      vendorVisible
      vendorLogsChanges
      wholesalePriceEditable
      wholesalePriceRequired
      wholesalePriceVisible
      wholesalePriceLogsChanges
      wholesaleCorePriceEditable
      wholesaleCorePriceRequired
      wholesaleCorePriceVisible
      wholesaleCorePriceLogsChanges
    }
    location {
      enforceLocationHierarchy
    }
    accounting {
      useGlAccounting
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userSettings": {
                "type": "UserSettings",
                "keyRaw": "userSettings",

                "selection": {
                    "fields": {
                        "parts": {
                            "type": "UserSettingsInventoryCategory",
                            "keyRaw": "parts",

                            "selection": {
                                "fields": {
                                    "clearScreenOnSave": {
                                        "type": "Boolean",
                                        "keyRaw": "clearScreenOnSave",
                                        "visible": true
                                    },

                                    "defaultNewInventoryPrintTags": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultNewInventoryPrintTags",
                                        "visible": true
                                    },

                                    "showSkuField": {
                                        "type": "Boolean",
                                        "keyRaw": "showSkuField",
                                        "visible": true
                                    },

                                    "showTagNumberWhenCreating": {
                                        "type": "Boolean",
                                        "keyRaw": "showTagNumberWhenCreating",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "settingValues": {
                "type": "SettingValues",
                "keyRaw": "settingValues",

                "selection": {
                    "fields": {
                        "inventory": {
                            "type": "InventorySettingValues",
                            "keyRaw": "inventory",

                            "selection": {
                                "fields": {
                                    "defaultNonReplenishablePartsArePublic": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultNonReplenishablePartsArePublic",
                                        "visible": true
                                    },

                                    "categoryEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "categoryEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "categoryRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "categoryRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "categoryVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "categoryVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "categoryLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "categoryLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "conditionEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "conditionRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "conditionVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "conditionLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "costEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "costRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "costVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "costLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturn": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturn",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturnCore": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturnCore",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturnCoreToVendor": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturnCoreToVendor",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultDaysToReturnToVendor": {
                                        "type": "Int",
                                        "keyRaw": "defaultDaysToReturnToVendor",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultLocationName": {
                                        "type": "String",
                                        "keyRaw": "defaultLocationName",
                                        "visible": true
                                    },

                                    "defaultQuantityForMisc": {
                                        "type": "Decimal6",
                                        "keyRaw": "defaultQuantityForMisc",
                                        "visible": true
                                    },

                                    "defaultQuantityForReplenishable": {
                                        "type": "Decimal6",
                                        "keyRaw": "defaultQuantityForReplenishable",
                                        "visible": true
                                    },

                                    "defaultQuantityForStandard": {
                                        "type": "Decimal6",
                                        "keyRaw": "defaultQuantityForStandard",
                                        "visible": true
                                    },

                                    "defaultReplenishablePartsArePublic": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultReplenishablePartsArePublic",
                                        "visible": true
                                    },

                                    "defaultReturnable": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultReturnable",
                                        "visible": true
                                    },

                                    "defaultGlCategoryId": {
                                        "type": "Int",
                                        "keyRaw": "defaultGlCategoryId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultReturnableToVendor": {
                                        "type": "Boolean",
                                        "keyRaw": "defaultReturnableToVendor",
                                        "visible": true
                                    },

                                    "distributorCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorCorePriceLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorCorePriceLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "glCategoryEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "glCategoryEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "glCategoryRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "glCategoryRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "glCategoryVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "glCategoryVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "glCategoryLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "glCategoryLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inventoryTypeEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "inventoryTypeEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inventoryTypeRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "inventoryTypeRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inventoryTypeVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "inventoryTypeVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inventoryTypeLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "inventoryTypeLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "oemNumberEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "oemNumberEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "oemNumberRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "oemNumberRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "oemNumberVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "oemNumberVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "oemNumberLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "oemNumberLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sideEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "sideEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sideRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "sideRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sideVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "sideVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "sideLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "sideLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "userStatusEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "userStatusEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "userStatusRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "userStatusRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "userStatusVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "userStatusVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "userStatusLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "userStatusLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "varianceLocationName": {
                                        "type": "String",
                                        "keyRaw": "varianceLocationName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vendorEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "vendorEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vendorRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "vendorRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vendorVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "vendorVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vendorLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "vendorLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceLogsChanges": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceLogsChanges",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "location": {
                            "type": "LocationSettingValues",
                            "keyRaw": "location",

                            "selection": {
                                "fields": {
                                    "enforceLocationHierarchy": {
                                        "type": "Boolean",
                                        "keyRaw": "enforceLocationHierarchy",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "accounting": {
                            "type": "AccountingSettingValues",
                            "keyRaw": "accounting",

                            "selection": {
                                "fields": {
                                    "useGlAccounting": {
                                        "type": "Boolean",
                                        "keyRaw": "useGlAccounting",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c371813aa44694111191e5b5b28d9e046af0259608ee5d3b82984b78a174808b";