export default {
    "name": "CreateVehicle",
    "kind": "HoudiniMutation",
    "hash": "e9fed20904509024f2df3401c1fb387c209e6cab97f853e2e585fe4bdca36e5b",

    "raw": `mutation CreateVehicle($input: NewVehicle!) {
  createVehicle(input: $input) {
    id
    storeId
    lastChangedDate
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createVehicle": {
                "type": "Vehicle",
                "keyRaw": "createVehicle(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "storeId": {
                            "type": "UInt",
                            "keyRaw": "storeId",
                            "visible": true
                        },

                        "lastChangedDate": {
                            "type": "DateTime",
                            "keyRaw": "lastChangedDate",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewVehicle"
        },

        "types": {
            "NestedNewVehicleBidFee": {
                "adjustmentTypeId": "UInt",
                "amount": "Decimal2",
                "notes": "String"
            },

            "NestedNewVehicleBidItem": {
                "category": "String",
                "cost": "Decimal2",
                "description": "String",
                "interchangeNumber": "String",
                "inventoryTypeId": "UInt",
                "listPrice": "Decimal2",
                "manufacturerId": "UInt",
                "modelId": "UInt",
                "parentManufacturerId": "UInt",
                "parentModelId": "UInt",
                "quantity": "Decimal6",
                "retailCorePrice": "Decimal2",
                "retailPrice": "Decimal2",
                "salesValue": "Decimal2",
                "side": "SideEnum",
                "subInterchangeNumber": "String",
                "webValue": "Decimal2",
                "wholesalePrice": "Decimal2"
            },

            "NestedNewVehicleBid": {
                "amount": "Decimal2",
                "assignedToUserId": "Int",
                "fees": "NestedNewVehicleBidFee",
                "items": "NestedNewVehicleBidItem",
                "name": "String",
                "sellerContact": "String",
                "sellerInfo": "String",
                "sellerPhone": "String",
                "status": "VehicleBidStatus",
                "vendorId": "UInt"
            },

            "InventoryOptionValueUpdate": {
                "inventoryOptionId": "Int",
                "value": "String"
            },

            "SaleClassReference": {
                "code": "String",
                "id": "Int"
            },

            "NewVehicle": {
                "adjuster": "String",
                "bids": "NestedNewVehicleBid",
                "bodyStyle": "String",
                "category": "String",
                "claimNumber": "String",
                "condition": "String",
                "description": "String",
                "dismantleDate": "Date",
                "dismantled": "Boolean",
                "dismantler": "Int",
                "externalColor": "String",
                "externalColorCode": "String",
                "fileNumber": "String",
                "glCategoryId": "Int",
                "inStock": "Boolean",
                "internalColor": "String",
                "internalColorCode": "String",
                "inventoryGlCategoryId": "Int",
                "inventoryTypeId": "UInt",
                "jobberPrice": "Decimal2",
                "junkingNumber": "String",
                "listPrice": "Decimal2",
                "location": "String",
                "make": "String",
                "mileage": "Int",
                "model": "String",
                "notes": "String",
                "optionValues": "InventoryOptionValueUpdate",
                "pickUpLocation": "String",
                "public": "Boolean",
                "purchaseDate": "Date",
                "purchasedFrom": "String",
                "receivedDate": "Date",
                "retailPrice": "Decimal2",
                "saleClass": "SaleClassReference",
                "sellerContact": "String",
                "sellerInfo": "String",
                "sellerPhone": "String",
                "status": "StatusEnum",
                "stockNumber": "String",
                "storeId": "UInt",
                "taxable": "Boolean",
                "titleNumber": "String",
                "titleReceivedDate": "DateTime",
                "titleState": "String",
                "titleStatus": "String",
                "titleType": "String",
                "userStatus": "String",
                "vin": "String",
                "wholesalePrice": "Decimal2",
                "year": "UInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=9a9efbc642d4701d090d2406e6211441b8c3b57e335f0e16ffdf486215e63428";