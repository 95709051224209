import type { AppContext } from 'types/common'
import type { vehicleScreenBasicQuery$result, vehicleInventorySettingValues$result } from '$houdini'
import type { WritableDeep, Merge } from 'type-fest'
export type Vehicle = WritableDeep<
	Merge<NonNullable<vehicleScreenBasicQuery$result['vehicle']>, { id: number | null; wholeUnit: Merge<NonNullable<vehicleScreenBasicQuery$result['vehicle']>['wholeUnit'], { quantity: number }> }>
>
export type SettingValues = vehicleInventorySettingValues$result['settingValues']

import { flattenInventoryOptionForDisplay } from 'utility/load-part'
import { klona } from 'klona'
import component from './Basic.svelte'
import { graphql } from '$houdini'
import {
	loadInventoryTypes,
	loadPartManufacturersForInventoryType,
	loadLocationsForStore,
	loadCategories,
	loadUserAccounts,
	loadUserStatuses,
	loadPartModelsForInventoryTypeAndManufacturer,
} from 'utility/search-fields'
import { getSession } from 'stores/session'

export function getDefaultVehicle(store: Vehicle['store'], settingValues: SettingValues | undefined): Vehicle {
	const defaultUnitTypeId = settingValues?.vehicle.defaultVehicleType ?? 9000

	return {
		id: null,
		vin: '',
		manufacturer: null,
		model: null,
		year: null,
		mileage: 0,
		description: '',
		notes: '',
		stockNumber: '',
		location: '',
		store,
		status: 'A',
		dismantled: false,
		bodyStyle: '',
		categoryType: {
			id: null,
			description: '',
			name: '',
		},
		externalColor: '',
		externalColorCode: '',
		internalColor: '',
		internalColorCode: '',
		userStatus: '',
		titleNumber: '',
		titleStatus: '',
		titleState: '',
		titleType: '',
		claimNumber: '',
		purchasedFrom: '',
		sellerContact: '',
		sellerPhone: '',
		sellerInfo: '',
		topImage: null,
		dismantler: null,
		purchaseDate: null,
		receivedDate: null,
		titleReceivedDate: null,
		adjuster: '',
		dismantleDate: null,
		fileNumber: '',
		junkingNumber: '',
		pickUpLocation: '',
		glCategory: settingValues?.vehicle.defaultGlCategory ?? null,
		inventoryGlCategory: settingValues?.vehicle.defaultComponentGlCategory ?? null,
		wholeUnit: {
			inventoryType: {
				id: defaultUnitTypeId,
				setId: defaultUnitTypeId,
				name: '',
				categories: [],
				typeData1History: [],
				typeData2History: [],
				typeData3History: [],
				typeData4History: [],
			},
			model: null,
			category: null,
			coreCost: '0',
			cost: '0',
			partNumber: '',
			defaultVendor: null,
			distributorPrice: '0',
			distributorCorePrice: '0',
			jobberPrice: '0',
			jobberCorePrice: '0',
			retailPrice: '0',
			retailCorePrice: '0',
			listPrice: '0',
			wholesalePrice: '0',
			wholesaleCorePrice: '0',
			inventoryTypeId: defaultUnitTypeId,
			vehicleYear: null,
			vehicleMake: null,
			vehicleModel: null,
			storeId: 1,
			condition: null,
			saleClass: null,
			public: true,
			description: '',
			replenishable: false,
			averageCost: '0',
			averageCoreCost: '0',
			coreRequired: false,
			coreRequiredToVendor: false,
			deplete: true,
			quantity: 1,
			typeField1: {
				label: '',
				data: '',
			},
			typeField2: {
				label: '',
				data: '',
			},
			typeField3: {
				label: '',
				data: '',
			},
			typeField4: {
				label: '',
				data: '',
			},
			optionValues: [],
		},
		inStock: false,
		sellerIsCompany: true,
		taxable: settingValues?.vehicle.unitsAreTaxableByDefault ?? true,
		public: true,
	}
}

const vehicleScreenBasicQuery = graphql(`
	query VehicleBasicStageOne($inventoryTypeId: Int!) {
		inventoryType(id: $inventoryTypeId) {
			options {
				id
				name
				defaultChoice
				required
				rank
				dataType
				public
				manufacturer {
					id
					name
				}
				model {
					id
					name
				}
				category {
					id
					name
				}
				inventoryType {
					id
				}
				choices {
					default
					label
					rank
				}
			}
		}
		states {
			stateName
			stateAbbreviation
			country
		}
		saleClasses {
			code
			name
		}
		inventoryConditions
		vehicleColors
		vehicleColorCodes
		glCategories {
			id
			name
		}
	}
`)

export const vehicleQuery = graphql(`
	query vehicleScreenBasicQuery($vehicleId: Int!) {
		vehicle(id: $vehicleId) {
			id
			vin
			manufacturer {
				id
				name
			}
			model {
				id
				name
				active
			}
			year
			mileage
			description
			notes
			stockNumber
			location
			store {
				id
				name
				code
			}
			status
			dismantled
			bodyStyle
			categoryType {
				id
				name
				description
			}
			externalColor
			externalColorCode
			internalColor
			internalColorCode
			userStatus
			titleNumber
			titleStatus
			titleState
			titleType
			titleReceivedDate
			claimNumber
			purchasedFrom
			adjuster
			dismantleDate
			fileNumber
			junkingNumber
			pickUpLocation
			sellerContact
			sellerPhone
			sellerInfo
			topImage {
				id
				name
				path
				host
				url
			}
			dismantler {
				id
				name
			}
			purchaseDate
			receivedDate
			glCategory {
				id
				name
			}
			inventoryGlCategory {
				id
				name
			}
			wholeUnit {
				inventoryType {
					id
					setId
					name
					categories {
						id
						name
					}
					typeData1History
					typeData2History
					typeData3History
					typeData4History
				}
				model {
					id
					name
					active
				}
				category {
					id
					name
					description
				}
				coreCost
				cost
				partNumber
				defaultVendor {
					id
				}
				distributorPrice
				distributorCorePrice
				jobberPrice
				jobberCorePrice
				retailPrice
				retailCorePrice
				listPrice
				wholesalePrice
				wholesaleCorePrice
				inventoryTypeId
				vehicleYear
				vehicleMake
				vehicleModel
				storeId
				condition
				saleClass {
					code
					name
				}
				public
				description
				replenishable
				averageCost
				averageCoreCost
				coreRequired
				coreRequiredToVendor
				deplete
				quantity
				typeField1 {
					label
					data
				}
				typeField2 {
					label
					data
				}
				typeField3 {
					label
					data
				}
				typeField4 {
					label
					data
				}
				optionValues {
					id
					optionId
					value
				}
			}
			taxable
			public
			inStock
			sellerIsCompany
		}
	}
`)

const vehicleSettingValuesQuery = graphql(`
	query vehicleInventorySettingValues {
		settingValues {
			inventory {
				categoryRequired
				conditionRequired
				costVisible
				costEditable
				costRequired
				coreCostVisible
				coreCostEditable
				coreCostRequired
				distributorPriceVisible
				distributorPriceEditable
				distributorCorePriceVisible
				distributorCorePriceEditable
				distributorPriceRequired
				jobberPriceVisible
				jobberPriceEditable
				jobberPriceRequired
				jobberCorePriceVisible
				jobberCorePriceEditable
				jobberCorePriceRequired
				wholesalePriceVisible
				wholesalePriceEditable
				wholesalePriceRequired
				wholesaleCorePriceVisible
				wholesaleCorePriceEditable
				wholesaleCorePriceRequired
				retailPriceVisible
				retailPriceEditable
				retailPriceRequired
				retailCorePriceVisible
				retailCorePriceEditable
				retailCorePriceRequired
				listPriceVisible
				listPriceEditable
				listPriceRequired
			}
			vehicle {
				mileageRequired
				minVinLength
				userStatusRequired
				conditionRequired
				defaultVehicleType
				unitsAreTaxableByDefault
				defaultGlCategory {
					id
					name
				}
				defaultComponentGlCategory {
					id
					name
				}
			}
		}
	}
`)

export default function (appContext: AppContext) {
	const stateName = 'app.vehicle.basic'

	appContext.stateRouter.addState({
		name: stateName,
		route: 'basic',
		querystringParameters: ['vehicleId', 'storeId', 'lastResetTime'],
		template: {
			svelte: true,
			component,
		},
		canLeaveState(domApi) {
			// @ts-expect-error it's fine
			if (domApi.vehicleChanged) {
				return confirm('You have unsaved changes. Are you sure you want to leave?')
			}
			return true
		},
		async resolve(_data, parameters) {
			const { authorizedStores } = getSession()

			const vehicleId = parseInt(parameters.vehicleId, 10) || undefined
			const storeId = parseInt(parameters.storeId, 10) || 1 //TODO: fallback to store 1, or do something else?
			const store = authorizedStores.find(store => store.id === storeId)
			if (!store) {
				throw {
					redirectTo: { name: 'login' },
				}
			}

			const settingValues = (await vehicleSettingValuesQuery.fetch())?.data?.settingValues
			const vehicleQueryResponse = vehicleId ? (await vehicleQuery.fetch({ variables: { vehicleId } }))?.data?.vehicle : undefined
			const loadedVehicle: Vehicle | null | undefined = vehicleQueryResponse
				? { ...vehicleQueryResponse, wholeUnit: { ...vehicleQueryResponse.wholeUnit, quantity: parseInt(vehicleQueryResponse.wholeUnit.quantity, 10) } }
				: vehicleQueryResponse

			const vehicle = vehicleId ? loadedVehicle : getDefaultVehicle(store, settingValues)

			if (vehicleId && !loadedVehicle) {
				appContext.mediator.publish('showMessage', {
					heading: 'Vehicle Not Found',
					message: 'The vehicle you are looking for does not exist',
					type: 'danger',
					time: 7,
				})
				throw {
					redirectTo: {
						name: 'app.vehicle.basic',
					},
				}
			}

			const unitTypeId = vehicle?.wholeUnit.inventoryType.id ?? settingValues?.vehicle.defaultVehicleType ?? 9000

			const [vehicleScreenBasicQueryRes, unitTypes, userStatuses, locations, userAccounts] = await Promise.all([
				vehicleScreenBasicQuery.fetch({ variables: { inventoryTypeId: unitTypeId } }),
				loadInventoryTypes({ active: true, vehicle: true }),
				loadUserStatuses('VEHICLE'),
				loadLocationsForStore(storeId),
				loadUserAccounts(),
			])

			const [vehicleMakes, vehicleModels, categories] = await Promise.all([
				loadPartManufacturersForInventoryType(unitTypeId),
				loadPartModelsForInventoryTypeAndManufacturer({ inventoryTypeId: unitTypeId, manufacturerId: vehicle?.manufacturer?.id }),
				loadCategories(unitTypeId),
			])

			const inventoryOptions = vehicleScreenBasicQueryRes.data?.inventoryType.options ?? []
			const flatOptions = inventoryOptions.map(option => {
				return flattenInventoryOptionForDisplay({
					option,
					value: vehicle?.wholeUnit.optionValues.find(optionValue => optionValue.optionId === option.id)?.value ?? '',
				})
			})

			const res = {
				vehicle,
				options: flatOptions,
				originalVehicle: Object.freeze(klona(vehicle)),
				vehicleId,
				storeId,
				stateName,
				mediator: appContext.mediator,
				settingValues,
				inventoryConditions: vehicleScreenBasicQueryRes.data?.inventoryConditions ?? [],
				glCategories: vehicleScreenBasicQueryRes.data?.glCategories ?? [],
				saleClasses: vehicleScreenBasicQueryRes.data?.saleClasses ?? [],
				colors: {
					vehicleColorCodes: vehicleScreenBasicQueryRes.data?.vehicleColorCodes ?? [],
					vehicleColors: vehicleScreenBasicQueryRes.data?.vehicleColors ?? [],
				},
				unitTypes,
				vehicleMakes,
				vehicleModels,
				userStatuses,
				locations: locations.map(location => location.name),
				userAccounts,
				categories,
				states: vehicleScreenBasicQueryRes.data?.states ?? [],
			}
			console.log(res)
			return res
		},
	})
}
