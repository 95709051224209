export default {
    "name": "vehicleScreenQuery",
    "kind": "HoudiniQuery",
    "hash": "b4acab7e1de8bff89091e87c3594620dbc2c0cbcec9879b6960a94bbcc778037",

    "raw": `query vehicleScreenQuery($vehicleId: Int!) {
  vehicle(id: $vehicleId) {
    stockNumber
    manufacturer {
      name
      id
    }
    model {
      name
      id
    }
    year
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vehicle": {
                "type": "Vehicle",
                "keyRaw": "vehicle(id: $vehicleId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "stockNumber": {
                            "type": "String",
                            "keyRaw": "stockNumber",
                            "visible": true
                        },

                        "manufacturer": {
                            "type": "Manufacturer",
                            "keyRaw": "manufacturer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "model": {
                            "type": "Model",
                            "keyRaw": "model",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "year": {
                            "type": "UInt",
                            "keyRaw": "year",
                            "nullable": true,
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "vehicleId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d3978bbea6a0404e61d38f4b7dbffcaa44cb40864eff57ed5197f991ac5fff02";