export default {
    "name": "Locations",
    "kind": "HoudiniQuery",
    "hash": "efe0f2e3c00ebe144b6530dfbc2fe851be10445707dc69e255a5378e7139f4c8",

    "raw": `query Locations($filter: LocationFilter) {
  locations(filter: $filter) {
    id
    name
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "locations": {
                "type": "Location",
                "keyRaw": "locations(filter: $filter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "LocationFilter"
        },

        "types": {
            "LocationFilter": {
                "allowInventory": "Boolean",
                "countable": "Boolean",
                "search": "String",
                "sellable": "Boolean",
                "storeIds": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=08d23e6480b3adec8f1936942f96c66c281ffa8f9ac7c48e1917ada86c5c8a51";