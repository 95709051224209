export default {
    "name": "VehicleSearchResultsData",
    "kind": "HoudiniQuery",
    "hash": "dde59046f0c3ef7e062c0fa937a55980bbcaac520dbb4d9aecbd94981fe4d385",

    "raw": `query VehicleSearchResultsData($filter: VehicleFilter, $pagination: PaginationOptions, $orderBy: [VehicleOrderBy!]) {
  vehicles(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
    totalItems
    pageInfo {
      pageSize
      pageNumber
      totalPages
    }
    items {
      id
      attachmentCount
      attachments {
        id
        public
        rank
        file {
          id
          name
          path: url
          mimetype
          size
        }
      }
      categoryType {
        name
        id
      }
      componentCount
      description
      dismantled
      dismantler {
        name
        id
      }
      manufacturer {
        name
        id
      }
      model {
        name
        id
      }
      receivedDate
      stockNumber
      storeId
      store {
        id
        name
      }
      topImage {
        path: url
        id
      }
      location
      notes
      status
      userStatus
      vin
      year
      wholeUnit {
        id
        retailPrice
        inventoryType {
          name
          typeLabel1
          typeLabel2
          typeLabel3
          typeLabel4
          id
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vehicles": {
                "type": "VehicleList",
                "keyRaw": "vehicles(filter: $filter, orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "totalItems": {
                            "type": "Int",
                            "keyRaw": "totalItems",
                            "visible": true
                        },

                        "pageInfo": {
                            "type": "PageInfo",
                            "keyRaw": "pageInfo",

                            "selection": {
                                "fields": {
                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "visible": true
                                    },

                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "items": {
                            "type": "Vehicle",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "attachmentCount": {
                                        "type": "Int",
                                        "keyRaw": "attachmentCount",
                                        "visible": true
                                    },

                                    "attachments": {
                                        "type": "VehicleAttachment",
                                        "keyRaw": "attachments",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "public": {
                                                    "type": "Boolean",
                                                    "keyRaw": "public",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "Int",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "file": {
                                                    "type": "File",
                                                    "keyRaw": "file",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "mimetype": {
                                                                "type": "String",
                                                                "keyRaw": "mimetype",
                                                                "visible": true
                                                            },

                                                            "size": {
                                                                "type": "Int",
                                                                "keyRaw": "size",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "categoryType": {
                                        "type": "Category",
                                        "keyRaw": "categoryType",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "componentCount": {
                                        "type": "Int",
                                        "keyRaw": "componentCount",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "dismantled": {
                                        "type": "Boolean",
                                        "keyRaw": "dismantled",
                                        "visible": true
                                    },

                                    "dismantler": {
                                        "type": "UserAccount",
                                        "keyRaw": "dismantler",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "manufacturer": {
                                        "type": "Manufacturer",
                                        "keyRaw": "manufacturer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "model": {
                                        "type": "Model",
                                        "keyRaw": "model",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "receivedDate": {
                                        "type": "Date",
                                        "keyRaw": "receivedDate",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "stockNumber": {
                                        "type": "String",
                                        "keyRaw": "stockNumber",
                                        "visible": true
                                    },

                                    "storeId": {
                                        "type": "UInt",
                                        "keyRaw": "storeId",
                                        "visible": true
                                    },

                                    "store": {
                                        "type": "Store",
                                        "keyRaw": "store",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "topImage": {
                                        "type": "File",
                                        "keyRaw": "topImage",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "location": {
                                        "type": "String",
                                        "keyRaw": "location",
                                        "visible": true
                                    },

                                    "notes": {
                                        "type": "String",
                                        "keyRaw": "notes",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "StatusEnum",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "userStatus": {
                                        "type": "String",
                                        "keyRaw": "userStatus",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vin": {
                                        "type": "String",
                                        "keyRaw": "vin",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "year": {
                                        "type": "UInt",
                                        "keyRaw": "year",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholeUnit": {
                                        "type": "Inventory",
                                        "keyRaw": "wholeUnit",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "retailPrice": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "retailPrice",
                                                    "visible": true
                                                },

                                                "inventoryType": {
                                                    "type": "InventoryType",
                                                    "keyRaw": "inventoryType",

                                                    "selection": {
                                                        "fields": {
                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "typeLabel1": {
                                                                "type": "String",
                                                                "keyRaw": "typeLabel1",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "typeLabel2": {
                                                                "type": "String",
                                                                "keyRaw": "typeLabel2",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "typeLabel3": {
                                                                "type": "String",
                                                                "keyRaw": "typeLabel3",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "typeLabel4": {
                                                                "type": "String",
                                                                "keyRaw": "typeLabel4",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "VehicleFilter",
            "pagination": "PaginationOptions",
            "orderBy": "VehicleOrderBy"
        },

        "types": {
            "StringFilter": {
                "eq": "String",
                "gt": "String",
                "gte": "String",
                "lt": "String",
                "lte": "String",
                "ne": "String"
            },

            "InventoryFieldFilter": {
                "name": "String",
                "value": "StringFilter"
            },

            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "Decimal2Filter": {
                "eq": "Decimal2",
                "gt": "Decimal2",
                "gte": "Decimal2",
                "lt": "Decimal2",
                "lte": "Decimal2"
            },

            "IntFilter": {
                "eq": "Int",
                "gt": "Int",
                "gte": "Int",
                "lt": "Int",
                "lte": "Int",
                "ne": "Int"
            },

            "VehicleFilter": {
                "adjuster": "String",
                "bodyStyle": "String",
                "category": "String",
                "claimNumber": "String",
                "condition": "String",
                "customFields": "InventoryFieldFilter",
                "dateEntered": "DateFilter",
                "description": "String",
                "dismantled": "Boolean",
                "dismantlerId": "UInt",
                "enteredByUserId": "UInt",
                "externalColor": "String",
                "fileNumber": "String",
                "junkingNumber": "String",
                "location": "String",
                "make": "String",
                "manufacturerId": "UInt",
                "model": "String",
                "modelId": "UInt",
                "pickupLocation": "String",
                "purchasedFrom": "String",
                "retailPrice": "Decimal2Filter",
                "search": "String",
                "sellerContact": "String",
                "sellerInfo": "String",
                "series": "String",
                "statuses": "String",
                "stockNumber": "String",
                "stores": "Int",
                "titleNumber": "String",
                "titleStatus": "String",
                "titleType": "String",
                "type": "String",
                "typeField1": "String",
                "typeField2": "String",
                "typeField3": "String",
                "typeField4": "String",
                "typeIds": "Int",
                "userStatuses": "String",
                "vehicleId": "UInt",
                "vin": "String",
                "year": "IntFilter"
            },

            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=f34d8630f5abe122a5db93f2d37a848bc2980d3494ca34acd820aecbd0cc8961";