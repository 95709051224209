<script lang="ts">
	import type { duplicateVinQuery$result } from '$houdini'
	import type { SvelteAsr } from 'types/common'
	type DuplicateVinVehicle = duplicateVinQuery$result['vehicles']['items'][0]

	import Modal from '@isoftdata/svelte-modal'
	import { Table, Td } from '@isoftdata/svelte-table'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher<{
		continueWithDuplicateVin: string
	}>()

	export let show: boolean = false
	export let asr: SvelteAsr
	let vin: string = ''

	export async function open(duplicateVinVehicles: Array<DuplicateVinVehicle>, inputVin: string) {
		vehicles = duplicateVinVehicles
		vin = inputVin
		show = true
	}

	let vehicles: Array<DuplicateVinVehicle> = []
</script>

<Modal
	bind:show
	title="Duplicate VIN Found"
	confirmButtonText="Continue With Duplicate VIN"
	modalSize="lg"
	cancelButtonText="Enter a Different VIN"
	on:confirm={() => dispatch('continueWithDuplicateVin', vin)}
	on:close
>
	<div
		class="alert alert-danger"
		role="alert"
	>
		One or more vehicles with VIN <span class="font-weight-bold">{vin}</span> were found. Select an existing vehicle to open, or continue with the duplicate VIN.
	</div>
	<div style="max-height: 60vh; overflow-y: auto;">
		<Table
			columns={[
				{ property: 'stockNumber', name: 'Stock #' },
				{ property: 'store[name]', name: 'Store' },
				{ property: 'enteredDate', name: 'Date Created' },
				{ property: 'status', name: 'Status' },
				{ property: 'userStatus', name: 'User Status' },
				{ property: 'inventoryType[name]', name: 'Unit Type' },
				{ property: 'location', name: 'Location' },
			]}
			rows={vehicles}
			let:row={vehicle}
			responsive
		>
			<tr
				on:click={() => {
					asr.go('app.vehicle', { id: vehicle.id })
				}}
				class="cursor-pointer"
			>
				<Td property="stockNumber">
					{vehicle.stockNumber}
				</Td>
				<Td property="store['name']">
					{vehicle.store.name}
				</Td>
				<Td property="enteredDate">
					{vehicle.enteredDate ?? 'Unknown'}
				</Td>
				<Td property="status">
					{vehicle.status}
				</Td>
				<Td property="userStatus">
					{vehicle.userStatus}
				</Td>
				<Td property="inventoryType['name']">
					{vehicle.inventoryType.name}
				</Td>
				<Td property="location">
					{vehicle.location}
				</Td>
			</tr>
		</Table>
	</div>
</Modal>
