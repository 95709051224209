export default {
    "name": "Parts_SetUserSetting",
    "kind": "HoudiniMutation",
    "hash": "c3d5542bcf8ae1c14c850289bf206bc199fcf4376aa8aa0149173b69342fa878",

    "raw": `mutation Parts_SetUserSetting($input: SetUserSettingInput!) {
  setUserSetting(input: $input)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setUserSetting": {
                "type": "Boolean",
                "keyRaw": "setUserSetting(input: $input)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "SetUserSettingInput"
        },

        "types": {
            "SetUserPartsSettingInput": {
                "clearScreenOnSave": "Boolean",
                "defaultNewAttachmentsArePublic": "Boolean"
            },

            "SetUserPartSearchSettingInput": {
                "pinnedSearchFields": "String"
            },

            "SetUserVehicleSearchSettingInput": {
                "pinnedSearchFields": "String"
            },

            "SetUserVehiclesSettingInput": {
                "defaultNewAttachmentsArePublic": "Boolean"
            },

            "SetUserSettingInput": {
                "parts": "SetUserPartsSettingInput",
                "searchParts": "SetUserPartSearchSettingInput",
                "searchVehicles": "SetUserVehicleSearchSettingInput",
                "vehicles": "SetUserVehiclesSettingInput"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=680e0437890ed370e35405b3d75a51821b4fc2f1ae4ea178cc6da953fc8c6a79";