export default {
    "name": "InventoryOptionValueData",
    "kind": "HoudiniFragment",
    "hash": "a92fde01e93c03084c0b7128b0062829856e1c35d4398608a69c44b8f0f597d3",

    "raw": `fragment InventoryOptionValueData on InventoryOptionValue {
  inventoryOptionValueId: id
  optionId
  value
  option {
    id
    name
    defaultChoice
    required
    rank
    dataType
    public
    manufacturer {
      id
      name
    }
    model {
      id
      name
    }
    category {
      id
      name
    }
    inventoryType {
      id
    }
    choices {
      default
      label
      rank
      id
    }
  }
  id
  inventoryId
  __typename
}
`,

    "rootType": "InventoryOptionValue",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryOptionValueId": {
                "type": "Int",
                "keyRaw": "inventoryOptionValueId",
                "visible": true
            },

            "optionId": {
                "type": "Int",
                "keyRaw": "optionId",
                "visible": true
            },

            "value": {
                "type": "String",
                "keyRaw": "value",
                "nullable": true,
                "visible": true
            },

            "option": {
                "type": "InventoryOption",
                "keyRaw": "option",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "defaultChoice": {
                            "type": "String",
                            "keyRaw": "defaultChoice",
                            "nullable": true,
                            "visible": true
                        },

                        "required": {
                            "type": "Boolean",
                            "keyRaw": "required",
                            "visible": true
                        },

                        "rank": {
                            "type": "Int",
                            "keyRaw": "rank",
                            "visible": true
                        },

                        "dataType": {
                            "type": "DataTypeEnum",
                            "keyRaw": "dataType",
                            "visible": true
                        },

                        "public": {
                            "type": "Boolean",
                            "keyRaw": "public",
                            "visible": true
                        },

                        "manufacturer": {
                            "type": "Manufacturer",
                            "keyRaw": "manufacturer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "model": {
                            "type": "Model",
                            "keyRaw": "model",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "category": {
                            "type": "Category",
                            "keyRaw": "category",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "inventoryType": {
                            "type": "InventoryType",
                            "keyRaw": "inventoryType",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "choices": {
                            "type": "InventoryOptionChoice",
                            "keyRaw": "choices",

                            "selection": {
                                "fields": {
                                    "default": {
                                        "type": "Boolean",
                                        "keyRaw": "default",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "id": {
                "type": "Int",
                "keyRaw": "id",
                "visible": true
            },

            "inventoryId": {
                "type": "Int",
                "keyRaw": "inventoryId",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=6367d2eaa83ffd762ce6f1f7e8d55338932e6bb26e913b4fe9273b399d279ce6";