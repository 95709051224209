export default {
    "name": "LIB_createInventory",
    "kind": "HoudiniMutation",
    "hash": "20c696ce86d506d6b61e204ca7e17af127584a1834b7ed129c441b3c636b11f3",

    "raw": `mutation LIB_createInventory($part: NewInventory!) {
  part: createInventory(input: $part) {
    innodbInventoryid: id
    storeId
    sku
    tagNumber
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "part": {
                "type": "Inventory",
                "keyRaw": "part(input: $part)",

                "selection": {
                    "fields": {
                        "innodbInventoryid": {
                            "type": "UInt",
                            "keyRaw": "innodbInventoryid",
                            "visible": true
                        },

                        "storeId": {
                            "type": "UInt",
                            "keyRaw": "storeId",
                            "visible": true
                        },

                        "sku": {
                            "type": "UInt",
                            "keyRaw": "sku",
                            "visible": true
                        },

                        "tagNumber": {
                            "type": "String",
                            "keyRaw": "tagNumber",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "part": "NewInventory"
        },

        "types": {
            "NestedNewInventoryAttachment": {
                "file": "Upload",
                "fileId": "UInt",
                "public": "Boolean",
                "rank": "Int"
            },

            "NewCollectionMember": {
                "costFactor": "Decimal2",
                "existingSkuNumber": "UInt",
                "integral": "Boolean",
                "newInventory": "NewInventory",
                "quantity": "Decimal6"
            },

            "NewCollection": {
                "members": "NewCollectionMember",
                "type": "CollectionTypeEnum"
            },

            "InventoryLocationInput": {
                "locationId": "Int",
                "locationName": "String",
                "permanent": "Boolean",
                "quantity": "Decimal6",
                "rank": "Int"
            },

            "InventoryOptionValueUpdate": {
                "inventoryOptionId": "Int",
                "value": "String"
            },

            "SaleClassReference": {
                "code": "String",
                "id": "Int"
            },

            "NestedNewInventorySerial": {
                "location": "String",
                "number": "String",
                "optionValues": "InventoryOptionValueUpdate"
            },

            "DimensionsInput": {
                "height": "Decimal2",
                "length": "Decimal2",
                "measurementUnit": "DimensionsMeasurementUnit",
                "weight": "Decimal6",
                "weightUnit": "DimensionsWeightUnit",
                "width": "Decimal2"
            },

            "NewInventory": {
                "attachments": "NestedNewInventoryAttachment",
                "bodyStyle": "String",
                "buyPackage": "Int",
                "categoryId": "UInt",
                "collection": "NewCollection",
                "condition": "String",
                "coreClass": "String",
                "coreCost": "Decimal2",
                "coreRequired": "Boolean",
                "coreRequiredToVendor": "Boolean",
                "cost": "Decimal2",
                "daysToReturn": "UInt",
                "daysToReturnCore": "UInt",
                "daysToReturnCoreToVendor": "UInt",
                "daysToReturnToVendor": "UInt",
                "defaultVendorId": "UInt",
                "deplete": "Boolean",
                "description": "String",
                "distributorCorePrice": "Decimal2",
                "distributorPrice": "Decimal2",
                "freezeUntil": "Date",
                "glCategoryId": "UInt",
                "interchangeNumber": "String",
                "inventoryTypeId": "UInt",
                "jobberCorePrice": "Decimal2",
                "jobberPrice": "Decimal2",
                "listPrice": "Decimal2",
                "locations": "InventoryLocationInput",
                "manufacturerId": "Int",
                "maxQuantity": "Decimal6",
                "minQuantity": "Decimal6",
                "modelId": "Int",
                "notes": "String",
                "oemNumber": "String",
                "optionValues": "InventoryOptionValueUpdate",
                "parentManufacturerId": "UInt",
                "parentModelId": "UInt",
                "partNumber": "String",
                "popularityCode": "String",
                "priceGroup": "String",
                "printTag": "Boolean",
                "public": "Boolean",
                "purchaseFactor": "Boolean",
                "quantity": "Decimal6",
                "replenishable": "Boolean",
                "retailCorePrice": "Decimal2",
                "retailPrice": "Decimal2",
                "returnable": "Boolean",
                "returnableToVendor": "Boolean",
                "safetyStockPercent": "Decimal2",
                "saleClass": "SaleClassReference",
                "seasonal": "Boolean",
                "sellPackage": "UInt",
                "sellPriceClassId": "Int",
                "serialized": "Boolean",
                "serials": "NestedNewInventorySerial",
                "shippingDimensions": "DimensionsInput",
                "side": "SideEnum",
                "singleQuantity": "Boolean",
                "sku": "Int",
                "status": "StatusEnum",
                "stockMethod": "StockMethodEnum",
                "stockType": "StockTypeEnum",
                "stockingDays": "Int",
                "storeId": "UInt",
                "subInterchangeNumber": "String",
                "tagNumber": "String",
                "taxable": "Boolean",
                "typeData1": "String",
                "typeData2": "String",
                "typeData3": "String",
                "typeData4": "String",
                "userStatus": "String",
                "vehicleId": "UInt",
                "vehicleMake": "String",
                "vehicleModel": "String",
                "vehicleVin": "String",
                "vehicleYear": "UInt",
                "vendorPopularityCode": "String",
                "vendorProductCode": "String",
                "wholesaleCorePrice": "Decimal2",
                "wholesalePrice": "Decimal2"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=9bdeafed3b62660f3d9d8340c476192699846c6642b0d8ab210625c1dd40a4d2";