<script lang="ts">
	import type { Vehicle } from './vehicle'
	import type { Writable } from 'svelte/store'
	import type { SvelteAsr, Mediator } from 'types/common'
	import type { VehiclesLookup$result } from '$houdini'
	import type { VehicleMediatorProviders } from './basic/Basic.svelte'

	import { graphql } from '$houdini'
	import { onMount, onDestroy, getContext } from 'svelte'
	import Button from '@isoftdata/svelte-button'
	import { hasPermission } from 'utility/permission'
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import LoadItemModal from '@isoftdata/svelte-load-item-modal'
	import { Dropdown, DropdownItem } from '@isoftdata/svelte-dropdown'

	export let asr: SvelteAsr
	export let storeId: number
	export let vehicle: Vehicle
	export let stateName: string
	export let vehicleId: number | undefined
	export let mediator = getContext<Mediator<VehicleMediatorProviders>>('mediator')
	export let parameters: Record<string, string | undefined> = {}

	let isSaving = false
	let vehicleChanged = true
	let requiredFieldsComplete = true
	let clearScreenOnSave: Writable<boolean>
	let loadVehicleModal: LoadItemModal<VehiclesLookup$result['vehicles']['items'][number]>

	const vehicleLookupQuery = graphql(`
		query VehiclesLookup($filter: VehicleFilter) {
			vehicles(filter: $filter) {
				items {
					id
					store {
						id
						code
						name
					}
					stockNumber
					vin
					inventoryType {
						id
						name
					}
				}
			}
		}
	`)

	const vehicleChangedUnsubscribe = mediator.provide('vehicleChanged', hasChanged => (vehicleChanged = hasChanged))
	const requiredVehicleFieldsCompleteUnsubscribe = mediator.provide('requiredVehicleFieldsComplete', fieldsComplete => (requiredFieldsComplete = fieldsComplete))

	onMount(() => {
		if (vehicle?.stockNumber) {
			mediator.publish('activity', {
				title: `#${vehicle.stockNumber}`,
				type: 'VEHICLE',
				route: stateName,
				name: 'Vehicle',
				parameters,
				icon: 'truck',
			})
		}
	})

	onDestroy(() => {
		vehicleChangedUnsubscribe?.()
		requiredVehicleFieldsCompleteUnsubscribe?.()
	})
</script>

<div class="card mb-3">
	<div class="card-header">
		<div class="d-flex flex-wrap justify-content-between">
			<div class="mr-auto">
				<h4 class="inline">
					<i class="fas fa-truck"></i>
					{#if vehicle?.id}
						<span class="d-inline-block">
							<span class="bold">Stock #:</span>
							{vehicle.stockNumber}
						</span>
						<span class="d-inline-block">
							<span class="bold">Make:</span>
							{vehicle.manufacturer?.name ?? ''}
						</span>
						<span class="d-inline-block">
							<span class="bold">Model:</span>
							{vehicle.model?.name ?? ''}
						</span>
					{:else}
						New Vehicle
					{/if}
				</h4>
			</div>
			<div
				class="d-flex flex-wrap d-sm-block mb-2 mb-sm-0"
				style="gap: 5px"
			>
				<Button
					size="sm"
					iconClass="plus"
					color="success"
					outline
					href={asr.makePath(null, { storeId, lastResetTime: Date.now() })}>New Vehicle</Button
				>
				<Button
					outline
					size="sm"
					iconClass="magnifying-glass"
					title="Click to search for vehicles by Stock #"
					on:click={() => loadVehicleModal.show()}
				></Button>
				<Dropdown
					split
					menuItemClickCloses={false}
					size="sm"
					class="flex-grow-1"
					iconClass="floppy-disk"
					isLoading={isSaving}
					color={requiredFieldsComplete ? 'primary' : 'danger'}
					disabled={!requiredFieldsComplete || !vehicleChanged}
					colorGreyDisabled={requiredFieldsComplete}
					title={!requiredFieldsComplete ? 'Missing Required Fields' : ''}
					on:click={async () => {
						isSaving = true
						await mediator.publish('saveVehicle')
						isSaving = false
					}}
					>Save Vehicle{#if !requiredFieldsComplete}*{/if}
					<svelte:fragment slot="dropdownItems">
						<h6 class="dropdown-header">Save and...</h6>
						<DropdownItem
							icon={{
								prefix: 'far',
								icon: $clearScreenOnSave ? 'check-square' : 'square',
							}}
							on:click={() => ($clearScreenOnSave = !$clearScreenOnSave)}
						>
							Start New Vehicle (Clear Screen)
						</DropdownItem>
					</svelte:fragment>
				</Dropdown>
			</div>
		</div>

		<AsrNavTabBar
			{asr}
			tabs={[
				{
					title: 'Basic',
					name: 'app.vehicle.basic',
					inherit: true,
					disabled: !vehicleId,
				},
				{
					title: 'Attachments',
					name: 'app.vehicle.attachment',
					inherit: true,
					disabled: !vehicleId || !hasPermission('VEHICLES_CAN_EDIT_ATTACHMENTS', storeId),
				},
				//Desktop app calls this tab "Performance", but we renamed it "History" for web
				{
					title: 'History',
					name: 'app.vehicle.history',
					inherit: true,
					disabled: !vehicleId || !hasPermission('VEHICLES_CAN_VIEW_VEHICLE_PERFORMANCE', storeId),
				},
				{
					title: 'Teardown',
					name: 'app.vehicle.teardown',
					inherit: true,
					disabled: !vehicleId || !hasPermission('VEHICLES_CAN_EDIT_BIDS', storeId),
				},
			]}
		/>
	</div>
	<div class="card-body">
		<uiView></uiView>
	</div>
</div>

<LoadItemModal
	itemTitle="Vehicles by Stock Number"
	itemIdProp="id"
	itemDisplayProp="stockNumber"
	doSearch={async searchString => {
		const { data } = await vehicleLookupQuery.fetch({
			variables: {
				filter: {
					stockNumber: searchString,
				},
			},
		})
		return data?.vehicles.items ?? []
	}}
	on:lookupError={({ detail: { error } }) =>
		mediator.publish('showMessage', {
			heading: 'Error Fetching Vehicles',
			message: error.message,
			type: 'danger',
			time: false,
		})}
	on:chooseItem={({ detail: { item } }) => asr.go(null, { vehicleId: item.id, storeId: item.store.id })}
	bind:this={loadVehicleModal}
	let:item
>
	<div class="w-100 d-flex justify-content-between">
		<h5 class="mb-1">{item.stockNumber}</h5>
		<h5
			class="mb-1"
			title={item.store.name}
		>
			Store {item.store.code}
		</h5>
	</div>
	<div class="w-100">{item.inventoryType?.id} - {item.inventoryType?.name}</div>
	<small>VIN: {item.id}</small>
</LoadItemModal>
