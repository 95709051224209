import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/VehicleHistoryTabComponents'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class VehicleHistoryTabComponentsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "VehicleHistoryTabComponentsStore",
			variables: true,
		})
	}
}

export async function load_VehicleHistoryTabComponents(params) {
	await initClient()

	const store = new VehicleHistoryTabComponentsStore()

	await store.fetch(params)

	return {
		VehicleHistoryTabComponents: store,
	}
}
