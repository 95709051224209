import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ChangeDetectionInterval'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ChangeDetectionIntervalStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ChangeDetectionIntervalStore",
			variables: false,
		})
	}
}

export async function load_ChangeDetectionInterval(params) {
	await initClient()

	const store = new ChangeDetectionIntervalStore()

	await store.fetch(params)

	return {
		ChangeDetectionInterval: store,
	}
}
