export default {
    "name": "InventoryOptions",
    "kind": "HoudiniQuery",
    "hash": "615047116975b186fa602de089fdf5b2410c8beb7ecf9e3cd22bf4d289170045",

    "raw": `query InventoryOptions($inventoryTypeId: [Int!], $manufacturerId: [Int!], $modelId: [Int!], $categoryName: [String!], $suppressFixedChoices: Boolean, $suppressQueryChoices: Boolean, $queryOverride: String) {
  inventoryOptions(
    inventoryTypeId: $inventoryTypeId
    manufacturerId: $manufacturerId
    modelId: $modelId
    categoryName: $categoryName
  ) {
    id
    name
    rank
    required
    public
    dataType
    choices(
      suppressFixedChoices: $suppressFixedChoices
      suppressQueryChoices: $suppressQueryChoices
      queryOverride: $queryOverride
    ) {
      id
      default
      label
      rank
    }
    manufacturer {
      id
      name
    }
    model {
      id
      name
    }
    category {
      id
      name
    }
    inventoryType {
      id
      name
    }
    defaultChoice
    choiceQuery
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryOptions": {
                "type": "InventoryOption",
                "keyRaw": "inventoryOptions(categoryName: $categoryName, inventoryTypeId: $inventoryTypeId, manufacturerId: $manufacturerId, modelId: $modelId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "rank": {
                            "type": "Int",
                            "keyRaw": "rank",
                            "visible": true
                        },

                        "required": {
                            "type": "Boolean",
                            "keyRaw": "required",
                            "visible": true
                        },

                        "public": {
                            "type": "Boolean",
                            "keyRaw": "public",
                            "visible": true
                        },

                        "dataType": {
                            "type": "DataTypeEnum",
                            "keyRaw": "dataType",
                            "visible": true
                        },

                        "choices": {
                            "type": "InventoryOptionChoice",
                            "keyRaw": "choices(queryOverride: $queryOverride, suppressFixedChoices: $suppressFixedChoices, suppressQueryChoices: $suppressQueryChoices)",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "default": {
                                        "type": "Boolean",
                                        "keyRaw": "default",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "manufacturer": {
                            "type": "Manufacturer",
                            "keyRaw": "manufacturer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "model": {
                            "type": "Model",
                            "keyRaw": "model",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "category": {
                            "type": "Category",
                            "keyRaw": "category",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "inventoryType": {
                            "type": "InventoryType",
                            "keyRaw": "inventoryType",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "defaultChoice": {
                            "type": "String",
                            "keyRaw": "defaultChoice",
                            "nullable": true,
                            "visible": true
                        },

                        "choiceQuery": {
                            "type": "String",
                            "keyRaw": "choiceQuery",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryTypeId": "Int",
            "manufacturerId": "Int",
            "modelId": "Int",
            "categoryName": "String",
            "suppressFixedChoices": "Boolean",
            "suppressQueryChoices": "Boolean",
            "queryOverride": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1b99993febe3a5ca8068ed27fde6ebf68241ceac95cf9893d33cab532bfe384d";