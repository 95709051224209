export default {
    "name": "SalesOrderDocumentTypes",
    "kind": "HoudiniQuery",
    "hash": "03c7a61eadc5649cb2eac3fa032e6f5db56af00541276022e07c6566af978253",

    "raw": `query SalesOrderDocumentTypes {
  salesOrderDocumentTypes {
    id
    name
    affectsInventory
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "salesOrderDocumentTypes": {
                "type": "SalesOrderDocument",
                "keyRaw": "salesOrderDocumentTypes",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "affectsInventory": {
                            "type": "Boolean",
                            "keyRaw": "affectsInventory",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=8204d8fc31673514283dfde86a976949ec095df9968c712bf3d59d679a9a2558";