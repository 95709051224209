import artifact from '$houdini/artifacts/LIB_replicateInventory'
import { MutationStore } from '../runtime/stores/mutation'

export class LIB_replicateInventoryStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
