export default {
    "name": "LIB_vehicleModels",
    "kind": "HoudiniQuery",
    "hash": "34343307d82caeae90f5bcc5456c7ec3154f2d22cbeccc524c55c9498de005f4",

    "raw": `query LIB_vehicleModels($make: String) {
  vehicleModels(make: $make) {
    name
    active
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vehicleModels": {
                "type": "VehicleModel",
                "keyRaw": "vehicleModels(make: $make)",

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "make": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=76fbb4c0bf2f7ec9d46968b7b0193724d4702ec6385d18486aa11577088e3aab";