export default {
    "name": "InventoryAllocations",
    "kind": "HoudiniQuery",
    "hash": "68790b4370daa0d097f8f4d14166f490c8b2fc863251669e7ec7cb3541f9ad4a",

    "raw": `query InventoryAllocations($inventoryId: UInt!) {
  inventory(id: $inventoryId) {
    allocations {
      salesOrderLines {
        items {
          id
          description
          quantity
          salesOrder {
            id
            date
            expirationDate
            store {
              id
            }
            documentType {
              id
              name
            }
            salesperson {
              name
              firstName
              lastName
              id
            }
            shippingAddress {
              company
            }
          }
        }
      }
      jobParts {
        id
        quantity
        job {
          id
          workOrder {
            id
            date
            address {
              company
            }
            store {
              id
              name
            }
            user {
              name
              id
            }
          }
        }
      }
      transferOrderLines {
        id
        quantity
        quantityReceived
        transferOrder {
          id
          date
          store {
            id
          }
          destinationStore {
            id
            name
          }
          user {
            firstName
            lastName
            id
          }
        }
      }
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventory": {
                "type": "Inventory",
                "keyRaw": "inventory(id: $inventoryId)",

                "selection": {
                    "fields": {
                        "allocations": {
                            "type": "InventoryAllocations",
                            "keyRaw": "allocations",

                            "selection": {
                                "fields": {
                                    "salesOrderLines": {
                                        "type": "SalesOrderLineList",
                                        "keyRaw": "salesOrderLines",

                                        "selection": {
                                            "fields": {
                                                "items": {
                                                    "type": "SalesOrderLine",
                                                    "keyRaw": "items",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "visible": true
                                                            },

                                                            "quantity": {
                                                                "type": "Decimal6",
                                                                "keyRaw": "quantity",
                                                                "visible": true
                                                            },

                                                            "salesOrder": {
                                                                "type": "SalesOrder",
                                                                "keyRaw": "salesOrder",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "date": {
                                                                            "type": "Date",
                                                                            "keyRaw": "date",
                                                                            "visible": true
                                                                        },

                                                                        "expirationDate": {
                                                                            "type": "Date",
                                                                            "keyRaw": "expirationDate",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        },

                                                                        "store": {
                                                                            "type": "Store",
                                                                            "keyRaw": "store",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "id": {
                                                                                        "type": "UInt",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "documentType": {
                                                                            "type": "SalesOrderDocument",
                                                                            "keyRaw": "documentType",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "id": {
                                                                                        "type": "UInt",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    },

                                                                                    "name": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "name",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "salesperson": {
                                                                            "type": "UserAccount",
                                                                            "keyRaw": "salesperson",
                                                                            "nullable": true,

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "name": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "name",
                                                                                        "visible": true
                                                                                    },

                                                                                    "firstName": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "firstName",
                                                                                        "nullable": true,
                                                                                        "visible": true
                                                                                    },

                                                                                    "lastName": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "lastName",
                                                                                        "nullable": true,
                                                                                        "visible": true
                                                                                    },

                                                                                    "id": {
                                                                                        "type": "Int",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "shippingAddress": {
                                                                            "type": "Address",
                                                                            "keyRaw": "shippingAddress",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "company": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "company",
                                                                                        "nullable": true,
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "jobParts": {
                                        "type": "JobPart",
                                        "keyRaw": "jobParts",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "quantity": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "quantity",
                                                    "visible": true
                                                },

                                                "job": {
                                                    "type": "Job",
                                                    "keyRaw": "job",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "workOrder": {
                                                                "type": "WorkOrder",
                                                                "keyRaw": "workOrder",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "date": {
                                                                            "type": "Date",
                                                                            "keyRaw": "date",
                                                                            "visible": true
                                                                        },

                                                                        "address": {
                                                                            "type": "Address",
                                                                            "keyRaw": "address",
                                                                            "nullable": true,

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "company": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "company",
                                                                                        "nullable": true,
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "store": {
                                                                            "type": "Store",
                                                                            "keyRaw": "store",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "id": {
                                                                                        "type": "UInt",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    },

                                                                                    "name": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "name",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "user": {
                                                                            "type": "UserAccount",
                                                                            "keyRaw": "user",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "name": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "name",
                                                                                        "visible": true
                                                                                    },

                                                                                    "id": {
                                                                                        "type": "Int",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "transferOrderLines": {
                                        "type": "TransferOrderLine",
                                        "keyRaw": "transferOrderLines",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "quantity": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "quantity",
                                                    "visible": true
                                                },

                                                "quantityReceived": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "quantityReceived",
                                                    "visible": true
                                                },

                                                "transferOrder": {
                                                    "type": "TransferOrder",
                                                    "keyRaw": "transferOrder",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "date": {
                                                                "type": "Date",
                                                                "keyRaw": "date",
                                                                "visible": true
                                                            },

                                                            "store": {
                                                                "type": "Store",
                                                                "keyRaw": "store",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "destinationStore": {
                                                                "type": "Store",
                                                                "keyRaw": "destinationStore",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "user": {
                                                                "type": "UserAccount",
                                                                "keyRaw": "user",

                                                                "selection": {
                                                                    "fields": {
                                                                        "firstName": {
                                                                            "type": "String",
                                                                            "keyRaw": "firstName",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        },

                                                                        "lastName": {
                                                                            "type": "String",
                                                                            "keyRaw": "lastName",
                                                                            "nullable": true,
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "Int",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryId": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=735f119566fd1aad86cabf6672b37afb067440e232302744729f51146165b519";