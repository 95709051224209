import type { AppContext } from 'types/common'
import type { Merge, WritableDeep } from 'type-fest'
import type { vehicleScreenQuery$result } from '$houdini'

import { graphql } from '$houdini'
import component from './Vehicle.svelte'
import { getSession } from 'stores/session'

export const vehicleQuery = graphql(`
	query vehicleScreenQuery($vehicleId: Int!) {
		vehicle(id: $vehicleId) {
			stockNumber
			manufacturer {
				name
			}
			model {
				name
			}
			year
		}
	}
`)

export type Vehicle = WritableDeep<Merge<vehicleScreenQuery$result['vehicle'], { id: number | null }>>

export default function createState({ mediator, stateRouter }: AppContext) {
	const stateName = 'app.vehicle'
	stateRouter.addState({
		name: stateName,
		route: 'vehicle',
		defaultChild: 'basic',
		querystringParameters: ['vehicleId', 'storeId', 'lastResetTime'],
		defaultParameters: {
			storeId() {
				return getSession()?.currentStore?.toString() ?? '1'
			},
			lastResetTime: null,
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(data, parameters) {
			const vehicleId = parseInt(parameters.vehicleId, 10) ?? undefined
			const storeId = parseInt(parameters.storeId, 10) ?? 1

			const vehicle = vehicleId ? (await vehicleQuery.fetch({ variables: { vehicleId } }))?.data?.vehicle : undefined

			return {
				clearScreenOnSave: undefined, //TODO
				vehicle,
				vehicleId,
				storeId,
				stateName,
				mediator,
				parameters,
			}
		},
	})
}
