export default {
    "name": "CreateInventoryType",
    "kind": "HoudiniMutation",
    "hash": "ecfa0cfb54c12aa34900bf5b877a362793a72b8a445ddff73ec8251b7ec59827",

    "raw": `mutation CreateInventoryType($input: NewInventoryType!) {
  createInventoryType(input: $input) {
    active
    id
    name
    setId
    vehicle
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createInventoryType": {
                "type": "InventoryType",
                "keyRaw": "createInventoryType(input: $input)",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "setId": {
                            "type": "Int",
                            "keyRaw": "setId",
                            "nullable": true,
                            "visible": true
                        },

                        "vehicle": {
                            "type": "Boolean",
                            "keyRaw": "vehicle",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewInventoryType"
        },

        "types": {
            "NewInventoryType": {
                "active": "Boolean",
                "id": "Int",
                "modelInventoryTypeId": "Int",
                "name": "String",
                "partListAuthorityId": "Int",
                "pickable": "Boolean",
                "publicAllowed": "Boolean",
                "requireSerialization": "Boolean",
                "setId": "Int",
                "taxCode": "String",
                "typeLabel1": "String",
                "typeLabel2": "String",
                "typeLabel3": "String",
                "typeLabel4": "String",
                "useManufacturer": "Boolean",
                "vehicle": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=1482e2abbaf857675926a0f0d95a9c4a3fbdade0b9d46a474c0c21b6b067cfc0";