import { writable } from 'svelte/store'

type Callback<T> = (value: T) => void | Promise<void>

/** Make a store that runs a callback whenever the value is updated.
 * Useful, for example, if you want to hit the API whenever the value changes.
 */
export default function makeCallbackWritable<T>(value: T, cb: Callback<T>) {
	const { subscribe, set } = writable(value)

	return {
		subscribe,
		set: (value: T) => {
			set(value)
			void cb(value)
		},
	}
}
