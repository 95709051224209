import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/InventoryDocumentHistory'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class InventoryDocumentHistoryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "InventoryDocumentHistoryStore",
			variables: true,
		})
	}
}

export async function load_InventoryDocumentHistory(params) {
	await initClient()

	const store = new InventoryDocumentHistoryStore()

	await store.fetch(params)

	return {
		InventoryDocumentHistory: store,
	}
}
