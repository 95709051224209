export default {
    "name": "CloseSessionMutation",
    "kind": "HoudiniMutation",
    "hash": "e836f836b8613f02a5bd5dd2385d0f26149522328444d0dee740e6d27763f17e",

    "raw": `mutation CloseSessionMutation {
  closeSession
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "closeSession": {
                "type": "Boolean",
                "keyRaw": "closeSession",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    }
};

"HoudiniHash=ee80ab8a48c59dcaaf0751d2d9a37dec558773a2cebe88ba1bcbe7a3fa5e33ba";