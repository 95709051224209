export default {
    "name": "InventoryChangeLog",
    "kind": "HoudiniQuery",
    "hash": "dd0336caf4eea793f28825b162a139803279f94e4cd885deac11d8ed202ac61a",

    "raw": `query InventoryChangeLog($inventoryId: UInt!, $pagination: PaginationOptions) {
  inventory(id: $inventoryId) {
    innodbInventoryId: id
    tagNumber
    changeLog(pagination: $pagination) {
      totalItems
      items {
        id
        averageCoreCost
        averageCost
        comment
        newValue
        previousValue
        quantity
        timeChanged
        glCategory {
          id
          name
        }
        user {
          id
          name
        }
        inventoryField {
          displayName
          id
        }
      }
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventory": {
                "type": "Inventory",
                "keyRaw": "inventory(id: $inventoryId)",

                "selection": {
                    "fields": {
                        "innodbInventoryId": {
                            "type": "UInt",
                            "keyRaw": "innodbInventoryId",
                            "visible": true
                        },

                        "tagNumber": {
                            "type": "String",
                            "keyRaw": "tagNumber",
                            "visible": true
                        },

                        "changeLog": {
                            "type": "InventoryLogList",
                            "keyRaw": "changeLog(pagination: $pagination)",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "items": {
                                        "type": "InventoryLogEntry",
                                        "keyRaw": "items",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "averageCoreCost": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "averageCoreCost",
                                                    "visible": true
                                                },

                                                "averageCost": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "averageCost",
                                                    "visible": true
                                                },

                                                "comment": {
                                                    "type": "String",
                                                    "keyRaw": "comment",
                                                    "visible": true
                                                },

                                                "newValue": {
                                                    "type": "String",
                                                    "keyRaw": "newValue",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "previousValue": {
                                                    "type": "String",
                                                    "keyRaw": "previousValue",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "quantity": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "quantity",
                                                    "visible": true
                                                },

                                                "timeChanged": {
                                                    "type": "DateTime",
                                                    "keyRaw": "timeChanged",
                                                    "visible": true
                                                },

                                                "glCategory": {
                                                    "type": "GlCategory",
                                                    "keyRaw": "glCategory",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true,
                                                                "nullable": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "user": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "user",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "inventoryField": {
                                                    "type": "InventoryField",
                                                    "keyRaw": "inventoryField",

                                                    "selection": {
                                                        "fields": {
                                                            "displayName": {
                                                                "type": "String",
                                                                "keyRaw": "displayName",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryId": "UInt",
            "pagination": "PaginationOptions"
        },

        "types": {
            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=f65762fb9b49332e79b50af46700b27e63fe0532ecc0951f52fd6535fb378189";