export default {
    "name": "VehicleBasicStageOne",
    "kind": "HoudiniQuery",
    "hash": "5e27021dbf7a29e47a6d5818bc54964c03abc36dbb297d3940c1acca0f28ba65",

    "raw": `query VehicleBasicStageOne($inventoryTypeId: Int!) {
  inventoryType(id: $inventoryTypeId) {
    options {
      id
      name
      defaultChoice
      required
      rank
      dataType
      public
      manufacturer {
        id
        name
      }
      model {
        id
        name
      }
      category {
        id
        name
      }
      inventoryType {
        id
      }
      choices {
        default
        label
        rank
        id
      }
    }
    id
  }
  states {
    stateName
    stateAbbreviation
    country
  }
  saleClasses {
    code
    name
    id
  }
  inventoryConditions
  vehicleColors
  vehicleColorCodes
  glCategories {
    id
    name
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryType": {
                "type": "InventoryType",
                "keyRaw": "inventoryType(id: $inventoryTypeId)",

                "selection": {
                    "fields": {
                        "options": {
                            "type": "InventoryOption",
                            "keyRaw": "options",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "defaultChoice": {
                                        "type": "String",
                                        "keyRaw": "defaultChoice",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "required": {
                                        "type": "Boolean",
                                        "keyRaw": "required",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "dataType": {
                                        "type": "DataTypeEnum",
                                        "keyRaw": "dataType",
                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "manufacturer": {
                                        "type": "Manufacturer",
                                        "keyRaw": "manufacturer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "model": {
                                        "type": "Model",
                                        "keyRaw": "model",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "category": {
                                        "type": "Category",
                                        "keyRaw": "category",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "inventoryType": {
                                        "type": "InventoryType",
                                        "keyRaw": "inventoryType",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "choices": {
                                        "type": "InventoryOptionChoice",
                                        "keyRaw": "choices",

                                        "selection": {
                                            "fields": {
                                                "default": {
                                                    "type": "Boolean",
                                                    "keyRaw": "default",
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "Int",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "states": {
                "type": "State",
                "keyRaw": "states",

                "selection": {
                    "fields": {
                        "stateName": {
                            "type": "String",
                            "keyRaw": "stateName",
                            "nullable": true,
                            "visible": true
                        },

                        "stateAbbreviation": {
                            "type": "String",
                            "keyRaw": "stateAbbreviation",
                            "nullable": true,
                            "visible": true
                        },

                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "saleClasses": {
                "type": "SaleClass",
                "keyRaw": "saleClasses",

                "selection": {
                    "fields": {
                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "inventoryConditions": {
                "type": "String",
                "keyRaw": "inventoryConditions",
                "visible": true
            },

            "vehicleColors": {
                "type": "String",
                "keyRaw": "vehicleColors",
                "visible": true
            },

            "vehicleColorCodes": {
                "type": "String",
                "keyRaw": "vehicleColorCodes",
                "visible": true
            },

            "glCategories": {
                "type": "GlCategory",
                "keyRaw": "glCategories",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "inventoryTypeId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=050a1dca7c9b022f86d5c8a162e729adb647060307628f43f34b210ac867288d";