import type { DataTypeEnum$options, HOUDINI_inventoryQuery$result as InventoryResult, LIB_settingValues$result, LIB_partStoreData$result, CollectionTypeEnum$options } from '$houdini'
import type { Merge, WritableDeep } from 'type-fest'
import type { BaseAttachmentFile } from '@isoftdata/svelte-attachments'
import ObjectMap from 'classes/ObjectMap'

import documentTypes, { type DocumentTypeName } from 'common/document-types'
import toTitleCase from 'to-title-case'
import { v4 as uuid } from '@lukeed/uuid'
import { stringToBoolean } from '@isoftdata/utility-string'
import { getObject } from '@isoftdata/utility-storage'
import { graphql } from '$houdini'
import { klona } from 'klona'

// we do a lot of post processing to change the shape of the inventory object, so this is the type before we do that
type InventoryFromApi = Omit<WritableDeep<InventoryResult['inventory']>, ' $fragments'>
export type SettingValues = LIB_settingValues$result['settingValues']
export type UserSettings = LIB_settingValues$result['userSettings']

export type TypeField = {
	label: string
	value: string | null
	history: Array<string>
}
// incorrect but good enough for now
export type CachedPart = Omit<PartForClient, 'attachments'>

export type CachedAttachments = Array<{
	uuid: string
	inventoryFileId: null
	fileId: number | null
	public: boolean
	rank: number
	mimeType: string
	name: string
	size: number
	path: string
	action: 'CREATE'
}>

export type OptionValueMap = ObjectMap<{ optionId: number; serialId?: number | null; serialUuid?: string | null; valueWasDefault?: boolean }, string | number | boolean | null>
export type PartStoreLocations = LIB_partStoreData$result['locations']
export type PartForClient = Merge<
	Omit<InventoryFromApi, 'attachments' | 'printTag' | 'sellPriceClass' | 'collection'>,
	{
		category: InventoryFromApi['category'] | null
		collection: {
			members: Array<CollectionMember>
			type: CollectionTypeEnum$options
		} | null
		defaultVendor: InventoryFromApi['defaultVendor'] | null
		enteredBy: {
			id: number
			name: string
		} | null
		innodbInventoryid: number | null
		inventoryId: number | null
		// jobberPrice: number
		maxQuantity: number
		minQuantity: number
		popularityCode: string
		sellPriceClassId: number | null
		subInterchangeNumber: string
		locations: Array<
			Merge<
				Merge<InventoryFromApi['locations'][number]['location'], Omit<InventoryFromApi['locations'][number], 'location'>>,
				{
					id: number | null
					quantity: number
					holdQuantity: number
					uuid: string
					deleted: boolean
					rank: number | null
				}
			>
		>
		inventoryType: WritableDeep<InventoryFromApi>['inventoryType'] | null
		inventoryTypeId: number | null
		inventoryOptions: Array<FlatQuestion>
		quantity: number
		quantityAvailable: number
		quantityOnHold: number
		safetyStockPercent: number
		serials: Array<
			Merge<
				Omit<InventoryFromApi['serials'][number], ' $fragments'>,
				{
					inventoryOptions: Array<FlatQuestion>
					uuid: string
					usedOn: string
					source: string
					displayStatus: string
					status: string
					deleted?: boolean
					location: {
						id: number | null
						name: string
						description: string | null
						unsaved?: boolean
					} | null
				}
			>
		>
		skuNumber: number | null
		storeId: number
		tagPrinted: boolean
		// vendorLeadTime: string
	}
>

export type CollectionType = 'ASSEMBLY' | 'KIT' | 'ORDERED_KIT' | 'TEMPLATE'
export type LoadedCollectionMember = {
	id?: number
	part: PartForClient
	attachments: Array<FlatAttachment>
	costFactor: string
	integral: boolean
	quantity: string
	optionValueMap: OptionValueMap
	origPart: Readonly<PartForClient>
	typeFields: Array<TypeField>
	template?: boolean
	uuid: string
	loaded: true
}
export type UnloadedCollectionMember = {
	id?: number
	part: NonNullable<NonNullable<InventoryFromApi['collection']>['members'][number]['localInventory']>
	attachments: Array<never>
	costFactor: string
	integral: boolean
	quantity: string
	template?: boolean
	uuid: string
	loaded: false
}
export type CollectionMember = LoadedCollectionMember | UnloadedCollectionMember

// Please keep this alphabetical :)
// These should be the same values as we keep in memory, not necessarily the same as the API.
export const defaultPart = Object.freeze<Omit<PartForClient, 'storeId'> & { storeId: null }>({
	attachmentCount: 0,
	averageCoreCost: '0',
	averageCost: '',
	averageDemandPerDay: 'N/A',
	averageDemandPerMonth: 'N/A',
	bodyStyle: '',
	buyPackage: 1,
	category: null,
	condition: '',
	collection: null,
	collectionMembership: [],
	coreClass: '',
	coreCost: '0',
	coreRequired: false,
	coreRequiredToVendor: false,
	cost: '0',
	dateEntered: new Date(),
	dateModified: new Date(),
	dateViewed: new Date(),
	daysToReturn: 30,
	daysToReturnCore: 30,
	daysToReturnCoreToVendor: 30,
	daysToReturnToVendor: 30,
	defaultVendor: null,
	deplete: true,
	description: '',
	distributorCorePrice: '0',
	distributorPrice: '0',
	enteredBy: null,
	freezeUntil: null,
	glCategory: null,
	innodbInventoryid: null,
	interchangeNumber: null,
	inventoryId: null,
	inventoryOptions: [],
	inventoryType: null,
	inventoryTypeId: null,
	jobberCorePrice: '0',
	jobberPrice: '0',
	listPrice: '0',
	locations: [],
	maxQuantity: 1,
	minQuantity: 1,
	manufacturer: null,
	model: null,
	notes: '',
	oemNumber: '',
	partNumber: '',
	partNumberStripped: '',
	parentManufacturer: null,
	parentModel: null,
	popularityCode: '',
	public: false,
	quantity: 1,
	quantityAvailable: 1,
	quantityOnHold: 0,
	replenishable: false,
	retailCorePrice: '0',
	retailPrice: '0',
	returnable: true,
	returnableToVendor: true,
	safetyStockPercent: 0,
	saleClass: { code: 'NONE', name: 'NONE' },
	seasonal: false,
	sellPackage: 1,
	sellPriceClassId: null,
	serials: [],
	serialized: false,
	shippingDimensions: {
		height: '0',
		length: '0',
		measurementUnit: 'IN',
		weight: '0',
		weightUnit: 'LB',
		width: '0',
	},
	side: 'NA',
	singleQuantity: false,
	skuNumber: null,
	skuType: { inventories: [] },
	status: 'A',
	stockCategory: 'MISC',
	stockingDays: 0,
	stockMethod: 'NONE',
	stockType: 'SPECIAL_ORDER',
	storeId: null,
	subInterchangeNumber: '',
	suggestedMaxQuantity: '',
	suggestedMinQuantity: '',
	suggestedSafetyStockPercent: '',
	tagNumber: '',
	tagPrinted: false,
	taxable: true,
	topImage: null,
	typeField1: {
		data: '',
		label: '',
	},
	typeField2: {
		data: '',
		label: '',
	},
	typeField3: {
		data: '',
		label: '',
	},
	typeField4: {
		data: '',
		label: '',
	},
	userStatus: '',
	useVendorOrderMultiplier: false,
	vendorLeadTime: 0,
	vendorPopularityCode: '',
	vendorProductCode: '',
	vehicle: null,
	vehicleId: null,
	vehicleMake: '',
	vehicleModel: '',
	vehicleVin: null,
	vehicleYear: null,
	wholesaleCorePrice: '0',
	wholesalePrice: '0',
})
// #endregion
// #region Function definitions

export function isValidQuestionForPart(
	question: FlatQuestion,
	part: {
		manufacturerId?: number | null
		modelId?: number | null
		categoryName?: string | null
		inventoryTypeId?: number | null
	},
): boolean {
	return (
		(question.manufacturerId === part.manufacturerId || question.manufacturerId === null) &&
		(question.modelId === part.modelId || question.modelId === null) &&
		(question.categoryName?.toLowerCase() === part.categoryName?.toLowerCase() || question.categoryName === null) &&
		(question.inventoryTypeId === part.inventoryTypeId || question.inventoryTypeId === null)
	)
}

export function transformInventoryOptionValue(dataType: DataTypeEnum$options, value: string | null): string | number | boolean | null {
	if (dataType === 'NUMBER') {
		return value === null || value === '' ? null : Number(value)
	} else if (dataType === 'BOOLEAN') {
		return stringToBoolean(value ?? '')
	} else {
		return value
	}
}

export function getOptionValueMap(part: Pick<PartForClient, 'inventoryOptions' | 'serialized' | 'serials'>): OptionValueMap {
	type KeyObj = { optionId: number; serialId?: number | null; serialUuid?: string }
	type OptionValue = (typeof part.inventoryOptions)[number]['value']
	type EntriesArray = Array<[KeyObj, OptionValue]>
	const inventoryOptionsForMap: Array<[KeyObj, OptionValue]> = part.inventoryOptions
		.filter(option => option.value) // don't include empty string or null values
		.map(option => [{ optionId: option.id }, option.value])

	const entries: EntriesArray = part.serialized
		? part.serials
				.reduce((acc: Array<[KeyObj, OptionValue]>, serial) => {
					return acc.concat(
						serial.inventoryOptions.map(option => [
							{
								serialId: serial.id,
								optionId: option.id,
								serialUuid: serial.uuid,
							},
							option.value,
						]),
					)
				}, [])
				.concat(inventoryOptionsForMap)
		: inventoryOptionsForMap
	return new ObjectMap(
		['serialId', 'optionId', 'serialUuid'], // always all key parts, in case we serialize later
		entries,
	)
}

export function flattenInventoryOptionForDisplay({ option, value }: Omit<InventoryFromApi['inventoryOptions'][number], ' $fragments' | 'optionId' | 'inventoryOptionValueId'>): FlatQuestion {
	const { category, manufacturer, model, inventoryType, ...optionRest } = option
	return {
		...optionRest,
		value: transformInventoryOptionValue(option.dataType, value),
		categoryId: category?.id ?? null,
		categoryName: category?.name ?? null,
		manufacturerId: manufacturer?.id ?? null,
		modelId: model?.id ?? null,
		inventoryTypeId: inventoryType?.id ?? null,
	}
}
export type FlatQuestion = {
	value: string | number | boolean | null
	categoryId: number | null
	categoryName: string | null
	manufacturerId: number | null
	modelId: number | null
	inventoryTypeId: number | null
	id: number
	name: string
	defaultChoice: string | null
	required: boolean
	rank: number
	dataType: DataTypeEnum$options
	public: boolean
	choices: Array<{
		default: boolean | null
		label: string
		rank: number
	}>
}

export function flattenAttachment(item: InventoryFromApi['attachments'][number]): FlatAttachment {
	return {
		...item.file,
		inventoryFileId: item.inventoryFileId,
		public: item.public,
		rank: item.rank ?? 0,
		createdDate: item.file.createdDate.toISOString(),
		uuid: uuid(),
	}
}

export type FlatAttachment = BaseAttachmentFile & { inventoryFileId: number | null }

export function formatUsedOnDocument({
	usedOnDocumentId,
	usedOnDocumentStoreId,
	usedOnDocumentType,
}: {
	usedOnDocumentId: number | null
	usedOnDocumentStoreId: number | null
	usedOnDocumentType: DocumentTypeName | null
}): string {
	if (!usedOnDocumentType) {
		return ''
	}

	const documentType = documentTypes[usedOnDocumentType]
	// Don't bother showing line ids, just show the document type / id
	const str = `${documentType.parentAbbreviation ?? documentType.abbreviation} `

	return str + [usedOnDocumentStoreId, usedOnDocumentId].filter(v => v).join('-')
}

export function formatSourceDocument({
	enteredOnDocumentId,
	enteredOnDocumentStoreId,
	enteredOnDocumentType,
}: {
	enteredOnDocumentId: number | null
	enteredOnDocumentStoreId: number | null
	enteredOnDocumentType: DocumentTypeName | null
}): string {
	return formatUsedOnDocument({
		usedOnDocumentId: enteredOnDocumentId,
		usedOnDocumentStoreId: enteredOnDocumentStoreId,
		usedOnDocumentType: enteredOnDocumentType,
	})
}

export function formatSerialLocation(serial: InventoryFromApi['serials'][number]): {
	id: number | null
	name: string
	description: string | null
} | null {
	return serial.location?.__typename === 'Location'
		? {
				id: serial.location.id,
				name: serial.location.name,
				description: serial.location.description,
		  }
		: serial.location?.__typename === 'VirtualLocation'
		  ? {
					id: null,
					name: serial.location.name,
					description: '',
		    }
		  : null
}

export function computeTypeFields(part: Pick<PartForClient, 'inventoryType' | 'typeField1' | 'typeField2' | 'typeField3' | 'typeField4'>): Array<TypeField> {
	if (!part.inventoryType) {
		return []
	}
	const typeFields = [
		//
		part.typeField1,
		part.typeField2,
		part.typeField3,
		part.typeField4,
	] as const

	const histories = [part.inventoryType?.typeData1History, part.inventoryType?.typeData2History, part.inventoryType?.typeData3History, part.inventoryType?.typeData4History]

	return typeFields.reduce((arr, field, index) => {
		if (field?.label) {
			arr.push({
				label: field.label,
				value: field.data,
				history: histories[index],
			})
		}
		return arr
	}, new Array<TypeField>())
}

graphql(`
	fragment CollectionMembershipLocalInventory on Inventory {
		innodbInventoryid: id
		storeId
		tagNumber
		inventoryType {
			id
			name
		}
		quantity
		quantityAvailable
		manufacturer {
			id
			name
		}
		model {
			id
			name
		}
		category {
			id
			name
		}
	}
`)

const inventoryQueryByIdStore = graphql(`
	query HOUDINI_inventoryQuery($innodbInventoryid: UInt!, $serialFilter: InventorySerialFilter) {
		inventory(id: $innodbInventoryid) {
			...InventoryData @with(serialFilter: $serialFilter)
			collection {
				type
				members {
					costFactor
					dateCreated
					id
					integral
					quantity
					localInventory {
						...CollectionMembershipLocalInventory
						status
						parentManufacturer {
							id
							name
						}
						parentModel {
							id
							name
						}
						side
						cost
						retailPrice
						wholesalePrice
						jobberPrice
						retailCorePrice
						attachmentCount
					}
				}
			}
			collectionMembership {
				collection {
					inventory {
						...CollectionMembershipLocalInventory
					}
					members {
						id
						localInventory {
							...CollectionMembershipLocalInventory
						}
					}
				}
			}
		}
	}
`)

type LoadedPartData = {
	part: PartForClient
	attachments: Array<FlatAttachment>
}

export async function loadPart(innodbInventoryid: number | null, useCachedPart: boolean, settingValues: SettingValues, userSettings: UserSettings, storeId: number): Promise<LoadedPartData> {
	const cachedPart = getObject<CachedPart>(localStorage, 'cachedPart') || false
	const cachedAttachments = getObject<CachedAttachments>(localStorage, 'cachedPartAttachments') ?? []
	// load cached
	if (useCachedPart && cachedPart) {
		return loadCachedPart(cachedPart, cachedAttachments)
	}
	// load from API
	if (innodbInventoryid) {
		const { data } = await inventoryQueryByIdStore.fetch({
			variables: {
				innodbInventoryid,
				serialFilter: { statuses: ['AVAILABLE', 'ON_HOLD', 'IN_TRANSIT'] },
			},
		})

		if (!data) {
			throw new Error('No data returned from inventoryQueryByIdStore')
		}

		const { collection, ...rest } = data.inventory

		const part: PartForClient = {
			...rest,
			collection: collection
				? {
						members:
							collection?.members.reduce((acc: Array<UnloadedCollectionMember>, member) => {
								// We only care about collection members who are at this store (for now?)
								if (member.localInventory) {
									acc.push({
										id: member.id,
										part: {
											...member.localInventory,
											innodbInventoryid: member.localInventory.innodbInventoryid,
										},
										uuid: uuid(),
										integral: member.integral,
										costFactor: member.costFactor,
										quantity: member.quantity,
										attachments: [],
										loaded: false,
									})
								}
								return acc
							}, []) ?? [],
						type: collection.type,
				  }
				: null,
			enteredBy: data.inventory.enteredBy,
			locations: data.inventory.locations
				? data.inventory.locations
						.map(({ location, ...inventoryLocation }) => {
							location ??= { name: '', description: '', allowInventory: null }
							return {
								...location,
								...inventoryLocation,
								quantity: parseFloat(inventoryLocation.quantity),
								holdQuantity: parseFloat(inventoryLocation.holdQuantity),
								uuid: uuid(),
								deleted: false,
							}
						})
						.sort((a, b) => a.rank ?? 0 - (b.rank ?? 0))
				: [],
			// On a serialized part, this will be the default serial Q&A. On a non-serialized part, this is the only Q&A.
			inventoryOptions: [],
			singleQuantity: data.inventory.singleQuantity ?? false, // temp fix for missing API field
			tagPrinted: !data.inventory.printTag,
			minQuantity: parseFloat(data.inventory.minQuantity),
			maxQuantity: parseFloat(data.inventory.maxQuantity),
			quantity: parseFloat(data.inventory.quantity),
			quantityOnHold: parseFloat(data.inventory.quantityOnHold),
			quantityAvailable: parseFloat(data.inventory.quantityAvailable),
			defaultVendor: data.inventory.defaultVendor ?? null,
			safetyStockPercent: parseFloat(data.inventory.safetyStockPercent),
			vendorLeadTime: data.inventory.vendorLeadTime,
			serials: [],
			sellPriceClassId: data.inventory.sellPriceClass?.id ?? null,
			subInterchangeNumber: data.inventory.subInterchangeNumber ?? '',
		}

		if (part.serialized) {
			part.serials =
				data.inventory.serials
					.map(serial => {
						return {
							...serial,
							inventoryOptions: serial.inventoryOptions.map(flattenInventoryOptionForDisplay),
							uuid: uuid(),
							usedOn: formatUsedOnDocument(serial),
							source: formatSourceDocument(serial),
							displayStatus: toTitleCase(serial.status ?? ''),
							status: serial.status ?? '',
							location: formatSerialLocation(serial),
						}
					})
					.sort((a, b) => a.status.localeCompare(b.status) || a.number.localeCompare(b.number)) ?? []
		}

		// On a serialized part, this will be the default serial Q&A. On a non-serialized part, this is the only Q&A.
		part.inventoryOptions = (data.inventory.inventoryOptions ?? [])
			.map(flattenInventoryOptionForDisplay)
			.filter(option => isValidQuestionForPart(option, { ...part, categoryName: part.category?.name ?? null }))
			.sort((a, b) => a.rank - b.rank)

		// cached/new part will already have this done to it
		part.locations = data.inventory.locations
			? // uuid is so we have a unique key for the stepper buttons, even on unsaved locations
			  data.inventory.locations
					.map(({ location, ...inventoryLocation }) => {
						location ??= { name: '', description: '', allowInventory: null }
						return {
							...location,
							...inventoryLocation,
							quantity: parseFloat(inventoryLocation.quantity),
							holdQuantity: parseFloat(inventoryLocation.holdQuantity),
							uuid: uuid(),
							deleted: false,
						}
					})
					.sort((a, b) => a.rank ?? 0 - (b.rank ?? 0))
			: []

		if (part.serialized) {
			part.serials =
				data.inventory.serials
					.map(serial => {
						return {
							...serial,
							inventoryOptions: serial.inventoryOptions.map(flattenInventoryOptionForDisplay),
							uuid: uuid(),
							usedOn: formatUsedOnDocument(serial),
							source: formatSourceDocument(serial),
							displayStatus: toTitleCase(serial.status ?? ''),
							status: serial.status ?? '',
							location: formatSerialLocation(serial),
						}
					})
					.sort((a, b) => a.status.localeCompare(b.status) || a.number.localeCompare(b.number)) ?? []
		}

		return {
			part,
			attachments: data.inventory.attachments?.map(flattenAttachment) ?? [],
		}
	}

	// New
	return newPart(storeId, settingValues, userSettings)
}

export function loadCachedPart(cachedPart: CachedPart, cachedAttachments: CachedAttachments): LoadedPartData | PromiseLike<LoadedPartData> {
	// TODO load cached collection members after I cache them
	// It will be a giant PITA because I'll have to encode/decode attachments for every member
	return {
		part: cachedPart,
		attachments: cachedAttachments.map(attachment => {
			const decode = attachment.path?.startsWith('data:') ?? false
			return {
				...attachment,
				fileId: attachment.fileId ?? undefined,
				inventoryFileId: attachment.inventoryFileId ?? null,
				File: decode ? decodeFileFromCache(attachment) : undefined,
			}
		}),
	}
}

export function newPart(storeId: number, settingValues: SettingValues, userSettings: UserSettings): { part: PartForClient; attachments: Array<FlatAttachment> } {
	return {
		part: {
			...klona(defaultPart),
			storeId,
			daysToReturn: settingValues.inventory.defaultDaysToReturn ?? 0,
			daysToReturnCore: settingValues.inventory.defaultDaysToReturnCore ?? 0,
			daysToReturnCoreToVendor: settingValues.inventory.defaultDaysToReturnCoreToVendor ?? 0,
			daysToReturnToVendor: settingValues.inventory.defaultDaysToReturnToVendor ?? 0,
			returnable: settingValues.inventory.defaultReturnable,
			// we set a fake object wit the id, and then .find from the list in resolve
			glCategory: { id: settingValues.inventory.defaultGlCategoryId, name: '' },
			returnableToVendor: settingValues.inventory.defaultReturnableToVendor,
			tagPrinted: !userSettings.parts.defaultNewInventoryPrintTags,
		},
		attachments: [],
	}
}

function decodeFileFromCache({ path, ...attachment }: { path: string; name: string }): File {
	const [meta, base64] = path.split(',')
	const [, mimeType] = meta.match(/^data:(.*);base64$/) ?? []
	const byteCharacters = atob(base64)

	const byteNumbers = new Array(byteCharacters.length)
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i)
	}

	const byteArray = new Uint8Array(byteNumbers)

	return new File([byteArray], attachment.name, { type: mimeType })
}
