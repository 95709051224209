export default {
    "name": "PartScreenStageOne",
    "kind": "HoudiniQuery",
    "hash": "3087d5c792eb25891f259d43832e6adf28a48578f65953c09439e47f7274ac69",

    "raw": `query PartScreenStageOne {
  inventoryConditions
  userPartStatusList: userStatuses(type: INVENTORY) {
    type
    status
    trigger
    partStatus
    description
  }
  inventoryTypeList: inventoryTypes(filter: {active: true}) {
    inventoryTypeId: id
    typeSetId: setId
    name
    vehicle
    categories {
      id
      name
      description
    }
    partManufacturers: manufacturers {
      id
      name
      code
    }
    requireSerialization
    id
  }
  glCategories {
    id
    name
  }
  vehicleMakes
  vendors(pagination: {pageSize: 0}) {
    items {
      id
      code
      companyName
      contactName
    }
  }
  sellPriceClasses {
    id
    name
    parent {
      id
      name
    }
  }
  saleClasses {
    code
    name
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryConditions": {
                "type": "String",
                "keyRaw": "inventoryConditions",
                "visible": true
            },

            "userPartStatusList": {
                "type": "UserStatus",
                "keyRaw": "userPartStatusList(type: INVENTORY)",

                "selection": {
                    "fields": {
                        "type": {
                            "type": "UserStatusTypeEnum",
                            "keyRaw": "type",
                            "visible": true
                        },

                        "status": {
                            "type": "String",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "trigger": {
                            "type": "Boolean",
                            "keyRaw": "trigger",
                            "visible": true
                        },

                        "partStatus": {
                            "type": "String",
                            "keyRaw": "partStatus",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "inventoryTypeList": {
                "type": "InventoryType",
                "keyRaw": "inventoryTypeList(filter: {active: true})",

                "selection": {
                    "fields": {
                        "inventoryTypeId": {
                            "type": "UInt",
                            "keyRaw": "inventoryTypeId",
                            "visible": true
                        },

                        "typeSetId": {
                            "type": "Int",
                            "keyRaw": "typeSetId",
                            "nullable": true,
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "vehicle": {
                            "type": "Boolean",
                            "keyRaw": "vehicle",
                            "visible": true
                        },

                        "categories": {
                            "type": "Category",
                            "keyRaw": "categories",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "partManufacturers": {
                            "type": "Manufacturer",
                            "keyRaw": "partManufacturers",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "requireSerialization": {
                            "type": "Boolean",
                            "keyRaw": "requireSerialization",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "glCategories": {
                "type": "GlCategory",
                "keyRaw": "glCategories",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "vehicleMakes": {
                "type": "String",
                "keyRaw": "vehicleMakes",
                "visible": true
            },

            "vendors": {
                "type": "VendorList",
                "keyRaw": "vendors(pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "Vendor",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "companyName": {
                                        "type": "String",
                                        "keyRaw": "companyName",
                                        "visible": true
                                    },

                                    "contactName": {
                                        "type": "String",
                                        "keyRaw": "contactName",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "sellPriceClasses": {
                "type": "SellPriceClass",
                "keyRaw": "sellPriceClasses",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "parent": {
                            "type": "SellPriceClass",
                            "keyRaw": "parent",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "saleClasses": {
                "type": "SaleClass",
                "keyRaw": "saleClasses",

                "selection": {
                    "fields": {
                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d51ce72d448db0e825380f75110006b6999820ba28582a6d2f066dbafa412d9c";