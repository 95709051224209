<script lang="ts">
	import type { DataTypeEnum$options as DataType } from '$houdini'
	import type { ComponentProps } from 'svelte'

	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'

	import { createEventDispatcher } from 'svelte'
	import { getEventValue, getEventChecked } from '@isoftdata/browser-event'

	export let name: string
	export let dataType: DataType
	export let value: string | number | boolean | null
	export let id: number | null

	export let choices: string[] = []
	export let required = false
	export let disabled = false
	export let defaultChoice = ''

	const dispatch = createEventDispatcher<{
		change: string | number | boolean | null
	}>()

	function getInputType(dataType: DataType): ComponentProps<Input>['type'] {
		switch (dataType) {
			case 'TEXT':
			case 'AUTOSUGGEST_TEXT':
				return 'text'
			case 'NUMBER':
			case 'CURRENCY':
				return 'number'
			case 'DATE':
				return 'date'
			case 'TIME':
				return 'time'
			case 'DATETIME':
				return 'datetime-local'
			default:
				return 'text'
		}
	}

	function onChange(event: Event) {
		value = dataType === 'BOOLEAN' ? getEventChecked(event) : getEventValue(event)
		dispatch('change', value)
	}

	// init value if not set and we have a default
	if (!value && defaultChoice) {
		value = defaultChoice
		dispatch('change', defaultChoice)
	}
</script>

{#if dataType === 'CHOICE'}
	<Select
		{required}
		{disabled}
		label={name}
		bind:value
		on:change={onChange}
	>
		{#each choices as choice}
			<option value={choice}>{choice}</option>
		{/each}
	</Select>
{:else if dataType === 'AUTOSUGGEST_TEXT' && typeof value !== 'boolean'}
	<Input
		selectOnFocus
		{required}
		{disabled}
		label={name}
		list="suggestText{id}List"
		bind:value
		on:change={onChange}
	/>
	<datalist id="suggestText{id}List">
		{#each choices as choice}
			<option value={choice}> </option>
		{/each}
	</datalist>
{:else if dataType === 'BOOLEAN' && typeof value === 'boolean'}
	<Checkbox
		{required}
		{disabled}
		type="radio"
		trueLabel="True"
		falseLabel="False"
		label={name}
		bind:checked={value}
		on:change={onChange}
	/>
{:else if typeof value !== 'boolean'}
	<Input
		{required}
		{disabled}
		label={name}
		type={getInputType(dataType)}
		bind:value
		on:change={onChange}
	/>
{/if}
