import svelteComponent from './InventoryType.svelte'
import pProps from 'p-props' // Bulk promise
import type { AppContext } from 'types/common'

import { graphql } from '$houdini'

const inventoryTypesQuery = graphql(`
	query InventoryTypesFull {
		inventoryTypes {
			active
			id
			#requireSerialization
			name
			setId
			vehicle
			#pickable
			#publicAllowed
		}
	}
`)

export default function ({ mediator, stateRouter }: AppContext) {
	const stateName = 'app.configuration.inventory-type'

	stateRouter.addState({
		name: stateName,
		route: 'inventory-type',
		querystringParameters: ['inventoryTypeId'],
		defaultChild: 'custom-question',
		template: {
			svelte: true, // this tells our renderer wrapper that this is a svelte component
			component: svelteComponent, // this is the svelte component
			options: {
				// these are the options passed to the svelte component
			},
		},
		async resolve(stateRouterData, { inventoryTypeId }) {
			const { data } = await inventoryTypesQuery.fetch()

			return await pProps({
				inventoryTypes: data?.inventoryTypes ?? [],
				selectedInventoryTypeId: inventoryTypeId ? parseInt(inventoryTypeId, 10) || undefined : undefined,
			})
		},
	})
}
