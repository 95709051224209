<script lang="ts">
	import type { ComponentProps } from 'svelte'
	import type { Strategy } from '@floating-ui/dom'

	import Button from '@isoftdata/svelte-button'

	import { computePosition, flip } from '@floating-ui/dom'

	let popoverTarget: HTMLDivElement
	let popoverButton: HTMLButtonElement
	let positionLeft = 0
	let positionTop = 0
	let strategy: Strategy = 'absolute'
	let show = false

	// ts-expect-error won't ignore this, but it's fine
	interface $$Props extends ComponentProps<Button> {}

	export async function toggle() {
		show = !show
		if (show) {
			const pos = await computePosition(popoverButton, popoverTarget, {
				placement: 'bottom',
				middleware: [flip()],
			})
			positionLeft = pos.x
			positionTop = pos.y
			strategy = pos.strategy
		}
	}
</script>

{#if $$slots.default}
	<Button
		{...$$restProps}
		on:click={toggle}
		bind:button={popoverButton}><slot></slot></Button
	>
{:else}
	<Button
		{...$$restProps}
		on:click={toggle}
		bind:button={popoverButton}
	></Button>
{/if}

<div
	class="popover"
	class:d-none={!show}
	style="position: {strategy}; left:{positionLeft}px; top:{positionTop}px;"
	bind:this={popoverTarget}
>
	<slot name="popover"></slot>
</div>
