export default {
    "name": "AppraisalQuery",
    "kind": "HoudiniQuery",
    "hash": "f2272499fa3f2c2654d6be08d471e3d14c03131bf667c5fb87822913fad2e926",

    "raw": `query AppraisalQuery($inputs: [AppraisalInput!]!) {
  appraisal(inputs: $inputs) {
    value
    relevance
    min
    max
    itemsAffected
    deviance
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "appraisal": {
                "type": "Appraisal",
                "keyRaw": "appraisal(inputs: $inputs)",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "Float",
                            "keyRaw": "value",
                            "nullable": true,
                            "visible": true
                        },

                        "relevance": {
                            "type": "Int",
                            "keyRaw": "relevance",
                            "nullable": true,
                            "visible": true
                        },

                        "min": {
                            "type": "Float",
                            "keyRaw": "min",
                            "nullable": true,
                            "visible": true
                        },

                        "max": {
                            "type": "Float",
                            "keyRaw": "max",
                            "nullable": true,
                            "visible": true
                        },

                        "itemsAffected": {
                            "type": "Int",
                            "keyRaw": "itemsAffected",
                            "nullable": true,
                            "visible": true
                        },

                        "deviance": {
                            "type": "Float",
                            "keyRaw": "deviance",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inputs": "AppraisalInput"
        },

        "types": {
            "AppraisalInput": {
                "category": "String",
                "inventoryTypeId": "Int",
                "manufacturer": "String",
                "model": "String",
                "originCode": "AppraisalCodeEnum",
                "vehicleMake": "String",
                "vehicleModel": "String",
                "year": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=2ac94f8f0a8d46c0def2c57deb8e2d930f0c7c32402964b32cb0d43a3cb23f6d";