export default {
    "name": "LIB_partStoreData",
    "kind": "HoudiniQuery",
    "hash": "8fbeb2761532c364e8bf78f856b684dd4ee8b9d46aeb2d9e69d0b85cead829dd",

    "raw": `query LIB_partStoreData($storeId: Int!) {
  locations(filter: {allowInventory: true, storeIds: [$storeId]}) {
    id
    name
    description
  }
  store(id: $storeId) {
    settingValues {
      parts {
        clearLocationsOnCopy
      }
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "locations": {
                "type": "Location",
                "keyRaw": "locations(filter: {allowInventory: true, storeIds: [$storeId]})",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "store": {
                "type": "Store",
                "keyRaw": "store(id: $storeId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "settingValues": {
                            "type": "StoreSettingValues",
                            "keyRaw": "settingValues",

                            "selection": {
                                "fields": {
                                    "parts": {
                                        "type": "StoreInventorySettingValues",
                                        "keyRaw": "parts",

                                        "selection": {
                                            "fields": {
                                                "clearLocationsOnCopy": {
                                                    "type": "Boolean",
                                                    "keyRaw": "clearLocationsOnCopy",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "storeId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d98a57b27e3007fd7e429af98c6fbadb2d18e4875213aad3e1dc5ca0a43be64a";