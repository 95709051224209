export default {
    "name": "InventoryQuestions",
    "kind": "HoudiniQuery",
    "hash": "c6568fea8dc3878873960e263374efbee892e4bcaa9aebd753685e6b8dd51477",

    "raw": `query InventoryQuestions($modelId: [Int!], $manufacturerId: [Int!], $inventoryTypeId: [Int!], $categoryName: [String!]) {
  inventoryOptions(
    modelId: $modelId
    manufacturerId: $manufacturerId
    inventoryTypeId: $inventoryTypeId
    categoryName: $categoryName
  ) {
    id
    name
    dataType
    choices {
      id
      label
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryOptions": {
                "type": "InventoryOption",
                "keyRaw": "inventoryOptions(categoryName: $categoryName, inventoryTypeId: $inventoryTypeId, manufacturerId: $manufacturerId, modelId: $modelId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "dataType": {
                            "type": "DataTypeEnum",
                            "keyRaw": "dataType",
                            "visible": true
                        },

                        "choices": {
                            "type": "InventoryOptionChoice",
                            "keyRaw": "choices",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "modelId": "Int",
            "manufacturerId": "Int",
            "inventoryTypeId": "Int",
            "categoryName": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=96a22dab75512f93695f353b47053465cc581c6149198f225a2cf62205fa6075";