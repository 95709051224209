export default {
    "name": "LIB_defaultPricing",
    "kind": "HoudiniQuery",
    "hash": "df1015ea211d30541e80fb231b05a537b4e9f284d600f497868d24d2ab7aca81",

    "raw": `query LIB_defaultPricing($input: DefaultPricingInput!) {
  defaultPricing(input: $input) {
    distributor
    distributorCore
    jobber
    jobberCore
    list
    retail
    retailCore
    wholesale
    wholesaleCore
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "defaultPricing": {
                "type": "Pricing",
                "keyRaw": "defaultPricing(input: $input)",

                "selection": {
                    "fields": {
                        "distributor": {
                            "type": "Decimal2",
                            "keyRaw": "distributor",
                            "visible": true
                        },

                        "distributorCore": {
                            "type": "Decimal2",
                            "keyRaw": "distributorCore",
                            "visible": true
                        },

                        "jobber": {
                            "type": "Decimal2",
                            "keyRaw": "jobber",
                            "visible": true
                        },

                        "jobberCore": {
                            "type": "Decimal2",
                            "keyRaw": "jobberCore",
                            "visible": true
                        },

                        "list": {
                            "type": "Decimal2",
                            "keyRaw": "list",
                            "visible": true
                        },

                        "retail": {
                            "type": "Decimal2",
                            "keyRaw": "retail",
                            "visible": true
                        },

                        "retailCore": {
                            "type": "Decimal2",
                            "keyRaw": "retailCore",
                            "visible": true
                        },

                        "wholesale": {
                            "type": "Decimal2",
                            "keyRaw": "wholesale",
                            "visible": true
                        },

                        "wholesaleCore": {
                            "type": "Decimal2",
                            "keyRaw": "wholesaleCore",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "DefaultPricingInput"
        },

        "types": {
            "DefaultPricingInput": {
                "category": "String",
                "coreCost": "Int",
                "cost": "Int",
                "modelId": "Int",
                "partNumber": "String",
                "storeId": "Int",
                "vendorId": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=15f9639232c5b6ec7d31f3febc48fffd3f7feec94d86dd50c22979e3a56217a4";