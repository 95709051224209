export default {
    "name": "inventoryTypeDetail",
    "kind": "HoudiniQuery",
    "hash": "457a2728f30fbdeadfe6807b6f5adf8f8efbede00ee953dc5ae62785499e876d",

    "raw": `query inventoryTypeDetail($inventoryTypeId: Int!) {
  inventoryType(id: $inventoryTypeId) {
    typeData1History
    typeData2History
    typeData3History
    typeData4History
    typeLabel1
    typeLabel2
    typeLabel3
    typeLabel4
    vehicle
    requireSerialization
    typeSetId: setId
    name
    inventoryTypeId: id
    categories {
      id
      description
      name
    }
    partManufacturers: manufacturers {
      id
      name
      code
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryType": {
                "type": "InventoryType",
                "keyRaw": "inventoryType(id: $inventoryTypeId)",

                "selection": {
                    "fields": {
                        "typeData1History": {
                            "type": "String",
                            "keyRaw": "typeData1History",
                            "visible": true
                        },

                        "typeData2History": {
                            "type": "String",
                            "keyRaw": "typeData2History",
                            "visible": true
                        },

                        "typeData3History": {
                            "type": "String",
                            "keyRaw": "typeData3History",
                            "visible": true
                        },

                        "typeData4History": {
                            "type": "String",
                            "keyRaw": "typeData4History",
                            "visible": true
                        },

                        "typeLabel1": {
                            "type": "String",
                            "keyRaw": "typeLabel1",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel2": {
                            "type": "String",
                            "keyRaw": "typeLabel2",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel3": {
                            "type": "String",
                            "keyRaw": "typeLabel3",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel4": {
                            "type": "String",
                            "keyRaw": "typeLabel4",
                            "nullable": true,
                            "visible": true
                        },

                        "vehicle": {
                            "type": "Boolean",
                            "keyRaw": "vehicle",
                            "visible": true
                        },

                        "requireSerialization": {
                            "type": "Boolean",
                            "keyRaw": "requireSerialization",
                            "visible": true
                        },

                        "typeSetId": {
                            "type": "Int",
                            "keyRaw": "typeSetId",
                            "nullable": true,
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "inventoryTypeId": {
                            "type": "UInt",
                            "keyRaw": "inventoryTypeId",
                            "visible": true
                        },

                        "categories": {
                            "type": "Category",
                            "keyRaw": "categories",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "partManufacturers": {
                            "type": "Manufacturer",
                            "keyRaw": "partManufacturers",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryTypeId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=db998bdc53d100b6a62c333ae6899994ec8a5007bdc96dd70068dc98e5c786f7";