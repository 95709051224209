export default {
    "name": "LIB_replicateInventory",
    "kind": "HoudiniMutation",
    "hash": "d155f331cc6bc1289342220753f31ab8bc18bb58cac649253da234c352dcc883",

    "raw": `mutation LIB_replicateInventory($sourceInventoryId: Int!, $destinationStoreIds: [Int!]!) {
  replicateInventory(
    sourceInventoryId: $sourceInventoryId
    destinationStoreIds: $destinationStoreIds
  ) {
    result
    storeId: destinationStoreId
    inventory {
      id
      store {
        id
        code
        name
      }
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "replicateInventory": {
                "type": "InventoryReplicationResponse",
                "keyRaw": "replicateInventory(destinationStoreIds: $destinationStoreIds, sourceInventoryId: $sourceInventoryId)",

                "selection": {
                    "fields": {
                        "result": {
                            "type": "InventoryReplicationResult",
                            "keyRaw": "result",
                            "visible": true
                        },

                        "storeId": {
                            "type": "UInt",
                            "keyRaw": "storeId",
                            "visible": true
                        },

                        "inventory": {
                            "type": "Inventory",
                            "keyRaw": "inventory",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "store": {
                                        "type": "Store",
                                        "keyRaw": "store",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "sourceInventoryId": "Int",
            "destinationStoreIds": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=d55bf762f1ff21b64f478eb023ba84e9b996ff986c242095fcf8f0360a1beae3";