<!--
 There are a number of places in EE where we want to select a list of stores to do something.
 This component facilitates that.
  -->
<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'

	import session from 'stores/session'
	import { createEventDispatcher } from 'svelte'
	import { getEventChecked } from '@isoftdata/browser-event'

	export let show = false
	export let selectedStoreIds: number[] = []
	export let excludedStoreIds: number[] = []
	export let title = 'Select Stores'
	export let subtitle = ''

	$: excludedStoreSet = new Set(excludedStoreIds)
	$: validStores = $session.authorizedStores.filter(store => !excludedStoreSet.has(store.id))

	export function hasValidStores() {
		return validStores.length > 0
	}

	export function open() {
		selectedStoreIds = []
		show = true
	}

	const dispatch = createEventDispatcher<{
		confirm: { selectedStoreIds: number[] }
	}>()
</script>

<Modal
	bind:show
	{title}
	{subtitle}
	on:close={() => (show = false)}
	on:confirm={() => {
		dispatch('confirm', { selectedStoreIds })
		show = false
	}}
>
	<svelte:fragment slot="modalFooter">
		<Button
			iconClass="check-double"
			on:click={() => {
				if (selectedStoreIds.length === validStores.length) {
					selectedStoreIds = []
				} else {
					selectedStoreIds = validStores.map(s => s.id)
				}
			}}>Select {selectedStoreIds.length === validStores.length ? 'None' : 'All'}</Button
		>
	</svelte:fragment>
	<ul class="list-group list-group-flush">
		{#each validStores as store (store.id)}
			<li class="list-group-item">
				<input
					id="store-{store.code}"
					type="checkbox"
					class="mr-3"
					checked={selectedStoreIds.includes(store.id)}
					on:change={event => {
						if (getEventChecked(event)) {
							selectedStoreIds.push(store.id)
							selectedStoreIds = selectedStoreIds
						} else {
							selectedStoreIds = selectedStoreIds.filter(id => id !== store.id)
						}
					}}
				/><label
					class="mb-0"
					for="store-{store.code}">{store.code} - {store.name}</label
				>
			</li>
		{:else}
			<li class="list-group-item">No Stores</li>
		{/each}
	</ul>
</Modal>
