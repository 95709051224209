import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/vehicleScreenQuery'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class vehicleScreenQueryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "vehicleScreenQueryStore",
			variables: true,
		})
	}
}

export async function load_vehicleScreenQuery(params) {
	await initClient()

	const store = new vehicleScreenQueryStore()

	await store.fetch(params)

	return {
		vehicleScreenQuery: store,
	}
}
