export default {
    "name": "VehicleHistoryTabComponents",
    "kind": "HoudiniQuery",
    "hash": "d9e2c8f55076edf8ddaca89f7689e2dec457360ae3ccdbe0170c68bf7c980e0f",

    "raw": `query VehicleHistoryTabComponents($vehicleId: Int!, $componentsFilter: InventoryFilter, $componentAllocationsFilter: ComponentAllocationsFilter, $pagination: PaginationOptions, $orderBy: [ComponentAllocationsSort!]) {
  vehicle(id: $vehicleId) {
    components(filter: $componentsFilter) {
      id
      status
      storeId
      tagNumber
      quantityOnHand: quantity
      quantityAvailable
      allocatedVehicleCost
      retailPrice
      wholesalePrice
      retailCorePrice
      inventoryType {
        name
        id
      }
      manufacturer {
        name
        id
      }
      model {
        name
        id
      }
      category {
        name
        id
      }
      averageCost
    }
    componentAllocations(
      filter: $componentAllocationsFilter
      pagination: $pagination
      orderBy: $orderBy
    ) {
      items {
        documentNumber
        documentType
        documentDate
        documentLine {
          ... on SalesOrderLine {
            id
            lookup
            quantity
            cost
            price
            inventory {
              id
              tagNumber
              category {
                id
                name
              }
            }
            inventoryType {
              id
              name
            }
            manufacturer {
              id
              name
            }
            model {
              id
              name
            }
          }
          ... on JobPart {
            id
            lookup
            quantity
            cost
            price
            inventory {
              id
              tagNumber
              category {
                id
                name
              }
            }
            inventoryType {
              id
              name
            }
            manufacturer {
              id
              name
            }
            model {
              id
              name
            }
          }
          __typename
        }
      }
      pageInfo {
        pageNumber
        totalPages
      }
      totalItems
    }
    consumedCost
    remainingCost
    unallocatedCost
    revenue
    totalCost
    netProfit
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vehicle": {
                "type": "Vehicle",
                "keyRaw": "vehicle(id: $vehicleId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "components": {
                            "type": "Inventory",
                            "keyRaw": "components(filter: $componentsFilter)",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "StatusEnum",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "storeId": {
                                        "type": "UInt",
                                        "keyRaw": "storeId",
                                        "visible": true
                                    },

                                    "tagNumber": {
                                        "type": "String",
                                        "keyRaw": "tagNumber",
                                        "visible": true
                                    },

                                    "quantityOnHand": {
                                        "type": "Decimal6",
                                        "keyRaw": "quantityOnHand",
                                        "visible": true
                                    },

                                    "quantityAvailable": {
                                        "type": "Decimal6",
                                        "keyRaw": "quantityAvailable",
                                        "visible": true
                                    },

                                    "allocatedVehicleCost": {
                                        "type": "Decimal2",
                                        "keyRaw": "allocatedVehicleCost",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "retailPrice",
                                        "visible": true
                                    },

                                    "wholesalePrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "wholesalePrice",
                                        "visible": true
                                    },

                                    "retailCorePrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "retailCorePrice",
                                        "visible": true
                                    },

                                    "inventoryType": {
                                        "type": "InventoryType",
                                        "keyRaw": "inventoryType",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "manufacturer": {
                                        "type": "Manufacturer",
                                        "keyRaw": "manufacturer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "model": {
                                        "type": "Model",
                                        "keyRaw": "model",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "category": {
                                        "type": "Category",
                                        "keyRaw": "category",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "averageCost": {
                                        "type": "Decimal2",
                                        "keyRaw": "averageCost",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "componentAllocations": {
                            "type": "ComponentAllocationList",
                            "keyRaw": "componentAllocations(filter: $componentAllocationsFilter, orderBy: $orderBy, pagination: $pagination)",

                            "selection": {
                                "fields": {
                                    "items": {
                                        "type": "ComponentAllocation",
                                        "keyRaw": "items",

                                        "selection": {
                                            "fields": {
                                                "documentNumber": {
                                                    "type": "String",
                                                    "keyRaw": "documentNumber",
                                                    "visible": true
                                                },

                                                "documentType": {
                                                    "type": "ComponentAllocationDocumentType",
                                                    "keyRaw": "documentType",
                                                    "visible": true
                                                },

                                                "documentDate": {
                                                    "type": "Date",
                                                    "keyRaw": "documentDate",
                                                    "visible": true
                                                },

                                                "documentLine": {
                                                    "type": "ComponentAllocationDocumentLine",
                                                    "keyRaw": "documentLine",

                                                    "selection": {
                                                        "abstractFields": {
                                                            "fields": {
                                                                "SalesOrderLine": {
                                                                    "id": {
                                                                        "type": "UInt",
                                                                        "keyRaw": "id",
                                                                        "visible": true
                                                                    },

                                                                    "lookup": {
                                                                        "type": "String",
                                                                        "keyRaw": "lookup",
                                                                        "visible": true
                                                                    },

                                                                    "quantity": {
                                                                        "type": "Decimal6",
                                                                        "keyRaw": "quantity",
                                                                        "visible": true
                                                                    },

                                                                    "cost": {
                                                                        "type": "Decimal2",
                                                                        "keyRaw": "cost",
                                                                        "visible": true
                                                                    },

                                                                    "price": {
                                                                        "type": "Decimal2",
                                                                        "keyRaw": "price",
                                                                        "visible": true
                                                                    },

                                                                    "inventory": {
                                                                        "type": "Inventory",
                                                                        "keyRaw": "inventory",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "id": {
                                                                                    "type": "UInt",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                },

                                                                                "tagNumber": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "tagNumber",
                                                                                    "visible": true
                                                                                },

                                                                                "category": {
                                                                                    "type": "Category",
                                                                                    "keyRaw": "category",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "id": {
                                                                                                "type": "Int",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true,
                                                                                                "nullable": true
                                                                                            },

                                                                                            "name": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "name",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "inventoryType": {
                                                                        "type": "InventoryType",
                                                                        "keyRaw": "inventoryType",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "id": {
                                                                                    "type": "UInt",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                },

                                                                                "name": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "name",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "manufacturer": {
                                                                        "type": "Manufacturer",
                                                                        "keyRaw": "manufacturer",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "id": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                },

                                                                                "name": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "name",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "model": {
                                                                        "type": "Model",
                                                                        "keyRaw": "model",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "id": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                },

                                                                                "name": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "name",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    }
                                                                },

                                                                "JobPart": {
                                                                    "id": {
                                                                        "type": "UInt",
                                                                        "keyRaw": "id",
                                                                        "visible": true
                                                                    },

                                                                    "lookup": {
                                                                        "type": "String",
                                                                        "keyRaw": "lookup",
                                                                        "visible": true
                                                                    },

                                                                    "quantity": {
                                                                        "type": "Decimal6",
                                                                        "keyRaw": "quantity",
                                                                        "visible": true
                                                                    },

                                                                    "cost": {
                                                                        "type": "Decimal2",
                                                                        "keyRaw": "cost",
                                                                        "visible": true
                                                                    },

                                                                    "price": {
                                                                        "type": "Decimal2",
                                                                        "keyRaw": "price",
                                                                        "visible": true
                                                                    },

                                                                    "inventory": {
                                                                        "type": "Inventory",
                                                                        "keyRaw": "inventory",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "id": {
                                                                                    "type": "UInt",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                },

                                                                                "tagNumber": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "tagNumber",
                                                                                    "visible": true
                                                                                },

                                                                                "category": {
                                                                                    "type": "Category",
                                                                                    "keyRaw": "category",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "id": {
                                                                                                "type": "Int",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true,
                                                                                                "nullable": true
                                                                                            },

                                                                                            "name": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "name",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "inventoryType": {
                                                                        "type": "InventoryType",
                                                                        "keyRaw": "inventoryType",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "id": {
                                                                                    "type": "UInt",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                },

                                                                                "name": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "name",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "manufacturer": {
                                                                        "type": "Manufacturer",
                                                                        "keyRaw": "manufacturer",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "id": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                },

                                                                                "name": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "name",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "model": {
                                                                        "type": "Model",
                                                                        "keyRaw": "model",
                                                                        "nullable": true,

                                                                        "selection": {
                                                                            "fields": {
                                                                                "id": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                },

                                                                                "name": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "name",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    }
                                                                }
                                                            },

                                                            "typeMap": {}
                                                        },

                                                        "fields": {
                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "abstract": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "pageInfo": {
                                        "type": "PageInfo",
                                        "keyRaw": "pageInfo",

                                        "selection": {
                                            "fields": {
                                                "pageNumber": {
                                                    "type": "Int",
                                                    "keyRaw": "pageNumber",
                                                    "visible": true
                                                },

                                                "totalPages": {
                                                    "type": "Int",
                                                    "keyRaw": "totalPages",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "consumedCost": {
                            "type": "Decimal2",
                            "keyRaw": "consumedCost",
                            "visible": true
                        },

                        "remainingCost": {
                            "type": "Decimal2",
                            "keyRaw": "remainingCost",
                            "visible": true
                        },

                        "unallocatedCost": {
                            "type": "Decimal2",
                            "keyRaw": "unallocatedCost",
                            "visible": true
                        },

                        "revenue": {
                            "type": "Decimal2",
                            "keyRaw": "revenue",
                            "visible": true
                        },

                        "totalCost": {
                            "type": "Decimal2",
                            "keyRaw": "totalCost",
                            "visible": true
                        },

                        "netProfit": {
                            "type": "Decimal2",
                            "keyRaw": "netProfit",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "vehicleId": "Int",
            "componentsFilter": "InventoryFilter",
            "componentAllocationsFilter": "ComponentAllocationsFilter",
            "pagination": "PaginationOptions",
            "orderBy": "ComponentAllocationsSort"
        },

        "types": {
            "StringFilter": {
                "eq": "String",
                "gt": "String",
                "gte": "String",
                "lt": "String",
                "lte": "String",
                "ne": "String"
            },

            "InventoryFieldFilter": {
                "name": "String",
                "value": "StringFilter"
            },

            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "Decimal2Filter": {
                "eq": "Decimal2",
                "gt": "Decimal2",
                "gte": "Decimal2",
                "lt": "Decimal2",
                "lte": "Decimal2"
            },

            "IntFilter": {
                "eq": "Int",
                "gt": "Int",
                "gte": "Int",
                "lt": "Int",
                "lte": "Int",
                "ne": "Int"
            },

            "Decimal6Filter": {
                "eq": "Decimal6",
                "gt": "Decimal6",
                "gte": "Decimal6",
                "lt": "Decimal6",
                "lte": "Decimal6",
                "ne": "Decimal6"
            },

            "SmartSearchFilter": {
                "keyword": "String",
                "moduleIds": "Int"
            },

            "InventoryFilter": {
                "additionalFields": "InventoryFieldFilter",
                "assemblyManufacturerId": "Int",
                "assemblyModelId": "Int",
                "category": "String",
                "condition": "String",
                "customFields": "InventoryFieldFilter",
                "dateSold": "DateFilter",
                "defaultVendorCode": "String",
                "defaultVendorId": "Int",
                "description": "String",
                "distributorCorePrice": "Decimal2Filter",
                "distributorPrice": "Decimal2Filter",
                "enteredByUser": "IntFilter",
                "ids": "Int",
                "inventoryTypeId": "IntFilter",
                "isCore": "Boolean",
                "jobberCorePrice": "Decimal2Filter",
                "jobberPrice": "Decimal2Filter",
                "keyword": "String",
                "location": "String",
                "manufacturerId": "Int",
                "modelId": "Int",
                "notes": "String",
                "partNumber": "String",
                "partNumberStripped": "String",
                "quantity": "Decimal6Filter",
                "quantityAvailable": "Decimal6Filter",
                "quantityOnHold": "Decimal6Filter",
                "quantityOnOrder": "Decimal6Filter",
                "replenishable": "Boolean",
                "shippingHeight": "Decimal2Filter",
                "shippingLength": "Decimal2Filter",
                "shippingWidth": "Decimal6Filter",
                "side": "SideEnum",
                "skuNumber": "Int",
                "smartSearchFilters": "SmartSearchFilter",
                "statuses": "InventorySearchStatus",
                "stores": "Int",
                "tagNumber": "String",
                "typeField1": "String",
                "typeField2": "String",
                "typeField3": "String",
                "typeField4": "String",
                "userStatus": "String",
                "vehicleId": "Int",
                "vehicleManufacturerId": "Int",
                "vehicleModelId": "Int",
                "vendorLeadTime": "IntFilter",
                "vin": "String",
                "weight": "Decimal6Filter",
                "year": "IntFilter"
            },

            "ComponentAllocationsFilter": {
                "consumed": "Boolean",
                "cost": "Decimal2Filter",
                "date": "DateFilter",
                "price": "Decimal2Filter",
                "quantity": "Decimal6Filter"
            },

            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=2d0eb0f320d5a75ea4b429560da381137873d1b1366baa3dd9fb85a99f572da0";