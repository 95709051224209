export default {
    "name": "InventoryTypes",
    "kind": "HoudiniQuery",
    "hash": "6d1058ab934b624f0c13f438f53440de2d7ecdd549e396ab0cc4ef494261d881",

    "raw": `query InventoryTypes($active: Boolean, $vehicle: Boolean) {
  inventoryTypes(filter: {active: $active, vehicle: $vehicle}) {
    name
    setId
    id
    typeLabel1
    typeLabel2
    typeLabel3
    typeLabel4
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryTypes": {
                "type": "InventoryType",
                "keyRaw": "inventoryTypes(filter: {active: $active, vehicle: $vehicle})",

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "setId": {
                            "type": "Int",
                            "keyRaw": "setId",
                            "nullable": true,
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "typeLabel1": {
                            "type": "String",
                            "keyRaw": "typeLabel1",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel2": {
                            "type": "String",
                            "keyRaw": "typeLabel2",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel3": {
                            "type": "String",
                            "keyRaw": "typeLabel3",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel4": {
                            "type": "String",
                            "keyRaw": "typeLabel4",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "active": "Boolean",
            "vehicle": "Boolean"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4909c4c318201659c1c94d1111b3a4522c9b8842838e14e831efa56030ac9e44";