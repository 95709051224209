import template from './teardown.html'

//Helper utilities
import { getBootstrapBreakpoint } from '@isoftdata/utility-bootstrap'
import { setUuidFromIndex } from '@isoftdata/utility-array'
import * as currency from '@isoftdata/utility-currency'

import domValue from 'dom-value'

//Ractive Components
import makeItImageViewerComponent from '@isoftdata/image-viewer-modal'
import makeItCurrencyInputComponent from '@isoftdata/currency-input'
import makeItSideSelectComponent from 'components/itSideSelect'
import makeItTableComponent from '@isoftdata/table'
import makeItModalComponent from '@isoftdata/modal'
import makeItTextAreaComponent from '@isoftdata/textarea'
import makeItSelectComponent from '@isoftdata/select'
import makeItInputComponent from '@isoftdata/input'
import makeAttachmentComponent from '@isoftdata/attachment'
import makeItAutocomplete from '@isoftdata/autocomplete'
import makeItPublicCheck from '@isoftdata/checkbox'
import makeItSelectPlus from '@isoftdata/select-with-modal'
import makeItCheckbox from '@isoftdata/checkbox'
//Ractive Events
import dragAndDropFiles from 'ractive-drag-and-drop-files'

//Classes
import DefaultPart from 'classes/DefaultPart'
import myTeardownItem from 'classes/myTeardownItem'
// STUFF!
import * as querylib from 'common/graphql-queries'
import * as mutationLib from '../mutations'
import { v4 as uuid } from '@lukeed/uuid'
import { sortArrayByObjectKey } from '@isoftdata/utility-array'
// Ractive Transitions
import ractiveTransitionsFade from 'ractive-transitions-fade'
import ractiveTransitionsFly from 'ractive-transitions-fly'
import ractiveTransitionsSlide from 'ractive-transitions-slide'

export default function createTeardownComponent(mediator) {
	return Ractive.extend({
		template,
		isolated: true,
		twoway: false,
		delegate: false,
		data() {
			return {
				parts: [],
				defaultPart: {},
				parentType: '', // VEHICLE or INVENTORY
				teardownType: '', // INVENTORY or BID(BID is only valid if parent type is VEHICLE)
				vehicleStatus: 'A',
				showTeardownItemModal: false,
				showTeardownEditModal: false,
				inventoryTypeFilter: '',
				currentPageTeardownRows: [],
				sortedAllTeardownItems: [],
				domValue,
				fileDraggedOverItemId: null,
				savingFiles: [],
				displayFiles: [],
				imageViewerShown: false,
				currentPhotoIndex: 0,
				userViewMode: localStorage.getItem('userViewMode') || 'LIST', //TABLE or LIST
				listViewSelection: 0,
				currency,
				selectedBreakdownTemplateId: '',
				selectedAddPartValue: '',
				userPartStatusList: [],
				inventoryFieldAccess: null,
				hideUnavailableParts: true,
				lastSaveOperation: null,
				typeDataHistory: {
					typeData1History: [],
					typeData2History: [],
					typeData3History: [],
					typeData4History: [],
				},

			}
		},
		components: {
			itInput: makeItInputComponent(),
			itPublicCheck: makeItPublicCheck(),
			partTypeFilterInput: makeItInputComponent({ twoway: true, lazy: 100 }),
			breakdownTemplateSelect: makeItSelectComponent({ twoway: true }),
			itAutoComplete: makeItAutocomplete(),
			itSelect: makeItSelectComponent(),
			itSelectPlus: makeItSelectPlus(),
			itTable: makeItTableComponent(),
			itSideSelect: makeItSideSelectComponent(),
			itTextArea: makeItTextAreaComponent({ twoway: true }),
			itCurrencyInput: makeItCurrencyInputComponent({ twoway: true }),
			itImageViewer: makeItImageViewerComponent(),
			appraisalModal: makeItModalComponent(),
			teardownItemModal: makeItModalComponent(),
			teardownEditModal: makeItModalComponent(),
			itCheckbox: makeItCheckbox({ twoway: true }),
			itAttachment: makeAttachmentComponent({

				saveFileAndAssociate: async(newfile, association) => {
					const savedAttachment = await mediator.publish('uploadFileAttachment', { file: newfile.File, relationId: association, relation: 'inventory', rank: newfile.rank })

					return {
						fileId: savedAttachment.data.createItemAttachment.fileId,
						public: savedAttachment.data.createItemAttachment.public,
						rank: savedAttachment.data.createItemAttachment.rank,
						...savedAttachment.data.createItemAttachment.file,
					}
				},

				updatePublicFlag: async(fileIds, toPublic, association) => {
					const data = fileIds.map(id => {
						return { id,	public: toPublic	}
					})

					const returnFiles = await mediator.publish('graphqlFetch', querylib.updateItemAttachments, { data })

					return returnFiles.updateItemAttachments.map(item => {
						return { fileId: item.fileId, public: item.public, rank: item.rank, ...item.file }
					})
				},
				deleteFileAssociation: async(fileIds, association) => {
					return await mediator.publish('graphqlFetch', querylib.deleteItemAttachments, { ids: fileIds })
				},

				setTopRankedFile: async(fileId, association, fileList) => {
					let r = 1
					const data = fileList.map(file => {
						const rank = file.fileId === fileId ? 1 : ++r
						return { id: file.fileId, rank }
					})

					const returnFiles = await mediator.publish('graphqlFetch', querylib.updateItemAttachments, { data })
					return returnFiles.updateItemAttachments.map(item => {
						return { fileId: item.fileId, public: item.public, rank: item.rank, ...item.file }
					})
				},
				handleError: async err => {
					if (typeof err === 'object' && err !== null && typeof err.message === 'string') {
						let message = err.message
						if (err.extensions?.code === 'DUPLICATE_ATTACHMENT') {
							message = `Error: Duplicate attachments not allowed`
						}
						mediator.publish('showMessage', { type: 'danger', heading: 'Failed to save part attachment', message, time: false })
					}
				},

			}),
		},
		transitions: {
			fade: ractiveTransitionsFade,
			fly: ractiveTransitionsFly,
			slide: ractiveTransitionsSlide,
		},
		events: {
			dragdropfiles: dragAndDropFiles,
		},
		computed: {
			displayTeardown() {
				let existingParts = this.get('parts')
				const defaultPart = this.get('defaultPart')

				let template = this.get('template')
				let inventoryTypeFilter = this.get('inventoryTypeFilter')

				const appraisedItems = this.get('appraisedItems')
				const selectedBreakdownTemplateId = this.get('selectedBreakdownTemplateId')

				template = template.reduce((sum, item) => {
					if (item.breakdownTemplateId === selectedBreakdownTemplateId) {
						return sum.concat(item)
					}
					return sum
				}, []).map(part=>{
					return new myTeardownItem(part, this.get('inventoryTypeList'))
				})

				template = sortArrayByObjectKey({ array: template, key: 'rank' })
				if (this.get('hideUnavailableParts')) {
					existingParts = existingParts.filter(item => {
						return item.status !== 'C' && item.status !== 'D' && item.status !== 'S' && item.status !== 'T'
					})
				}
				existingParts = existingParts.map(part => {
					part.rank = null
					if (part.status === 'D') {
						return part
					}

					const templateItemIndex = template.findIndex(({ inventoryTypeId }) => inventoryTypeId === part.inventoryTypeId)

					if (templateItemIndex !== -1) {
						part.rank = template[templateItemIndex].rank
						template.splice(templateItemIndex, 1)
					}

					return part
				})

				let computedTeardownItems = [ ...existingParts, ...template ]
				computedTeardownItems = computedTeardownItems.map(teardownItem => {
					teardownItem.fileCount = teardownItem.attachments ? teardownItem.attachments.length : 0

					//teardownItem.maxAttachmentRank = teardownItem.attachments ? Math.max.apply(Math, teardownItem.attachments.map(file => file.rank)) : 0

					return { ...teardownItem, textColorClassForStatus: teardownItem.exists ? getClassFromInventoryStatus(teardownItem.status) : '',
						partStatusIsToggleable: partStatusIsToggleable(teardownItem.exists, teardownItem.status),
						tooltipTextForStatus: getTooltipTextFromInventoryStatus(teardownItem.status),
						existsAndStatus: teardownItem.exists ? teardownItem.status : '', //only used for sorting
						canDelete: (teardownItem.exists && !teardownItem.locked),
					}
				})

				if (inventoryTypeFilter) {
					computedTeardownItems = computedTeardownItems.filter(item => {
						if (typeof inventoryTypeFilter === 'number') {
							inventoryTypeFilter = inventoryTypeFilter.toString()
						}

						inventoryTypeFilter = inventoryTypeFilter.toUpperCase()

						return item.inventoryTypeName.toUpperCase().indexOf(inventoryTypeFilter) > -1
							|| item.inventoryTypeId.toString().indexOf(inventoryTypeFilter) > -1
					})
				}

				if (appraisedItems && appraisedItems.length > 0) {
					computedTeardownItems = computedTeardownItems.map(item => {
						const match = appraisedItems.find(appraisedItem => appraisedItem.id === item.id)
						return { ...item, appraisalValue: match ? match.value : '' }
					})
				}
				//	console.log("displayTeardown.computedTeardownItems", computedTeardownItems)
				return setUuidFromIndex(computedTeardownItems)
			},
			displayTemplateNameList() {
				return this.get('breakdownTemplate').reduce((sum, templateItem) => {
					if (sum.find(existingTemplateItem => templateItem.name === existingTemplateItem.name)) {
						return sum
					}
					return sum.concat(templateItem)
				}, [])
			},
			currentTemplate() {
				const selectedBreakdownTemplateId = this.get('selectedBreakdownTemplateId')
				let currentTemplate = this.get('displayTemplateNameList').find(template => template.breakdownTemplateId == selectedBreakdownTemplateId)
				currentTemplate.components = this.get('breakdownTemplate').filter(template => template.breakdownTemplateId == selectedBreakdownTemplateId)

				let existingParts = this.get('parts').map(part=> {
					return { inventoryTypeId: part.inventoryTypeId, inventoryTypeName: part.inventoryTypeName }
				})
				currentTemplate.existingParts = existingParts.filter(part=> {
					return !currentTemplate.components.some(arrVal => part.inventoryTypeId == arrVal.inventoryTypeId)
				})

				return currentTemplate
			},

			tableColumns() {
				const chosenAppraisalOrigin = this.get('chosenAppraisalOrigin')
				const appraisedItems = this.get('appraisedItems')
				const viewSize = this.get('viewSize')
				const viewMode = this.get('viewMode')

				const smallViewHeaders = [
					{
						property: 'existsAndStatus',
						name: '',
						sortType: 'alphaNum',
						footer: {
							altProperty: 'exists',
							fn: 'COUNT',
							requiredValue: true,
						},
					},
					{	property: 'rank',		name: 'Rank', defaultSortColumn: true },
					{	property: 'tagNumber',		name: 'Tag #' },

					{	property: 'inventoryTypeName',		name: 'Part Type' },
					// { property: 'inventoryTypeId', name: 'Type #', defaultSortColumn: true },
					{
						property: 'fileCount',
						name: '',
						icon: 'fas fa-paperclip',
						title: 'Attachment Count',
						footer: {
							fn: 'SUM',
						},
					},
				]

				const mediumViewHeaders = smallViewHeaders.concat([

					{
						property: 'parentManufacturerName',
						name: 'Assy. Manufacturer',
					},
					{
						property: 'parentModelName',
						name: 'Assy. Model',
					},
					{
						property: 'partManufacturerName',
						name: 'Manufacturer',
					},
					{
						property: 'partModelName',
						name: 'Model',
					},
					{
						property: 'categoryName',
						name: 'Category',
					},
					{
						property: 'side',
						name: 'Side',
						columnWidth: '75px',
					},

				])

				if (this.get("inventoryFieldAccess.cost.read")) {
					mediumViewHeaders.push(
						{
							property: 'cost',
							name: 'Cost',
							sortType: 'alphaNum',
							columnWidth: '100px',
							footer: {
								fn: 'SUM',
								formatCurrency: true,
							},
						})
				}

				const largeViewHeaders = mediumViewHeaders.concat([
					{
						property: 'retailPrice',
						name: 'Retail',
						sortType: 'alphaNum',
						columnWidth: '100px',
						footer: {
							fn: 'SUM',
							formatCurrency: true,
						},
					},
					{
						property: 'wholesalePrice',
						sortType: 'alphaNum',
						name: 'Wholesale', columnWidth: '100px',
						footer: {
							fn: 'SUM',
							formatCurrency: true,
						},
					},
					{
						property: 'jobberPrice',
						name: 'Jobber',
						sortType: 'alphaNum',
						columnWidth: '100px',
						footer: {
							fn: 'SUM',
							formatCurrency: true,
						},
					},
					{
						property: 'retailCorePrice',
						name: 'Retail Core',
						sortType: 'alphaNum',
						columnWidth: '100px',
						footer: {
							fn: 'SUM',
							formatCurrency: true,
						},
					},
				])

				//const inventoryHeader = { property: 'notes', name: 'Notes' }

				const appraisalHeader = {
					property: 'appraisalValue',
					name: 'Appraised',
					title: chosenAppraisalOrigin ? chosenAppraisalOrigin.name : '',
					footer: {
						fn: 'SUM',
						formatCurrency: true,
					},
				}

				let headers

				switch (viewSize) {
					case 'SMALL':
						headers = smallViewHeaders
						break
					case 'MEDIUM':
						if (viewMode === 'LIST') {
							headers = smallViewHeaders
						} else if (viewMode === 'TABLE') {
							headers = mediumViewHeaders
						}
						break
					case 'LARGE':
						if (viewMode === 'LIST') {
							headers = smallViewHeaders
						} else if (viewMode === 'TABLE') {
							headers = largeViewHeaders
							/*
							if (!this.get('isBid')) {
								headers = headers.concat(inventoryHeader)
							}
							*/
							if (appraisedItems && appraisedItems.length > 0) {
								headers = headers.concat(appraisalHeader)
							}
						}
						break
				}

				return headers
			},
			tableHeaderProperties() {
				return this.get('tableColumns').map(header => header.property)
			},
			viewSize() {
				switch (this.get('bsBreakpoint')) {
					case 'xs':
					case 'sm':
						return 'SMALL'
					case 'md':
					case 'lg':
						return 'MEDIUM'
					case 'xl':
						return 'LARGE'
				}
			},

			inventoryTypeListbyTypeId() {
				const inventoryTypeList = this.get('inventoryTypeList')
				return inventoryTypeList.reduce((obj, item) => (obj[item.inventoryTypeId] = item, obj), {})
			},
			inventoryTypeAutoCompleteList() {
				const inventoryTypeList = this.get('inventoryTypeList')
				return inventoryTypeList.reduce((obj, item) => (obj[item.inventoryTypeId] = `${item.name} - ${item.inventoryTypeId}`, obj), {})
			},
			fileSupport() {
				return this.get('teardownType') !== 'BID'
			},
			tableInline() {
				return this.get('viewMode') === 'TABLE' && !this.get('showTeardownItemModal')
			},
			viewMode() {
				const breakpoint = this.get('bsBreakpoint')
				const userViewMode = this.get('userViewMode')

				return [ 'xl', 'lg' ].includes(breakpoint) ? userViewMode : 'TABLE'
			},
			listViewSelectionSortedIndex() {
				const listViewSelection = this.get('listViewSelection')
				const sortedAllTeardownItems = this.get('sortedAllTeardownItems')

				return sortedAllTeardownItems.findIndex(item => item.originalIndex === listViewSelection)
			},
		},
		async fileUpload(id, files) {
			const ractive = this

			try {
				// TODO: EE-112 is out to fix this mutation throwing "Cannot get inventoryId before it has been initialized"
				const res = await mediator.publish('uploadFileAttachment',	{
					file: files[0],
					relationId: id,
					relation: 'inventory',
					rank: null,
				})
				let parts = ractive.get('parts')
				const matchingPartIndex = parts.findIndex(part => part.innodbInventoryid === id)

				parts[matchingPartIndex].attachments.push({ fileId: res.data.createItemAttachment.fileId, public: res.data.createItemAttachment.public, rank: res.data.createItemAttachment.rank, ...res.data.createItemAttachment.file })
				ractive.set({ parts, savingFiles: ractive.get('savingFiles').filter(idSaving => idSaving !== id) })
				mediator.publish('showMessage', { heading: 'File Uploaded Successfully!', message: '', type: 'info', time: 10000 })
			} catch (err) {
				let message = err.message
				if (err?.extensions?.code === 'DUPLICATE_ATTACHMENT') {
					message = 'Duplicate attachments not allowed'
				}

				mediator.publish('showMessage', { heading: 'Error Uploading File!', message, type: 'danger', time: false })
				console.error(err)
			}
		},
		async loadTypeDataHistory(inventoryTypeId) {
			const ractive = this
			if (inventoryTypeId) {
				const variables =	{
					inventoryTypeId: parseInt(inventoryTypeId, 10),
				}
				try {
					const res = await mediator.publish('graphqlFetch', querylib.typeDataHistory, variables)
					if (res.typeDataHistory) {
						ractive.set({ typeDataHistory: res.typeDataHistory })
					}
				} catch (err) {
					alert('something went wrong getting autocomplete typedata information')
				}
			}
		},
		async checkModelCategoryPricing(item) {
			const ractive = this
			if (item.partModelId && item.categoryId) {
				const variables =	{
					modelId: parseInt(item.partModelId, 10),
					categoryId: parseInt(item.categoryId, 10),
				}
				try {
					const res = await mediator.publish('graphqlFetch', querylib.modelCategoryPricingQuery, variables)
					if (res.modelCategoryPricing) {
						const pricing = res.modelCategoryPricing.pricing
						//console.log(pricing)

						item.retailCorePrice = pricing.retailCore || item.retailCorePrice
						item.retailPrice = pricing.retail || item.retailPrice
						item.listPrice = pricing.list || item.listPrice
						item.wholesalePrice = pricing.wholesale || item.wholesalePrice
						item.jobberPrice = pricing.jobber || item.jobberPrice
					}
				} catch (err) {
					alert('something went wrong getting modelCategoryPricing')
				}
			}
			ractive.saveItemAPI(item)
		},
		async createNewSerial(item, serial) {
			const ractive = this

			const variables =	{
				createInventorySerialInput: {
					inventoryId: item.innodbInventoryid,
					number: serial,
				},
			}
			try {
				await mediator.publish('graphqlFetch', querylib.createInventorySerial, variables)
			} catch (err) {
				alert('something went wrong adding a serial')
			}

			ractive.reloadInventoryItem(item)
		},
		async editSerial(item, serial) {
			const ractive = this
			const variables =	{
				updateInventorySerialInput: {
					id: item.serials[0].id,
					number: serial,
				},
			}
			try {
				await mediator.publish('graphqlFetch', querylib.updateInventorySerial, variables)
			} catch (err) {
				alert('something went wrong editing a serial')
			}
			ractive.reloadInventoryItem(item)
		},
		async reloadInventoryItem(item) {
			const ractive = this
			let variables = {
				innodbInventoryid: item.innodbInventoryid,
				inventoryTypeId: item.inventoryTypeId,
				typeSetId: item.typeSetId,
			}
			try {
				const { inventory: part } = await mediator.publish('graphqlFetch', querylib.inventoryQuery, variables)

				part.modelId = part.model ? part.model.id : null
				part.manufacturerId = part.manufacturer ? part.manufacturer.id : null
				part.parentModelId = part.parentModel ? part.parentModel.id : null
				part.parentManufacturerId = part.parentManufacturer ? part.parentManufacturer.id : null
				part.locked = item.locked
				let savedItem = new myTeardownItem(part, ractive.get('inventoryTypeList'))

				ractive.upsert('parts', 'id', savedItem)
			} catch (err) {
				mediator.publish('showMessage', { heading: 'Error Reloading Part!', message: err.message, type: 'danger', time: false })
			}
		},
		async saveItemAPI(teardownItem) {
			const ractive = this
			mediator.publish('showMessage', { heading: 'Saving Part...', type: 'info', time: 10000 })
			let theQuery
			let variables = {}

			const lastSaveOperation = uuid()
			let isNewPart = false

			if (teardownItem.innodbInventoryid) { //its an update
				ractive.set({ lastSaveOperation })

				theQuery =	mutationLib.updateVehicleInventoryMutation

				variables.innodbInventoryid = teardownItem.innodbInventoryid
				variables.inventoryTypeId = teardownItem.inventoryTypeId
				variables.typeSetId = teardownItem.typeSetId

				variables.part = {
					cost: teardownItem.cost,
					retailPrice: teardownItem.retailPrice,
					wholesalePrice: teardownItem.wholesalePrice,
					jobberPrice: teardownItem.jobberPrice,
					retailCorePrice: teardownItem.retailCorePrice,
					description: teardownItem.description,
					manufacturerId: teardownItem.partManufacturerId,
					modelId: teardownItem.partModelId,
					parentManufacturerId: teardownItem.parentManufacturerId,
					parentModelId: teardownItem.parentModelId,
					notes: teardownItem.notes,
					categoryId: parseInt(teardownItem.categoryId, 10),
					status: teardownItem.status,
					side: teardownItem.side,
					tagNumber: teardownItem.tagNumber,
					shippingDimensions: teardownItem.shippingDimensions,
					oemNumber: teardownItem.oemNumber,
					condition: teardownItem.condition,
					userStatus: teardownItem.userStatus,
					typeData1: teardownItem.typeField1.data,
					typeData2: teardownItem.typeField2.data,
					typeData3: teardownItem.typeField3.data,
					typeData4: teardownItem.typeField4.data,
					public: teardownItem.public,
				}
				if (teardownItem.categoryId === -1) {
					delete variables.part.categoryId
				}
			} else { //it's new part
				isNewPart = true
				theQuery = mutationLib.newVehicleInventoryMutation

				variables.part = {
					storeId: parseInt(teardownItem.storeId, 10),
					inventoryTypeId: teardownItem.inventoryTypeId,
					vehicleId: parseInt(teardownItem.vehicleId, 10),
				}
			}
			try {
				const { part } = await mediator.publish('graphqlFetch', theQuery, variables)
				if (isNewPart || lastSaveOperation === ractive.get('lastSaveOperation')) {
					part.modelId = part.model ? part.model.id : null
					part.manufacturerId = part.manufacturer ? part.manufacturer.id : null
					part.parentModelId = part.parentModel ? part.parentModel.id : null
					part.parentManufacturerId = part.parentManufacturer ? part.parentManufacturer.id : null
					part.locked = teardownItem.locked
					let savedItem = new myTeardownItem(part, ractive.get('inventoryTypeList'))

					ractive.upsert('parts', 'id', savedItem)
					mediator.publish('showMessage', { heading: 'Part Saved!', message: '', type: 'success', time: 10000 })

					const sortedAllTeardownItems = ractive.get('sortedAllTeardownItems')
					const newItem = sortedAllTeardownItems.find(item => item.id === savedItem.id)
					//console.log(sortedAllTeardownItems, sortedAllTeardownItemsIndex)

					if (newItem) {
						ractive.set({ listViewSelection: newItem.originalIndex })
					}
					return savedItem
				} else {
					console.log("Another Save is in the works")
				}
			} catch (err) {
				mediator.publish('showMessage', { heading: 'Error Saving Part!', message: err.message, type: 'danger', time: false })
			}
		},
		oninit() {
			const ractive = this

			ractive.set({ defaultPart: new DefaultPart(ractive.get('parent.vehicleId'), ractive.get('parent.storeId')) })

			ractive.on('add-part-change', async context => {
			//	console.log(context.value)
				const value = context.value
				ractive.set({ selectedAddPartValue: '' })

				if (value) {
					const part = { ...ractive.get('defaultPart'), inventoryTypeId: parseInt(value, 10) }

					const newTeardownItem = new myTeardownItem(part,	ractive.get('inventoryTypeList'))

					try {
						const savedItem = await ractive.saveItemAPI(newTeardownItem)
						ractive.findComponent('itTable').setPageVisibleByItemId({ id: savedItem.id, keyName: 'id' })
					} catch (err) {
						mediator.publish('showMessage', { type: 'danger', heading: 'Failed to save part', message: err.message || err[0].message, time: false })
					}
				}
			})

			ractive.observe('listViewSelection', listviewSelection => {
			//	console.log(listviewSelection)
				const displayTeardown = ractive.get('displayTeardown')

				if (displayTeardown[listviewSelection]) {
					//console.log(displayTeardown[listviewSelection].inventoryTypeId)
					ractive.loadTypeDataHistory(displayTeardown[listviewSelection].inventoryTypeId)
				}
			})

			ractive.observe('userViewMode', userViewMode => {
			//	console.log(userViewMode)
				localStorage.setItem('userViewMode', userViewMode)
			}, { init: false })

			function handleResize() {
				ractive.set({ bsBreakpoint: getBootstrapBreakpoint() })
			}
			handleResize()
			window.addEventListener('resize', handleResize)

			ractive.on('list-item-clicked', ({ index }) => {
				ractive.set({ listViewSelection: index }).then(() => {
					if (this.get('viewMode') === 'TABLE') {
						ractive.set({ showTeardownItemModal: true, listViewSelection: index })
					}
				})
			})

			// #Region File Stuff
			ractive.on('file-count-clicked', (context, item) => {
				context.event.stopPropagation()
				const restoreShowTeardownItemModal = ractive.get('showTeardownItemModal')

				ractive.set({

					imageViewerShown: true,
					showTeardownItemModal: false,
					restoreShowTeardownItemModal,
					displayFiles: item.attachments.map(attachment => attachment.path),
					currentPhotoIndex: 0,
				})
			})

			ractive.on('itImageViewer.imageViewerClosed', () => {
				const restoreShowTeardownItemModal = ractive.get('restoreShowTeardownItemModal')

				ractive.set({
					displayFiles: [],
					showTeardownItemModal: restoreShowTeardownItemModal,
				})
			},
			)

			ractive.on('drag-file', (_context, id, event) => {
				if (ractive.get('fileSupport')) {
					if (id) {
						event.dataTransfer.setData('image', event.target.id)
						event.dataTransfer.effectAllowed = 'copy'
						ractive.set({ fileDraggedOverItemId: id })
					}
				}
			})

			ractive.on('drag-leave', () => {
				if (ractive.get('fileSupport')) {
					ractive.set({ fileDraggedOverItemId: null })
				}
			})

			ractive.on('drop-file', e => {
				const id = ractive.get('fileDraggedOverItemId')
				if (ractive.get('fileSupport') && id) {
					ractive.set({
						fileDraggedOverItemId: null,
						savingFiles: ractive.get('savingFiles').concat(id),
					}).then(() => {
						ractive.fileUpload(id, e.files)
					})
				}
			})

			ractive.on('add-attachments', () => {
				//we have to use a hidden input since we want to style our input differently
				ractive.find('#attachment-upload').click()
			})
			// #endregion
			ractive.on('partPagination', ({ direction }) => {
				const shownItemIndex = ractive.get('listViewSelectionSortedIndex')
				const sortedAllTeardownItems = ractive.get('sortedAllTeardownItems')
				const newShownItemIndex = direction === 'NEXT' ? shownItemIndex + 1 : shownItemIndex - 1

				if (newShownItemIndex <= sortedAllTeardownItems.length - 1 && newShownItemIndex > -1) {
					const newListViewSelection = sortedAllTeardownItems[newShownItemIndex].originalIndex
					ractive.set('listViewSelection', newListViewSelection).then(() => {
						ractive.findComponent('itTable').setPageVisibleByItemIndex(newShownItemIndex)
					})
				}
			})

			ractive.on('statusIndicatorClicked', async(_context, item) => {
				if (item.id) {
					item.status = toggleInventoryStatus(item.status, ractive.get('vehicleStatus'))
				}
				console.log("statusindicatorclicked", item.status)
				const savedItem = await ractive.saveItemAPI(item)
				//console.log("saveItemAPI", savedItem)
				if (ractive.get('hideUnavailableParts') && item.status === 'D') {
					await ractive.set({ listViewSelection: 0, showTeardownItemModal: false })
					ractive.findComponent('itTable').setPageVisibleByItemIndex(0)
				} else {
				//	console.log("setPageVisibleByItemId", savedItem.id)
					ractive.findComponent('itTable').setPageVisibleByItemId({ id: savedItem.id, keyName: 'id' })
				}
			})

			ractive.on('parentManufacturerSelection', (context, item) => {
				item.parentManufacturerId = parseInt(context.node.value, 10) || null
				item.parentModelId = null
				ractive.saveItemAPI(item)
			})

			ractive.on('parentModelSelection', (context, item) => {
				item.parentModelId = parseInt(context.node.value, 10) || null
				ractive.saveItemAPI(item)
			})

			ractive.on('partManufacturerSelection', (context, item) => {
				item.partManufacturerId = parseInt(context.node.value, 10) || null
				item.partModelId = null
				ractive.saveItemAPI(item)
			})

			ractive.on('partModelSelection', (context, item) => {
				const modelId = parseInt(context.node.value, 10) || null
				item.partModelId = modelId
				//console.log(item.partModelList)

				const model = item.partModelList.find(model => model.modelId == modelId)
				//console.log(model?.defaultShippingDimensions)
				if (model && model.defaultShippingDimensions) {
					item.shippingDimensions.weightUnit = model.defaultShippingDimensions.weightUnit || item.shippingDimensions.weightUnit
					item.shippingDimensions.measurementUnit = model.defaultShippingDimensions.measurementUnit || item.shippingDimensions.measurementUnit
					item.shippingDimensions.weight = model.defaultShippingDimensions.weight || item.shippingDimensions.weight
					item.shippingDimensions.length = model.defaultShippingDimensions.length || item.shippingDimensions.length
					item.shippingDimensions.width = model.defaultShippingDimensions.width || item.shippingDimensions.width
					item.shippingDimensions.height = model.defaultShippingDimensions.height || item.shippingDimensions.height
				}

				ractive.checkModelCategoryPricing(item)
			})

			ractive.on('partCategorySelection', (context, item) => {
				item.categoryId = context.node.value || null
				ractive.checkModelCategoryPricing(item)
			})

			ractive.on('tagNumberChange', (context, item) => {
				item.tagNumber = context.node.value || item.tagNumber
				ractive.saveItemAPI(item)
			})

			ractive.on('sideChange', (context, item) => {
				item.side = context.node.value || null
				ractive.saveItemAPI(item)
			})

			// #region Pricing handlers
			// These are all context.value because the currency input is WHACK
			ractive.on('costChange', (context, item) => {
				item.cost = context.value || null
				ractive.saveItemAPI(item)
			})

			ractive.on('retailPriceChange', (context, item) => {
				item.retailPrice = context.value || null
				ractive.saveItemAPI(item)
			})

			ractive.on('listPriceChange', (context, item) => {
				item.listPrice = context.value || null
				ractive.saveItemAPI(item)
			})

			ractive.on('wholesalePriceChange', (context, item) => {
				item.wholesalePrice = context.value || null
				ractive.saveItemAPI(item)
			})

			ractive.on('jobberPriceChange', (context, item) => {
				item.jobberPrice = context.value || null
				ractive.saveItemAPI(item)
			})

			ractive.on('retailCorePriceChange', (context, item) => {
				item.retailCorePrice = context.value || null
				ractive.saveItemAPI(item)
			})
			// #endregion
			// #region shipping dimensions handlers
			ractive.on('weightChange', (context, item) => {
				item.shippingDimensions.weight = parseFloat(context.node.value) || 0
				ractive.saveItemAPI(item)
			})

			ractive.on('weightUnitChange', (context, item) => {
				item.shippingDimensions.weightUnit = context.node.value
				ractive.saveItemAPI(item)
			})

			ractive.on('lengthChange', (context, item) => {
				item.shippingDimensions.length = parseFloat(context.node.value) || 0
				ractive.saveItemAPI(item)
			})

			ractive.on('widthChange', (context, item) => {
				item.shippingDimensions.width = parseFloat(context.node.value) || 0
				ractive.saveItemAPI(item)
			})

			ractive.on('heightChange', (context, item) => {
				item.shippingDimensions.height = parseFloat(context.node.value) || 0
				ractive.saveItemAPI(item)
			})

			ractive.on('measurementUnitChange', (context, item) => {
				item.shippingDimensions.measurementUnit = context.node.value
				ractive.saveItemAPI(item)
			})

			// #endregion

			ractive.on('descriptionChange', (context, item) => {
				item.description = context.node.value || null
				ractive.saveItemAPI(item)
			})

			ractive.on('notesChange', (context, item) => {
				item.notes = context.node.value || null
				ractive.saveItemAPI(item)
			})

			ractive.on('oemNumberChange', (context, item) => {
				item.oemNumber = context.node.value || ''
				ractive.saveItemAPI(item)
			})

			ractive.on('publicChange', (context, item) => {
				item.public = context.node.checked
				ractive.saveItemAPI(item)
			})

			ractive.on('serialNumberCreate', (context, item, serialNumber) => {
				ractive.createNewSerial(item, serialNumber)
				//alert(`this will either create or edit the serial: ${serialNumber}`)
			})

			ractive.on('serialNumberChange', (context, item, serialNumber) => {
				ractive.editSerial(item, serialNumber)
				//alert(`this will  edit the serial: ${serialNumber}`)
			})
			ractive.on('conditionChange', (context, item) => {
				item.condition = context.node.value || ''
				ractive.saveItemAPI(item)
			})

			ractive.on('userStatusChange', (context, item, userStatus) => {
				item.userStatus = context.node.value || ''
				if (item.userStatus.length > 1) {
					item.userStatus = item.userStatus.slice(0, 1)
				//	alert("Userstatus is can only be a single character")
				}
				ractive.saveItemAPI(item)
			})

			ractive.on('typeField1Change', (context, item) => {
				item.typeField1.data = context.node.value || ''
				ractive.saveItemAPI(item)
			})
			ractive.on('typeField2Change', (context, item) => {
				item.typeField2.data = context.node.value || ''
				ractive.saveItemAPI(item)
			})
			ractive.on('typeField3Change', (context, item) => {
				item.typeField3.data = context.node.value || ''
				ractive.saveItemAPI(item)
			})
			ractive.on('typeField4Change', (context, item) => {
				item.typeField4.data = context.node.value || ''
				ractive.saveItemAPI(item)
			})
		},
	})
}

function getClassFromInventoryStatus(status) {
	switch (status) {
		case 'A': return 'text-success'
		case 'H': return 'text-warning'
		case 'D': return 'text-danger'
		case 'S': return 'text-info'
		default: return ''
	}
}

function getTooltipTextFromInventoryStatus(status) {
	switch (status) {
		case 'A': return 'Available part. Click to set status to \'Deleted\'.'
		case 'H': return 'On Hold part. The status of this part can\'t be changed.'
		case 'D': return 'Deleted part. Click to set status to \'Available\'.'
		case 'S': return 'Sold part. The status of this part can\'t be changed.'
		default: return 'This part doesn\'t exist, click to create it.'
	}
}

function toggleInventoryStatus(status, vehicleStatus) {
	switch (status) {
		case 'D': return vehicleStatus
		default: return 'D'
	}
}

const partStatusIsToggleable = (exists, status) => true
