<script lang="ts">
	import type { ComponentProps } from 'svelte'

	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import Icon from '@isoftdata/svelte-icon'
	import { Table, Td } from '@isoftdata/svelte-table'
	import { decodeVin } from 'utility/vin-decode'
	import { validate as validateVin } from 'vinidator'
	import { tick, createEventDispatcher } from 'svelte'
	import debounce from 'debounce'

	export let show: boolean = false
	export async function open(lookupVin: string) {
		show = true
		vin = lookupVin
		await tick()
		const vinDecodeLookupInput = document.getElementById('vinDecodeLookupInput')
		if (vinDecodeLookupInput instanceof HTMLInputElement) {
			vinDecodeLookupInput?.select?.()
		}
		await performLookup(vin)
	}

	// ts-expect-error won't ignore this, but it's fine
	interface $$Props extends ComponentProps<Modal> {
		show?: boolean
	}

	let dispatch = createEventDispatcher<{ confirm: string }>()
	let vinDecodeIsLoading: boolean = false
	let vin: string = ''
	let year: number | null = null
	let make: string = ''
	let model: string = ''
	let validationMessage = ''
	let validVin: boolean | null = null
	let additionalInfo: Array<{ label: string; value: string }> = []
	async function performLookup(lookupVin: string) {
		vinDecodeIsLoading = true
		//TODO some sort of abort controller support to cancel previous requests
		try {
			const { isValid, message } = validateVin(lookupVin)
			validVin = lookupVin ? isValid : null
			if (isValid) {
				const decodeRes = await decodeVin(lookupVin)
				validationMessage = decodeRes?.validation.message ?? ''
				year = decodeRes?.year ?? null
				make = decodeRes?.make ?? ''
				model = decodeRes?.model ?? ''
				additionalInfo =
					decodeRes?.notes?.split('\n').map(row => {
						const [key, value] = row.split(':')
						return { label: key, value }
					}) ?? []
			} else {
				validationMessage = message.toLowerCase().indexOf('invalid') > -1 ? message : `Invalid VIN. ${message}`
				year = null
				make = ''
				model = ''
				additionalInfo = []
			}
		} catch (err) {
			console.error(err)
			validVin = false
			validationMessage = 'An error occurred while decoding the VIN.'
		} finally {
			vinDecodeIsLoading = false
		}
	}
	const debouncedVinLookup = debounce(() => performLookup(vin), 300)
</script>

<Modal
	title="VIN Lookup"
	modalSize="lg"
	bind:show
	cancelButtonText="Close"
	on:close={() => (show = false)}
	on:confirm={() => {
		show = false
		dispatch('confirm', vin)
	}}
	{...$$restProps}
>
	<div class="form-row justify-content-between mb-3">
		<div class="col-12 col-md-4">
			<Input
				id="vinDecodeLookupInput"
				label="Enter VIN to look up"
				bind:value={vin}
				on:input={() => {
					//Because vin validation is all client-side, we only need to debounce the vin input when it's a valid input
					if (validateVin(vin).isValid) {
						debouncedVinLookup()
					} else {
						performLookup(vin)
					}
				}}
				validation={{
					validator: value => (typeof value === 'string' ? validateVin(value).isValid : false),
					// You can put the value in this object if you want to validate this value instead of your node's value
					value: vin,
				}}
			/>
		</div>
		<div class="col-12 col-md-8 col-lg-6">
			<Input
				label="Result"
				value={validationMessage}
				readonly
				tabindex={-1}
			>
				<span
					slot="prepend"
					class="input-group-text"
					class:text-success={typeof validVin === 'boolean' && validVin}
					class:text-danger={typeof validVin === 'boolean' && !validVin}
				>
					<Icon icon={validVin ? 'circle-check' : 'circle-exclamation'} />
				</span>
			</Input>
		</div>
	</div>
	<div class="card">
		<h4 class="card-header">Decoded VIN Data</h4>
		<div
			class="card-body"
			style="overflow-y: auto;"
		>
			<div class="form-row">
				<div class="col-auto mb-3">
					<Input
						label="Year"
						value={year}
						type="number"
						disabled
					/>
				</div>
				<div class="col-auto">
					<Input
						label="Make"
						value={make}
						disabled
					/>
				</div>
				<div class="col-auto">
					<Input
						label="Model"
						value={model}
						disabled
					/>
				</div>
			</div>
			{#if vinDecodeIsLoading}
				<Icon
					isLoading
					size="2xl"
				/>
			{:else if additionalInfo?.length}
				<h5 class="mt-3">Additional Info</h5>
				<Table
					columns={[
						{ property: 'label', name: 'Label' },
						{ property: 'value', name: 'Value' },
					]}
					rows={additionalInfo}
					let:row
					responsive
				>
					<tr>
						<Td
							property="label"
							class="font-weight-bold">{row.label}</Td
						>
						<Td property="value">{row.value}</Td>
					</tr>
				</Table>
			{/if}
		</div>
	</div>
</Modal>
