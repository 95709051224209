export default {
    "name": "DecodeVin",
    "kind": "HoudiniQuery",
    "hash": "f2c44a8df431ef237e2e78efe432d2e81c4ee0c9f19548b4e5edd799d3c0f300",

    "raw": `query DecodeVin($vin: String!) {
  decodeVin(vin: $vin) {
    year
    make
    model
    notes
    validation {
      code
      isValid
      message
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "decodeVin": {
                "type": "DecodedVin",
                "keyRaw": "decodeVin(vin: $vin)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "year": {
                            "type": "Int",
                            "keyRaw": "year",
                            "nullable": true,
                            "visible": true
                        },

                        "make": {
                            "type": "String",
                            "keyRaw": "make",
                            "nullable": true,
                            "visible": true
                        },

                        "model": {
                            "type": "String",
                            "keyRaw": "model",
                            "nullable": true,
                            "visible": true
                        },

                        "notes": {
                            "type": "String",
                            "keyRaw": "notes",
                            "nullable": true,
                            "visible": true
                        },

                        "validation": {
                            "type": "VinValidation",
                            "keyRaw": "validation",

                            "selection": {
                                "fields": {
                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "isValid": {
                                        "type": "Boolean",
                                        "keyRaw": "isValid",
                                        "visible": true
                                    },

                                    "message": {
                                        "type": "String",
                                        "keyRaw": "message",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "vin": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=560f366d74e3b260f26d76239e9ed9b95c816b1b25cad9ac7994df5c9bcc8119";