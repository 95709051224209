import sessionStore, { getSession, type ClientSession } from 'stores/session'

let session = getSession()
sessionStore.subscribe(value => {
	session = value
})

export type PermissionCode = keyof Exclude<ClientSession['sessionPermissions'], null>

/** Whether the user has the given permission at the currently logged-in store */
export function checkSessionPermission(permissionCode: PermissionCode): boolean {
	if (session.sessionPermissions && !(permissionCode in session.sessionPermissions)) {
		console.warn(`Permission code "${permissionCode}" is not a valid permission code`)
	}

	return session.sessionPermissions?.[permissionCode] ?? false
}

/** Whether the user has the given permission at the specified store (or the currently logged-in store, if not specified) */
export function hasPermission(permissionCode: PermissionCode, storeId?: number): boolean {
	storeId ??= session.currentStore

	if (storeId === session.currentStore) {
		return checkSessionPermission(permissionCode)
	}

	const level = session.userPermissions[permissionCode]

	if (!level) {
		console.warn(`Permission code "${permissionCode}" is not a valid permission code`)
		return false
	}

	return level === 'Global' || (level === 'Store' && !!session.authorizedStores.find(store => store.id === storeId))
}
