export default {
    "name": "vehicleScreenBasicQuery",
    "kind": "HoudiniQuery",
    "hash": "d1370d833c33b568c86f65dbec06fcb3291762c6b3ba89c4a16ddcb4e5e5fe86",

    "raw": `query vehicleScreenBasicQuery($vehicleId: Int!) {
  vehicle(id: $vehicleId) {
    id
    vin
    manufacturer {
      id
      name
    }
    model {
      id
      name
      active
    }
    year
    mileage
    description
    notes
    stockNumber
    location
    store {
      id
      name
      code
    }
    status
    dismantled
    bodyStyle
    categoryType {
      id
      name
      description
    }
    externalColor
    externalColorCode
    internalColor
    internalColorCode
    userStatus
    titleNumber
    titleStatus
    titleState
    titleType
    titleReceivedDate
    claimNumber
    purchasedFrom
    adjuster
    dismantleDate
    fileNumber
    junkingNumber
    pickUpLocation
    sellerContact
    sellerPhone
    sellerInfo
    topImage {
      id
      name
      path
      host
      url
    }
    dismantler {
      id
      name
    }
    purchaseDate
    receivedDate
    glCategory {
      id
      name
    }
    inventoryGlCategory {
      id
      name
    }
    wholeUnit {
      inventoryType {
        id
        setId
        name
        categories {
          id
          name
        }
        typeData1History
        typeData2History
        typeData3History
        typeData4History
      }
      model {
        id
        name
        active
      }
      category {
        id
        name
        description
      }
      coreCost
      cost
      partNumber
      defaultVendor {
        id
      }
      distributorPrice
      distributorCorePrice
      jobberPrice
      jobberCorePrice
      retailPrice
      retailCorePrice
      listPrice
      wholesalePrice
      wholesaleCorePrice
      inventoryTypeId
      vehicleYear
      vehicleMake
      vehicleModel
      storeId
      condition
      saleClass {
        code
        name
        id
      }
      public
      description
      replenishable
      averageCost
      averageCoreCost
      coreRequired
      coreRequiredToVendor
      deplete
      quantity
      typeField1 {
        label
        data
      }
      typeField2 {
        label
        data
      }
      typeField3 {
        label
        data
      }
      typeField4 {
        label
        data
      }
      optionValues {
        id
        optionId
        value
        inventoryId
      }
      id
    }
    taxable
    public
    inStock
    sellerIsCompany
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vehicle": {
                "type": "Vehicle",
                "keyRaw": "vehicle(id: $vehicleId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "vin": {
                            "type": "String",
                            "keyRaw": "vin",
                            "nullable": true,
                            "visible": true
                        },

                        "manufacturer": {
                            "type": "Manufacturer",
                            "keyRaw": "manufacturer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "model": {
                            "type": "Model",
                            "keyRaw": "model",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "year": {
                            "type": "UInt",
                            "keyRaw": "year",
                            "nullable": true,
                            "visible": true
                        },

                        "mileage": {
                            "type": "UInt",
                            "keyRaw": "mileage",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "notes": {
                            "type": "String",
                            "keyRaw": "notes",
                            "visible": true
                        },

                        "stockNumber": {
                            "type": "String",
                            "keyRaw": "stockNumber",
                            "visible": true
                        },

                        "location": {
                            "type": "String",
                            "keyRaw": "location",
                            "visible": true
                        },

                        "store": {
                            "type": "Store",
                            "keyRaw": "store",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "status": {
                            "type": "StatusEnum",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "dismantled": {
                            "type": "Boolean",
                            "keyRaw": "dismantled",
                            "visible": true
                        },

                        "bodyStyle": {
                            "type": "String",
                            "keyRaw": "bodyStyle",
                            "nullable": true,
                            "visible": true
                        },

                        "categoryType": {
                            "type": "Category",
                            "keyRaw": "categoryType",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "externalColor": {
                            "type": "String",
                            "keyRaw": "externalColor",
                            "nullable": true,
                            "visible": true
                        },

                        "externalColorCode": {
                            "type": "String",
                            "keyRaw": "externalColorCode",
                            "nullable": true,
                            "visible": true
                        },

                        "internalColor": {
                            "type": "String",
                            "keyRaw": "internalColor",
                            "nullable": true,
                            "visible": true
                        },

                        "internalColorCode": {
                            "type": "String",
                            "keyRaw": "internalColorCode",
                            "nullable": true,
                            "visible": true
                        },

                        "userStatus": {
                            "type": "String",
                            "keyRaw": "userStatus",
                            "nullable": true,
                            "visible": true
                        },

                        "titleNumber": {
                            "type": "String",
                            "keyRaw": "titleNumber",
                            "visible": true
                        },

                        "titleStatus": {
                            "type": "String",
                            "keyRaw": "titleStatus",
                            "visible": true
                        },

                        "titleState": {
                            "type": "String",
                            "keyRaw": "titleState",
                            "visible": true
                        },

                        "titleType": {
                            "type": "String",
                            "keyRaw": "titleType",
                            "visible": true
                        },

                        "titleReceivedDate": {
                            "type": "DateTime",
                            "keyRaw": "titleReceivedDate",
                            "nullable": true,
                            "visible": true
                        },

                        "claimNumber": {
                            "type": "String",
                            "keyRaw": "claimNumber",
                            "nullable": true,
                            "visible": true
                        },

                        "purchasedFrom": {
                            "type": "String",
                            "keyRaw": "purchasedFrom",
                            "visible": true
                        },

                        "adjuster": {
                            "type": "String",
                            "keyRaw": "adjuster",
                            "visible": true
                        },

                        "dismantleDate": {
                            "type": "Date",
                            "keyRaw": "dismantleDate",
                            "nullable": true,
                            "visible": true
                        },

                        "fileNumber": {
                            "type": "String",
                            "keyRaw": "fileNumber",
                            "visible": true
                        },

                        "junkingNumber": {
                            "type": "String",
                            "keyRaw": "junkingNumber",
                            "visible": true
                        },

                        "pickUpLocation": {
                            "type": "String",
                            "keyRaw": "pickUpLocation",
                            "visible": true
                        },

                        "sellerContact": {
                            "type": "String",
                            "keyRaw": "sellerContact",
                            "visible": true
                        },

                        "sellerPhone": {
                            "type": "String",
                            "keyRaw": "sellerPhone",
                            "visible": true
                        },

                        "sellerInfo": {
                            "type": "String",
                            "keyRaw": "sellerInfo",
                            "visible": true
                        },

                        "topImage": {
                            "type": "File",
                            "keyRaw": "topImage",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "host": {
                                        "type": "String",
                                        "keyRaw": "host",
                                        "visible": true
                                    },

                                    "url": {
                                        "type": "String",
                                        "keyRaw": "url",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "dismantler": {
                            "type": "UserAccount",
                            "keyRaw": "dismantler",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "purchaseDate": {
                            "type": "Date",
                            "keyRaw": "purchaseDate",
                            "nullable": true,
                            "visible": true
                        },

                        "receivedDate": {
                            "type": "Date",
                            "keyRaw": "receivedDate",
                            "nullable": true,
                            "visible": true
                        },

                        "glCategory": {
                            "type": "GlCategory",
                            "keyRaw": "glCategory",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "inventoryGlCategory": {
                            "type": "GlCategory",
                            "keyRaw": "inventoryGlCategory",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "wholeUnit": {
                            "type": "Inventory",
                            "keyRaw": "wholeUnit",

                            "selection": {
                                "fields": {
                                    "inventoryType": {
                                        "type": "InventoryType",
                                        "keyRaw": "inventoryType",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "setId": {
                                                    "type": "Int",
                                                    "keyRaw": "setId",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "categories": {
                                                    "type": "Category",
                                                    "keyRaw": "categories",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true,
                                                                "nullable": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "typeData1History": {
                                                    "type": "String",
                                                    "keyRaw": "typeData1History",
                                                    "visible": true
                                                },

                                                "typeData2History": {
                                                    "type": "String",
                                                    "keyRaw": "typeData2History",
                                                    "visible": true
                                                },

                                                "typeData3History": {
                                                    "type": "String",
                                                    "keyRaw": "typeData3History",
                                                    "visible": true
                                                },

                                                "typeData4History": {
                                                    "type": "String",
                                                    "keyRaw": "typeData4History",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "model": {
                                        "type": "Model",
                                        "keyRaw": "model",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "category": {
                                        "type": "Category",
                                        "keyRaw": "category",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "coreCost": {
                                        "type": "Decimal2",
                                        "keyRaw": "coreCost",
                                        "visible": true
                                    },

                                    "cost": {
                                        "type": "Decimal2",
                                        "keyRaw": "cost",
                                        "visible": true
                                    },

                                    "partNumber": {
                                        "type": "String",
                                        "keyRaw": "partNumber",
                                        "visible": true
                                    },

                                    "defaultVendor": {
                                        "type": "Vendor",
                                        "keyRaw": "defaultVendor",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "distributorPrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "distributorPrice",
                                        "visible": true
                                    },

                                    "distributorCorePrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "distributorCorePrice",
                                        "visible": true
                                    },

                                    "jobberPrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "jobberPrice",
                                        "visible": true
                                    },

                                    "jobberCorePrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "jobberCorePrice",
                                        "visible": true
                                    },

                                    "retailPrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "retailPrice",
                                        "visible": true
                                    },

                                    "retailCorePrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "retailCorePrice",
                                        "visible": true
                                    },

                                    "listPrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "listPrice",
                                        "visible": true
                                    },

                                    "wholesalePrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "wholesalePrice",
                                        "visible": true
                                    },

                                    "wholesaleCorePrice": {
                                        "type": "Decimal2",
                                        "keyRaw": "wholesaleCorePrice",
                                        "visible": true
                                    },

                                    "inventoryTypeId": {
                                        "type": "UInt",
                                        "keyRaw": "inventoryTypeId",
                                        "visible": true
                                    },

                                    "vehicleYear": {
                                        "type": "UInt",
                                        "keyRaw": "vehicleYear",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vehicleMake": {
                                        "type": "String",
                                        "keyRaw": "vehicleMake",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "vehicleModel": {
                                        "type": "String",
                                        "keyRaw": "vehicleModel",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "storeId": {
                                        "type": "UInt",
                                        "keyRaw": "storeId",
                                        "visible": true
                                    },

                                    "condition": {
                                        "type": "String",
                                        "keyRaw": "condition",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "saleClass": {
                                        "type": "SaleClass",
                                        "keyRaw": "saleClass",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "replenishable": {
                                        "type": "Boolean",
                                        "keyRaw": "replenishable",
                                        "visible": true
                                    },

                                    "averageCost": {
                                        "type": "Decimal2",
                                        "keyRaw": "averageCost",
                                        "visible": true
                                    },

                                    "averageCoreCost": {
                                        "type": "Decimal2",
                                        "keyRaw": "averageCoreCost",
                                        "visible": true
                                    },

                                    "coreRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "coreRequired",
                                        "visible": true
                                    },

                                    "coreRequiredToVendor": {
                                        "type": "Boolean",
                                        "keyRaw": "coreRequiredToVendor",
                                        "visible": true
                                    },

                                    "deplete": {
                                        "type": "Boolean",
                                        "keyRaw": "deplete",
                                        "visible": true
                                    },

                                    "quantity": {
                                        "type": "Decimal6",
                                        "keyRaw": "quantity",
                                        "visible": true
                                    },

                                    "typeField1": {
                                        "type": "InventoryTypeField",
                                        "keyRaw": "typeField1",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "data": {
                                                    "type": "String",
                                                    "keyRaw": "data",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "typeField2": {
                                        "type": "InventoryTypeField",
                                        "keyRaw": "typeField2",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "data": {
                                                    "type": "String",
                                                    "keyRaw": "data",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "typeField3": {
                                        "type": "InventoryTypeField",
                                        "keyRaw": "typeField3",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "data": {
                                                    "type": "String",
                                                    "keyRaw": "data",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "typeField4": {
                                        "type": "InventoryTypeField",
                                        "keyRaw": "typeField4",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "data": {
                                                    "type": "String",
                                                    "keyRaw": "data",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "optionValues": {
                                        "type": "InventoryOptionValue",
                                        "keyRaw": "optionValues",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "optionId": {
                                                    "type": "Int",
                                                    "keyRaw": "optionId",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "inventoryId": {
                                                    "type": "Int",
                                                    "keyRaw": "inventoryId",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "taxable": {
                            "type": "Boolean",
                            "keyRaw": "taxable",
                            "visible": true
                        },

                        "public": {
                            "type": "Boolean",
                            "keyRaw": "public",
                            "visible": true
                        },

                        "inStock": {
                            "type": "Boolean",
                            "keyRaw": "inStock",
                            "visible": true
                        },

                        "sellerIsCompany": {
                            "type": "Boolean",
                            "keyRaw": "sellerIsCompany",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "vehicleId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d740132ce0e0f56579c178b0b424b7e5f2c1e015e11943860041e49d0ab1084e";