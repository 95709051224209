export default {
    "name": "PartSearchResultsAllocations",
    "kind": "HoudiniQuery",
    "hash": "445973e29f8e97124c6128558dbbb4f69cd30e410f2edaf9b8bfe4eb6d9be98a",

    "raw": `query PartSearchResultsAllocations($filter: InventoryFilter) {
  inventories(filter: $filter) {
    items {
      id
      allocations {
        salesOrderLines {
          items {
            id
          }
        }
        jobParts {
          id
        }
        transferOrderLines {
          id
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventories": {
                "type": "InventoryList",
                "keyRaw": "inventories(filter: $filter)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "Inventory",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "allocations": {
                                        "type": "InventoryAllocations",
                                        "keyRaw": "allocations",

                                        "selection": {
                                            "fields": {
                                                "salesOrderLines": {
                                                    "type": "SalesOrderLineList",
                                                    "keyRaw": "salesOrderLines",

                                                    "selection": {
                                                        "fields": {
                                                            "items": {
                                                                "type": "SalesOrderLine",
                                                                "keyRaw": "items",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "UInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "jobParts": {
                                                    "type": "JobPart",
                                                    "keyRaw": "jobParts",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "transferOrderLines": {
                                                    "type": "TransferOrderLine",
                                                    "keyRaw": "transferOrderLines",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "UInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "InventoryFilter"
        },

        "types": {
            "StringFilter": {
                "eq": "String",
                "gt": "String",
                "gte": "String",
                "lt": "String",
                "lte": "String",
                "ne": "String"
            },

            "InventoryFieldFilter": {
                "name": "String",
                "value": "StringFilter"
            },

            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "Decimal2Filter": {
                "eq": "Decimal2",
                "gt": "Decimal2",
                "gte": "Decimal2",
                "lt": "Decimal2",
                "lte": "Decimal2"
            },

            "IntFilter": {
                "eq": "Int",
                "gt": "Int",
                "gte": "Int",
                "lt": "Int",
                "lte": "Int",
                "ne": "Int"
            },

            "Decimal6Filter": {
                "eq": "Decimal6",
                "gt": "Decimal6",
                "gte": "Decimal6",
                "lt": "Decimal6",
                "lte": "Decimal6",
                "ne": "Decimal6"
            },

            "SmartSearchFilter": {
                "keyword": "String",
                "moduleIds": "Int"
            },

            "InventoryFilter": {
                "additionalFields": "InventoryFieldFilter",
                "assemblyManufacturerId": "Int",
                "assemblyModelId": "Int",
                "category": "String",
                "condition": "String",
                "customFields": "InventoryFieldFilter",
                "dateSold": "DateFilter",
                "defaultVendorCode": "String",
                "defaultVendorId": "Int",
                "description": "String",
                "distributorCorePrice": "Decimal2Filter",
                "distributorPrice": "Decimal2Filter",
                "enteredByUser": "IntFilter",
                "ids": "Int",
                "inventoryTypeId": "IntFilter",
                "isCore": "Boolean",
                "jobberCorePrice": "Decimal2Filter",
                "jobberPrice": "Decimal2Filter",
                "keyword": "String",
                "location": "String",
                "manufacturerId": "Int",
                "modelId": "Int",
                "notes": "String",
                "partNumber": "String",
                "partNumberStripped": "String",
                "quantity": "Decimal6Filter",
                "quantityAvailable": "Decimal6Filter",
                "quantityOnHold": "Decimal6Filter",
                "quantityOnOrder": "Decimal6Filter",
                "replenishable": "Boolean",
                "shippingHeight": "Decimal2Filter",
                "shippingLength": "Decimal2Filter",
                "shippingWidth": "Decimal6Filter",
                "side": "SideEnum",
                "skuNumber": "Int",
                "smartSearchFilters": "SmartSearchFilter",
                "statuses": "InventorySearchStatus",
                "stores": "Int",
                "tagNumber": "String",
                "typeField1": "String",
                "typeField2": "String",
                "typeField3": "String",
                "typeField4": "String",
                "userStatus": "String",
                "vehicleId": "Int",
                "vehicleManufacturerId": "Int",
                "vehicleModelId": "Int",
                "vendorLeadTime": "IntFilter",
                "vin": "String",
                "weight": "Decimal6Filter",
                "year": "IntFilter"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=9dc856b805fcaf611aa91c482e4cb339909e7d515d3f6d703ba01bf19b5726aa";