export default {
    "name": "CollectionMembershipLocalInventory",
    "kind": "HoudiniFragment",
    "hash": "c4747d9623f69ab0ac5e7d340c2c7c402aa4efd4f650c63aa1f35df5a2c72bf9",

    "raw": `fragment CollectionMembershipLocalInventory on Inventory {
  innodbInventoryid: id
  storeId
  tagNumber
  inventoryType {
    id
    name
  }
  quantity
  quantityAvailable
  manufacturer {
    id
    name
  }
  model {
    id
    name
  }
  category {
    id
    name
  }
  id
  __typename
}
`,

    "rootType": "Inventory",
    "stripVariables": [],

    "selection": {
        "fields": {
            "innodbInventoryid": {
                "type": "UInt",
                "keyRaw": "innodbInventoryid",
                "visible": true
            },

            "storeId": {
                "type": "UInt",
                "keyRaw": "storeId",
                "visible": true
            },

            "tagNumber": {
                "type": "String",
                "keyRaw": "tagNumber",
                "visible": true
            },

            "inventoryType": {
                "type": "InventoryType",
                "keyRaw": "inventoryType",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "quantity": {
                "type": "Decimal6",
                "keyRaw": "quantity",
                "visible": true
            },

            "quantityAvailable": {
                "type": "Decimal6",
                "keyRaw": "quantityAvailable",
                "visible": true
            },

            "manufacturer": {
                "type": "Manufacturer",
                "keyRaw": "manufacturer",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "model": {
                "type": "Model",
                "keyRaw": "model",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "category": {
                "type": "Category",
                "keyRaw": "category",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "id": {
                "type": "UInt",
                "keyRaw": "id",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=fbe28c35e494c8d27475d9e328ae19c546eeb131480536ea16105c7c73b24f72";