export default {
    "name": "ChangeDetectionInterval",
    "kind": "HoudiniQuery",
    "hash": "6e159805f933e1d107f45704b22bbc2295692154876c9a45d34170eb724d79d7",

    "raw": `query ChangeDetectionInterval {
  settingValues {
    preferences {
      changeDetectionInterval
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "settingValues": {
                "type": "SettingValues",
                "keyRaw": "settingValues",

                "selection": {
                    "fields": {
                        "preferences": {
                            "type": "PreferencesSettingValues",
                            "keyRaw": "preferences",

                            "selection": {
                                "fields": {
                                    "changeDetectionInterval": {
                                        "type": "Int",
                                        "keyRaw": "changeDetectionInterval",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=7213c005fb40a02aa9669fb7d5e0d8cce5ad4fa275cb0fc7e0aa25c35e582f21";