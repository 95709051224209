export default {
    "name": "LIB_vehicleinventory",
    "kind": "HoudiniQuery",
    "hash": "7d675e710450ebe4fb3538bd186da5f2258da86c9a9f9098d9d7f2efac1c126f",

    "raw": `query LIB_vehicleinventory($filter: VehicleFilter) {
  vehicles(filter: $filter) {
    items {
      vehicleId: id
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vehicles": {
                "type": "VehicleList",
                "keyRaw": "vehicles(filter: $filter)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "Vehicle",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "vehicleId": {
                                        "type": "UInt",
                                        "keyRaw": "vehicleId",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "VehicleFilter"
        },

        "types": {
            "StringFilter": {
                "eq": "String",
                "gt": "String",
                "gte": "String",
                "lt": "String",
                "lte": "String",
                "ne": "String"
            },

            "InventoryFieldFilter": {
                "name": "String",
                "value": "StringFilter"
            },

            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "Decimal2Filter": {
                "eq": "Decimal2",
                "gt": "Decimal2",
                "gte": "Decimal2",
                "lt": "Decimal2",
                "lte": "Decimal2"
            },

            "IntFilter": {
                "eq": "Int",
                "gt": "Int",
                "gte": "Int",
                "lt": "Int",
                "lte": "Int",
                "ne": "Int"
            },

            "VehicleFilter": {
                "adjuster": "String",
                "bodyStyle": "String",
                "category": "String",
                "claimNumber": "String",
                "condition": "String",
                "customFields": "InventoryFieldFilter",
                "dateEntered": "DateFilter",
                "description": "String",
                "dismantled": "Boolean",
                "dismantlerId": "UInt",
                "enteredByUserId": "UInt",
                "externalColor": "String",
                "fileNumber": "String",
                "junkingNumber": "String",
                "location": "String",
                "make": "String",
                "manufacturerId": "UInt",
                "model": "String",
                "modelId": "UInt",
                "pickupLocation": "String",
                "purchasedFrom": "String",
                "retailPrice": "Decimal2Filter",
                "search": "String",
                "sellerContact": "String",
                "sellerInfo": "String",
                "series": "String",
                "statuses": "String",
                "stockNumber": "String",
                "stores": "Int",
                "titleNumber": "String",
                "titleStatus": "String",
                "titleType": "String",
                "type": "String",
                "typeField1": "String",
                "typeField2": "String",
                "typeField3": "String",
                "typeField4": "String",
                "typeIds": "Int",
                "userStatuses": "String",
                "vehicleId": "UInt",
                "vin": "String",
                "year": "IntFilter"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=fb51b572c551728e56529c5c43a282c6ac3dc1e4cb2c20ae2a306399ff30f1d9";