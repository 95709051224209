import { graphql } from '$houdini'

export const decodeVinQuery = graphql(`
	query DecodeVin($vin: String!) {
		decodeVin(vin: $vin) {
			year
			make
			model
			notes
			validation {
				code
				isValid
				message
			}
		}
	}
`)

export async function decodeVin(vin: string) {
	return (await decodeVinQuery.fetch({ variables: { vin } }))?.data?.decodeVin ?? null
}
