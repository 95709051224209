export default {
    "name": "EnteredBy",
    "kind": "HoudiniFragment",
    "hash": "716bb25bd103593f16b5a3130fc5e4c35fd7f4b4c82c2c97d7f6da0c773c3a72",

    "raw": `fragment EnteredBy on Inventory {
  enteredBy {
    id
    name
  }
  id
  __typename
}
`,

    "rootType": "Inventory",
    "stripVariables": [],

    "selection": {
        "fields": {
            "enteredBy": {
                "type": "UserAccount",
                "keyRaw": "enteredBy",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "id": {
                "type": "UInt",
                "keyRaw": "id",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=6dd89cb977dc6f7d33f9259b7f70442478813c8d4bc0f423a073dc332b88ab7d";