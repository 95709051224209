import makeStateRouter from 'abstract-state-router'
import mannish from 'mannish'
import * as currency from '@isoftdata/utility-currency'
import { stringToBoolean } from '@isoftdata/utility-string'
import { booleanToString } from '@isoftdata/utility-boolean'

import { checkSessionPermission } from 'utility/permission'
import makeRenderer from '@isoftdata/ractive-svelte-state-renderer-wrapper'
import buildApiUrl from 'utility/build-api-url'
import { getSession } from 'stores/session'
import { writable } from 'svelte/store'

function isChildState({ childState, parentState }: { childState: string; parentState: string }) {
	return childState.indexOf(parentState) > -1
}

const { microservicehost, apiURL } = buildApiUrl()

const imgURL = microservicehost
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const mediator = mannish() as Mediator
const ractiveRendererOptions = {
	data: {
		toLocaleDateString(dateString: string) {
			if (dateString === null || dateString === undefined) {
				return ''
			} //otherwise, toLocaleDateString will return the start of unix time
			dateString = dateString.length === 10 ? `${dateString}T23:59:59Z` : dateString //mad hax bro
			return new Date(dateString).toLocaleDateString()
		},
		formatCurrency(decimalString: string, options: Parameters<typeof currency.format>[1]) {
			//pass false for includeSymbol option if you're calling this for an input,
			//like the examples shown here: https://getbootstrap.com/docs/4.0/components/forms/#inline-forms
			return currency.format(decimalString, options)
		},
		getValidWebsiteURL(url: string) {
			if (!url.startsWith('http')) {
				return `http://${url}`
			}
			return url
		},
		stringToBoolean,
		booleanToString,
		getImgHostURL: (imgPath: string) => {
			return `${imgURL}${imgPath}`
		},
		getImgURL: (img: { id: number; name: string }) => {
			return `${imgURL}/file/${img.id}/${img.name}`
		},
		checkSessionPermission,
	},
	checkSessionPermission,
}

// Track whether a hot update is currently ocurring, so we can disable certain behaviors during that time
const isHotUpdating = writable(false)

// These fucntions get tree shaken in production
import.meta.hot?.on('vite:beforeUpdate', () => {
	isHotUpdating.set(true)
})

import.meta.hot?.on('vite:afterUpdate', () => {
	isHotUpdating.set(false)
})

const svelteContext = new Map<string, unknown>([
	['apiURL', apiURL],
	['checkSessionPermission', checkSessionPermission],
	['isChildState', isChildState],
	['mediator', mediator],
	['isHotUpdating', isHotUpdating],
	// can't put the stateRouter in here since we need to init this before the stateRouter is created
])

const svelteRendererOptions = {
	props: {},
	// Svelte default context, you can call getContext<Type>('key') to get the value in a svelte component
	context: svelteContext,
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const stateRenderer = makeRenderer(ractiveRendererOptions, svelteRendererOptions)

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const stateRouter = makeStateRouter(stateRenderer, document.querySelector('body'))
const context = {
	stateRouter,
	apiURL,
	mediator,
	isChildState,
	checkSessionPermission,
}
svelteContext.set('stateRouter', stateRouter)

import ractiveSelectOnFocus from 'ractive-select-on-focus'
import ractiveMethodUpsert from '@isoftdata/ractive-method-upsert'
import ractiveDecoratorValid from '@isoftdata/ractive-decorator-valid'

declare const Ractive: Static
Ractive.decorators.selectOnFocus = ractiveSelectOnFocus as Decorator
Ractive.decorators.valid = ractiveDecoratorValid as Decorator
Ractive.decorators.focus = node => {
	node.focus()
	return {
		teardown() {
			//
		},
	}
}
Ractive.defaults.upsert = ractiveMethodUpsert as keyof ValueMap

import component from './App.svelte'
import type { Mediator } from 'types/mediator'
import type { Decorator, Static, ValueMap } from 'ractive'

stateRouter.addState({
	route: '',
	name: 'app',
	template: {
		svelte: true,
		component,
	},
	defaultChild: 'part-search',
	querystringParameters: ['sessionToken', 'sessionId', 'appLayout'],
	async resolve() {
		const user = getSession()

		if (!user) {
			throw { redirectTo: { name: 'login' } }
		}

		return Promise.resolve()
	},
})

Object.entries(import.meta.glob('./states/**/*.[tj]s', { import: 'default', eager: true }))
	.sort((a, b) => {
		const depthA = a[0].split('/').length
		const depthB = b[0].split('/').length
		return depthA - depthB
	})
	.forEach(([_, createState]) => {
		if (typeof createState === 'function') {
			createState(context)
		}
	})

Object.values(import.meta.glob('./services/**/*.[tj]s', { import: 'default', eager: true })).forEach(registerService => {
	if (typeof registerService === 'function') {
		registerService(context)
	}
})
//Svelte appends to the body rather than setting the innerHTML,
//so we need to remove this loading-view div before we render
document.getElementById('loading-view')?.remove()
stateRouter.evaluateCurrentRoute('app')
