import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/vehicleScreenBasicQuery'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class vehicleScreenBasicQueryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "vehicleScreenBasicQueryStore",
			variables: true,
		})
	}
}

export async function load_vehicleScreenBasicQuery(params) {
	await initClient()

	const store = new vehicleScreenBasicQueryStore()

	await store.fetch(params)

	return {
		vehicleScreenBasicQuery: store,
	}
}
