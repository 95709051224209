<script lang="ts">
	import Modal from '@isoftdata/svelte-modal'
	import Button from '@isoftdata/svelte-button'
	import { onDestroy, onMount, tick } from 'svelte'
	import { isEqual } from 'date-fns'
	import { graphql } from '$houdini'

	/** Fn to check for local changes */
	export let hasLocalChanges: () => boolean
	/** Fn to check for remote changes */
	export let getRemoteDateModified: () => Promise<Date | null>
	/** Fn to refresh the screen / entity */
	export let refresh: () => void | Promise<void>
	/** Compared to the remote dateModified to see if any remote chances occurred*/
	export let localDateModified: Date | null
	/** If present, the second button will read "Cancel {verb}" instead of "Refresh Manually Later"*/
	export let verb: string = ''
	// Dillon's notes had these as two separate things, but they seem like the same thing?
	export let fullObjectName: string
	export let autoRefreshObjectTitle: string

	/** Set this to true if you want to temporarily ignore refresh events, like if you have a modal open*/
	export let ignoreRefreshEvent = false

	// Default is 15 seconds, but fetch the actual value from the server on mount
	let intervalSeconds = 15 // seconds

	let intervalId: ReturnType<typeof setInterval> | null = null
	let showCollisonModal = false
	let shouldUseVerb = true

	export function startTimer() {
		if (!intervalId) {
			intervalId = setInterval(() => {
				checkForChanges(false).catch(console.error)
			}, intervalSeconds * 1000)
		}
	}

	export function stopTimer() {
		if (intervalId) {
			clearInterval(intervalId)
			intervalId = null
		}
	}

	async function hasRemoteChanges() {
		const remoteDateModified = await getRemoteDateModified()

		return !!remoteDateModified && !!localDateModified && !isEqual(localDateModified, remoteDateModified)
	}

	/** Checks whether it is "safe" to save the entity, and if not, shows the collision modal. Returns true if saving should continue.*/
	export async function isSafeToSaveEntity() {
		const hasRemote = await hasRemoteChanges()
		if (!hasRemote) {
			return true
		}
		if (hasLocalChanges()) {
			showCollisonModal = true
			await tick()
			document.getElementById('refreshAndDiscardButton')?.focus()
			return false
		}
		return true
	}

	async function checkForChanges(useVerb = true) {
		if (ignoreRefreshEvent || showCollisonModal) {
			return
		}

		try {
			const hasRemote = await hasRemoteChanges()
			if (!hasRemote) {
				return
			}
		} catch (e) {
			console.error(e)
			// Don't show warning in dev (for now?) bc I'm tired of seeing it every time the API restarts
			if (import.meta.env.PROD && confirm('Error checking for remote changes. Do you want to disable auto refresh?')) {
				stopTimer()
			}
			return
		}

		if (!hasLocalChanges()) {
			return await refresh()
		}
		shouldUseVerb = useVerb
		showCollisonModal = true
		await tick()
		document.getElementById('refreshAndDiscardButton')?.focus()
	}

	const changeDetectionIntervalQuery = graphql(`
		query ChangeDetectionInterval {
			settingValues {
				preferences {
					changeDetectionInterval
				}
			}
		}
	`)

	onMount(async () => {
		const { data } = await changeDetectionIntervalQuery.fetch({ policy: 'CacheOrNetwork' })
		if (data?.settingValues?.preferences?.changeDetectionInterval) {
			intervalSeconds = data.settingValues.preferences.changeDetectionInterval
		}
	})

	onDestroy(() => {
		stopTimer()
	})
</script>

<Modal
	bind:show={showCollisonModal}
	title="Change Collision Detected!"
	confirmShown={false}
	cancelShown={false}
	backdropClickCancels={false}
>
	<p>Changes detected to {fullObjectName}. Your version is now out of date.</p>
	<p>You must discard unsaved changes and refresh the {autoRefreshObjectTitle} to continue.</p>

	<svelte:fragment slot="modalFooter">
		<Button
			id="refreshAndDiscardButton"
			color="danger"
			iconClass="refresh"
			class="w-100 mb-1"
			on:click={() => {
				showCollisonModal = false
				refresh()
			}}>Refresh Now and Discard Changes</Button
		>
		<Button
			outline
			color="danger"
			iconClass="cancel"
			class="w-100"
			on:click={() => {
				showCollisonModal = false
				stopTimer()
			}}
			>{#if verb && shouldUseVerb}Cancel {verb}{:else}Refresh Manually Later{/if}</Button
		>
	</svelte:fragment>
</Modal>
