<script lang="ts">
	import type { Mediator } from 'types/common'

	import LoadItemModal from '@isoftdata/svelte-load-item-modal'

	import { graphql, type InventoryLookup$result } from '$houdini'
	import { getContext } from 'svelte'

	export let chooseItem: (item: InventoryLookup$result['inventories']['items'][number]) => void | Promise<void>

	let loadPartModal: LoadItemModal<InventoryLookup$result['inventories']['items'][number]>

	const mediator = getContext<Mediator>('mediator')

	const inventoryLookupQuery = graphql(`
		query InventoryLookup($filter: InventoryFilter) {
			inventories(filter: $filter) {
				items {
					id
					store {
						id
						code
						name
					}
					tagNumber
					skuNumber
					inventoryType {
						id
						name
					}
				}
			}
		}
	`)

	export function show() {
		loadPartModal?.show()
	}
</script>

<LoadItemModal
	itemTitle="Parts by Tag Number"
	itemIdProp="id"
	itemDisplayProp="tagNumber"
	doSearch={async searchString => {
		const { data } = await inventoryLookupQuery.fetch({
			variables: {
				filter: {
					tagNumber: searchString,
				},
			},
		})
		return data?.inventories.items ?? []
	}}
	on:lookupError={({ detail: { error } }) =>
		mediator.publish('showMessage', {
			heading: 'Error Fetching Parts',
			message: error.message,
			type: 'danger',
			time: false,
		})}
	on:chooseItem={({ detail: { item } }) => chooseItem(item)}
	bind:this={loadPartModal}
	let:item
>
	<div class="w-100 d-flex justify-content-between">
		<h5 class="mb-1">{item.tagNumber}</h5>
		<h5
			class="mb-1"
			title={item.store.name}
		>
			Store {item.store.code}
		</h5>
	</div>
	<div class="w-100">{item.inventoryType?.id} - {item.inventoryType?.name}</div>
	<small>SKU #{item.skuNumber}</small>
</LoadItemModal>
