import type { LIB_partStoreData$result, PartScreenStageOne$result } from '$houdini'
import type { AppContext } from 'types/common'
import type { WritableDeep } from 'type-fest'

import type { OptionValueMap } from 'utility/load-part'

import { getOptionValueMap, loadPart, computeTypeFields } from 'utility/load-part'

import pProps from 'p-props'
import component from './Part.svelte'
import { getSession } from 'stores/session'
import { graphql } from '$houdini'
import loadModelsForManufacturer, { type PartModel } from 'utility/load-models-for-manufacturer'
import { klona } from 'klona'
import { stringToBoolean } from '@isoftdata/utility-string'
import ObjectMap from 'classes/ObjectMap'

import { getObject } from '@isoftdata/utility-storage'
import loadVehicleModelsForMake from 'utility/load-models-for-make'
import makeCallbackWritable from 'stores/callback-writable'

// #region Constants

// #endregion
export default function createState({ mediator, stateRouter, checkSessionPermission }: AppContext) {
	const stateName = 'app.part'

	stateRouter.addState({
		name: stateName,
		route: 'part/:storeId/:inventoryId',
		querystringParameters: ['inventoryId', 'storeId', 'lastSavedTime', 'lastResetTime', 'loadCachedPart'],
		defaultParameters: {
			inventoryId: null,
			storeId() {
				return getSession()?.currentStore?.toString() ?? null
			},
			tab: 'basic',
			lastResetTime: null, // only used to trigger a state reload
			loadCachedPart: 'false',
		},
		canLeaveState(domApi) {
			// @ts-expect-error it's fine
			if (domApi.partChanged) {
				return confirm('You have unsaved changes. Are you sure you want to leave?\n\nYour changes have been saved for later, click "Unsaved Part" in "Recent Activity" to resume your work.')
			}
			return true
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(data, { inventoryId, tab, ...params }) {
			if (!checkSessionPermission('PARTS_CAN_VIEW_PARTS')) {
				alert('You do not have permission to view parts.')
				throw {
					redirectTo: {
						name: 'app',
					},
				}
			}
			console.log('params', params)
			const storeId = parseInt(params.storeId, 10)
			const loadCachedPart = stringToBoolean(params.loadCachedPart)
			const hasCachedPart = !!getObject(localStorage, 'cachedPart') || false
			// if we don't have a cached part, but we're trying to load one, don't
			if (loadCachedPart && !hasCachedPart) {
				localStorage.removeItem('cachedPart')
				localStorage.removeItem('cachedOptionValues')
				localStorage.removeItem('cachedPartAttachments')
				void mediator.publish('removeActivity', 'Unsaved Part')
				throw {
					redirectTo: {
						name: null,
						params: {
							inventoryId,
							storeId,
							loadCachedPart: false,
						},
					},
				}
			}
			const innodbInventoryid = parseInt(inventoryId, 10) || null

			const { data: settingValuesData } = await settingValuesQuery.fetch({ policy: 'CacheOrNetwork' })

			if (!settingValuesData) {
				throw new Error('No data returned from settingValuesQuery')
			}

			const { settingValues, userSettings } = settingValuesData

			const { part, attachments } = await loadPart(innodbInventoryid, loadCachedPart, settingValues, userSettings, storeId)

			const { data: stageOneData }: { data: WritableDeep<PartScreenStageOne$result> | null } = await stageOneQuery.fetch({ policy: 'CacheOrNetwork' })

			if (!stageOneData) {
				throw new Error('No data returned from stageOneQuery')
			}

			const stageTwoLoads: {
				partStoreData: ReturnType<typeof partStoreDataQuery.fetch>
				trueModels?: Promise<Array<PartModel>>
				assyModels?: Promise<Array<PartModel>>
				vehicleModels?: Promise<Array<string>>
			} = {
				// locations at the store the part is at. Used when adding a new location to the part
				partStoreData: partStoreDataQuery.fetch({
					variables: {
						storeId: part.storeId,
					},
					policy: 'CacheOrNetwork',
				}),
			}

			if (part.manufacturer && part.inventoryTypeId) {
				stageTwoLoads.trueModels = loadModelsForManufacturer(part.manufacturer.id, part.inventoryTypeId)
			}

			if (part.parentManufacturer && part.inventoryType?.setId) {
				stageTwoLoads.assyModels = loadModelsForManufacturer(part.parentManufacturer.id, part.inventoryType.setId)
			}

			if (part.vehicleMake) {
				stageTwoLoads.vehicleModels = loadVehicleModelsForMake(part.vehicleMake)
			}

			const { partStoreData, trueModels, assyModels } = (await pProps(stageTwoLoads)) as {
				partStoreData: { data: LIB_partStoreData$result }
				trueModels?: Array<PartModel>
				assyModels?: Array<PartModel>
				vehicleModels?: Array<string>
			}

			// It's technically possible to have a cached part and not a cached optionValueMap
			const cachedOptionValueMap = getObject<{
				keyParts: Array<'optionId' | 'serialId' | 'serialUuid'>
				entries: ReturnType<OptionValueMap['entries']>
			}>(localStorage, 'cachedOptionValues')
			const optionValueMap = loadCachedPart && cachedOptionValueMap ? new ObjectMap(cachedOptionValueMap.keyParts, cachedOptionValueMap.entries) : getOptionValueMap(part)

			part.glCategory = stageOneData.glCategories.find(cat => cat.id === part.glCategory?.id) ?? null
			part.saleClass = stageOneData.saleClasses.find(saleClass => saleClass.code === part.saleClass?.code) ?? part.saleClass

			part.userStatus ||= null
			part.coreClass ||= null

			const res = {
				clearScreenOnSave: makeCallbackWritable(userSettings.parts.clearScreenOnSave, async clearScreenOnSave => {
					try {
						await setUserSetting.mutate({ input: { parts: { clearScreenOnSave } } })
					} catch (err) {
						console.error('Error setting user setting', err)
					}
				}),
				settingValues,
				userSettings,
				partStoreLocations: partStoreData.data?.locations ?? [],
				clearLocationsOnCopy: partStoreData.data?.store?.settingValues.parts.clearLocationsOnCopy ?? true,
				origPart: klona(part),
				part,
				attachments,
				optionValueMap,
				loadCachedPart,
				partChanged: loadCachedPart,
				sellPriceClasses: stageOneData.sellPriceClasses.sort((a, b) => {
					const aDisplayName = a.parent?.name ? `${a.parent.name} - ${a.name}` : a.name
					const bDisplayName = b.parent?.name ? `${b.parent.name} - ${b.name}` : b.name
					return aDisplayName.localeCompare(bDisplayName)
				}),
				trueModels: trueModels ?? [],
				assyModels: assyModels ?? [],
				storeId,
				tab,
				typeFields: computeTypeFields(part),
				glCategories: stageOneData.glCategories,
				inventoryConditions: stageOneData.inventoryConditions.filter(Boolean),
				inventoryTypeList: stageOneData.inventoryTypeList.map(type => ({
					...type,
					manufacturerList: type?.partManufacturers.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
				})),
				saleClasses: stageOneData.saleClasses,
				userPartStatusList: stageOneData.userPartStatusList,
				vehicleMakes: stageOneData.vehicleMakes,
				// maybe we could filter this better on the server, idk
				vendorList: stageOneData.vendors.items.filter(vendor => vendor?.code ?? vendor?.companyName ?? vendor?.contactName) ?? [],
			}
			console.log('resolve', res)
			return res
		},
	})
}

const settingValuesQuery = graphql(`
	query LIB_settingValues {
		userSettings {
			parts {
				clearScreenOnSave
				defaultNewInventoryPrintTags
				showSkuField
				showTagNumberWhenCreating
			}
		}
		settingValues {
			inventory {
				defaultNonReplenishablePartsArePublic
				categoryEditable
				categoryRequired
				categoryVisible
				categoryLogsChanges
				conditionEditable
				conditionRequired
				conditionVisible
				conditionLogsChanges
				coreCostEditable
				coreCostRequired
				coreCostVisible
				coreCostLogsChanges
				costEditable
				costRequired
				costVisible
				costLogsChanges
				defaultDaysToReturn
				defaultDaysToReturnCore
				defaultDaysToReturnCoreToVendor
				defaultDaysToReturnToVendor
				defaultLocationName
				defaultQuantityForMisc
				defaultQuantityForReplenishable
				defaultQuantityForStandard
				defaultReplenishablePartsArePublic
				defaultReturnable
				defaultGlCategoryId
				defaultNonReplenishablePartsArePublic
				defaultReturnableToVendor
				distributorCorePriceEditable
				distributorCorePriceRequired
				distributorCorePriceVisible
				distributorCorePriceLogsChanges
				distributorPriceEditable
				distributorPriceRequired
				distributorPriceVisible
				distributorPriceLogsChanges
				glCategoryEditable
				glCategoryRequired
				glCategoryVisible
				glCategoryLogsChanges
				inventoryTypeEditable
				inventoryTypeRequired
				inventoryTypeVisible
				inventoryTypeLogsChanges
				jobberCorePriceEditable
				jobberCorePriceRequired
				jobberCorePriceVisible
				jobberCorePriceLogsChanges
				jobberPriceEditable
				jobberPriceRequired
				jobberPriceVisible
				jobberPriceLogsChanges
				listPriceEditable
				listPriceRequired
				listPriceVisible
				listPriceLogsChanges
				oemNumberEditable
				oemNumberRequired
				oemNumberVisible
				oemNumberLogsChanges
				retailPriceEditable
				retailPriceRequired
				retailPriceVisible
				retailPriceLogsChanges
				retailCorePriceEditable
				retailCorePriceRequired
				retailCorePriceVisible
				retailCorePriceLogsChanges
				sideEditable
				sideRequired
				sideVisible
				sideLogsChanges
				userStatusEditable
				userStatusRequired
				userStatusVisible
				userStatusLogsChanges
				varianceLocationName # todo get for part store
				vendorEditable
				vendorRequired
				vendorVisible
				vendorLogsChanges
				wholesalePriceEditable
				wholesalePriceRequired
				wholesalePriceVisible
				wholesalePriceLogsChanges
				wholesaleCorePriceEditable
				wholesaleCorePriceRequired
				wholesaleCorePriceVisible
				wholesaleCorePriceLogsChanges
			}
			location {
				enforceLocationHierarchy
			}
			accounting {
				useGlAccounting
			}
		}
	}
`)

const stageOneQuery = graphql(`
	query PartScreenStageOne {
		inventoryConditions
		userPartStatusList: userStatuses(type: INVENTORY) {
			type
			status
			trigger
			partStatus
			description
		}
		inventoryTypeList: inventoryTypes(filter: { active: true }) {
			inventoryTypeId: id
			typeSetId: setId
			name
			vehicle
			categories {
				id
				name
				description
			}
			partManufacturers: manufacturers {
				id
				name
				code
			}
			requireSerialization
		}
		glCategories {
			id
			name
		}
		vehicleMakes
		vendors(pagination: { pageSize: 0 }) {
			items {
				id
				# active
				code
				companyName
				contactName
				# stockVendor
			}
		}
		sellPriceClasses {
			id
			name
			parent {
				id
				name
			}
		}
		saleClasses {
			code
			name
		}
	}
`)

const partStoreDataQuery = graphql(`
	query LIB_partStoreData($storeId: Int!) {
		locations(filter: { allowInventory: true, storeIds: [$storeId] }) {
			id
			name
			description
		}
		store(id: $storeId) {
			settingValues {
				parts {
					clearLocationsOnCopy
				}
			}
		}
	}
`)

const setUserSetting = graphql(`
	mutation Parts_SetUserSetting($input: SetUserSettingInput!) {
		setUserSetting(input: $input)
	}
`)
