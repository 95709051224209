import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LIB_defaultPricing'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LIB_defaultPricingStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LIB_defaultPricingStore",
			variables: true,
		})
	}
}

export async function load_LIB_defaultPricing(params) {
	await initClient()

	const store = new LIB_defaultPricingStore()

	await store.fetch(params)

	return {
		LIB_defaultPricing: store,
	}
}
