export default {
    "name": "UpdateVehicle",
    "kind": "HoudiniMutation",
    "hash": "bc881332a83e307e181dd7bd6cb280d1f25188e4c2e450a24f23f9731c01cddf",

    "raw": `mutation UpdateVehicle($input: VehicleUpdate!) {
  updateVehicle(input: $input) {
    id
    storeId
    lastChangedDate
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateVehicle": {
                "type": "Vehicle",
                "keyRaw": "updateVehicle(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "storeId": {
                            "type": "UInt",
                            "keyRaw": "storeId",
                            "visible": true
                        },

                        "lastChangedDate": {
                            "type": "DateTime",
                            "keyRaw": "lastChangedDate",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "VehicleUpdate"
        },

        "types": {
            "InventoryOptionValueUpdate": {
                "inventoryOptionId": "Int",
                "value": "String"
            },

            "SaleClassReference": {
                "code": "String",
                "id": "Int"
            },

            "VehicleUpdate": {
                "adjuster": "String",
                "bodyStyle": "String",
                "category": "String",
                "claimNumber": "String",
                "condition": "String",
                "description": "String",
                "dismantleDate": "Date",
                "dismantled": "Boolean",
                "dismantler": "UInt",
                "externalColor": "String",
                "externalColorCode": "String",
                "fileNumber": "String",
                "glCategoryId": "Int",
                "id": "UInt",
                "internalColor": "String",
                "internalColorCode": "String",
                "inventoryGlCategoryId": "Int",
                "jobberPrice": "Decimal2",
                "junkingNumber": "String",
                "listPrice": "Decimal2",
                "location": "String",
                "make": "String",
                "mileage": "UInt",
                "model": "String",
                "notes": "String",
                "optionValues": "InventoryOptionValueUpdate",
                "pickUpLocation": "String",
                "public": "Boolean",
                "purchaseDate": "Date",
                "purchasedFrom": "String",
                "receivedDate": "Date",
                "retailPrice": "Decimal2",
                "saleClass": "SaleClassReference",
                "sellerContact": "String",
                "sellerInfo": "String",
                "sellerPhone": "String",
                "status": "StatusEnum",
                "stockNumber": "String",
                "taxable": "Boolean",
                "titleNumber": "String",
                "titleReceivedDate": "DateTime",
                "titleState": "String",
                "titleStatus": "String",
                "titleType": "String",
                "userStatus": "String",
                "vin": "String",
                "wholesalePrice": "Decimal2",
                "year": "UInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=6fb7e3572feb40f822714f6bb81326f49ff2f87e808f90ae9769925a3c3a6963";