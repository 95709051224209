export default {
    "name": "UserAccounts",
    "kind": "HoudiniQuery",
    "hash": "fecc95352d88217cb38e24ef4ccd3e9ee7f31af05803b378d3503194b2035909",

    "raw": `query UserAccounts($status: UserAccountStatusEnum) {
  userAccounts(status: $status) {
    id
    name
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccounts": {
                "type": "UserAccount",
                "keyRaw": "userAccounts(status: $status)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "status": "UserAccountStatusEnum"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1a25446e06694d872bb2c339bacf5dafe4cdf8bf2718f643e1822cde4b6f8eb2";