export default {
    "name": "VehicleSearchData",
    "kind": "HoudiniQuery",
    "hash": "e00c1e0cb9f5f87fbabc5c266d7c22db77cbd9a64744e6f309eb86f257dab60a",

    "raw": `query VehicleSearchData {
  session {
    userAccount {
      authorizedStores {
        id
        name
        code
      }
      settings {
        searchVehicles {
          pinnedSearchFields
        }
      }
      id
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",

                            "selection": {
                                "fields": {
                                    "authorizedStores": {
                                        "type": "Store",
                                        "keyRaw": "authorizedStores",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "settings": {
                                        "type": "UserSettings",
                                        "keyRaw": "settings",

                                        "selection": {
                                            "fields": {
                                                "searchVehicles": {
                                                    "type": "UserSettingsSearchVehiclesCategory",
                                                    "keyRaw": "searchVehicles",

                                                    "selection": {
                                                        "fields": {
                                                            "pinnedSearchFields": {
                                                                "type": "String",
                                                                "keyRaw": "pinnedSearchFields",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=909d69a34524de7011115ac4ba804d9f163593127558613b75ffb4fdcdf49bc6";