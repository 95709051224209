export default {
    "name": "InventoryMessages",
    "kind": "HoudiniQuery",
    "hash": "1ca723c32d278cbbc46b60bde3b10606267507c5305b0985d0e866df076170d2",

    "raw": `query InventoryMessages($inventoryId: UInt!) {
  inventory(id: $inventoryId) {
    messages {
      type
      trigger
      text
      display
      id
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventory": {
                "type": "Inventory",
                "keyRaw": "inventory(id: $inventoryId)",

                "selection": {
                    "fields": {
                        "messages": {
                            "type": "Message",
                            "keyRaw": "messages",

                            "selection": {
                                "fields": {
                                    "type": {
                                        "type": "MessageType",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "trigger": {
                                        "type": "String",
                                        "keyRaw": "trigger",
                                        "visible": true
                                    },

                                    "text": {
                                        "type": "String",
                                        "keyRaw": "text",
                                        "visible": true
                                    },

                                    "display": {
                                        "type": "MessageDisplay",
                                        "keyRaw": "display",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryId": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1761e1d223d75dd4771a352cd08384ff809de2f773ac44ebc5bca150c29416e8";