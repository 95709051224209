export default {
    "name": "VehicleLookup",
    "kind": "HoudiniQuery",
    "hash": "a782cc525bfbc19c07f1bfd2c4c2f0045ed9dc3b09ef43c98749be62744075ef",

    "raw": `query VehicleLookup($filter: VehicleFilter) {
  vehicles(filter: $filter) {
    items {
      bodyStyle
      id
      manufacturer {
        id
        name
      }
      model {
        id
        name
      }
      make
      year
      stockNumber
      store {
        code
        name
        id
      }
      topImage {
        name
        url
        id
      }
      vin
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vehicles": {
                "type": "VehicleList",
                "keyRaw": "vehicles(filter: $filter)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "Vehicle",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "bodyStyle": {
                                        "type": "String",
                                        "keyRaw": "bodyStyle",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "manufacturer": {
                                        "type": "Manufacturer",
                                        "keyRaw": "manufacturer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "model": {
                                        "type": "Model",
                                        "keyRaw": "model",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "make": {
                                        "type": "String",
                                        "keyRaw": "make",
                                        "visible": true
                                    },

                                    "year": {
                                        "type": "UInt",
                                        "keyRaw": "year",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "stockNumber": {
                                        "type": "String",
                                        "keyRaw": "stockNumber",
                                        "visible": true
                                    },

                                    "store": {
                                        "type": "Store",
                                        "keyRaw": "store",

                                        "selection": {
                                            "fields": {
                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "topImage": {
                                        "type": "File",
                                        "keyRaw": "topImage",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "url": {
                                                    "type": "String",
                                                    "keyRaw": "url",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "vin": {
                                        "type": "String",
                                        "keyRaw": "vin",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "VehicleFilter"
        },

        "types": {
            "StringFilter": {
                "eq": "String",
                "gt": "String",
                "gte": "String",
                "lt": "String",
                "lte": "String",
                "ne": "String"
            },

            "InventoryFieldFilter": {
                "name": "String",
                "value": "StringFilter"
            },

            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "Decimal2Filter": {
                "eq": "Decimal2",
                "gt": "Decimal2",
                "gte": "Decimal2",
                "lt": "Decimal2",
                "lte": "Decimal2"
            },

            "IntFilter": {
                "eq": "Int",
                "gt": "Int",
                "gte": "Int",
                "lt": "Int",
                "lte": "Int",
                "ne": "Int"
            },

            "VehicleFilter": {
                "adjuster": "String",
                "bodyStyle": "String",
                "category": "String",
                "claimNumber": "String",
                "condition": "String",
                "customFields": "InventoryFieldFilter",
                "dateEntered": "DateFilter",
                "description": "String",
                "dismantled": "Boolean",
                "dismantlerId": "UInt",
                "enteredByUserId": "UInt",
                "externalColor": "String",
                "fileNumber": "String",
                "junkingNumber": "String",
                "location": "String",
                "make": "String",
                "manufacturerId": "UInt",
                "model": "String",
                "modelId": "UInt",
                "pickupLocation": "String",
                "purchasedFrom": "String",
                "retailPrice": "Decimal2Filter",
                "search": "String",
                "sellerContact": "String",
                "sellerInfo": "String",
                "series": "String",
                "statuses": "String",
                "stockNumber": "String",
                "stores": "Int",
                "titleNumber": "String",
                "titleStatus": "String",
                "titleType": "String",
                "type": "String",
                "typeField1": "String",
                "typeField2": "String",
                "typeField3": "String",
                "typeField4": "String",
                "typeIds": "Int",
                "userStatuses": "String",
                "vehicleId": "UInt",
                "vin": "String",
                "year": "IntFilter"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b462487491526000f42aa826408e56268021a7e58021315187bf28539e242fbf";