export default {
    "name": "InventoryConditions",
    "kind": "HoudiniQuery",
    "hash": "fb4e85e7c3992aa18b49104333006fd23f6691b2d020adc4f0d63659fd3daf06",

    "raw": `query InventoryConditions {
  inventoryConditions
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryConditions": {
                "type": "String",
                "keyRaw": "inventoryConditions",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=a60ac494e9eb219f4c17a66776c473bd39cba89a04f0fcffe30ee14ee8c71546";