export default {
    "name": "VehicleManufacturers",
    "kind": "HoudiniQuery",
    "hash": "10dab93a42408c402f027a7608bda28ac123d3d3a390beceb38fe751c6b6957e",

    "raw": `query VehicleManufacturers {
  vehicleManufacturers {
    name
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "vehicleManufacturers": {
                "type": "Manufacturer",
                "keyRaw": "vehicleManufacturers",

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=f9dc1c6566950e7b12f070e77202d1ea0ba8fc14be5073e28ffb053a449a45b0";