export default {
    "name": "GetPartDateModified",
    "kind": "HoudiniQuery",
    "hash": "81b78059770bbb68f7d9bfd63fbd59a483e02e0bc875e98bfa4f2a4c3525947b",

    "raw": `query GetPartDateModified($innodbInventoryid: UInt!) {
  inventory(id: $innodbInventoryid) {
    dateModified
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventory": {
                "type": "Inventory",
                "keyRaw": "inventory(id: $innodbInventoryid)",

                "selection": {
                    "fields": {
                        "dateModified": {
                            "type": "DateTime",
                            "keyRaw": "dateModified",
                            "nullable": true,
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "innodbInventoryid": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=8d797e436dbb8e18f63bb0dde424f19867ba354dce2ed0f629dddc4a9bf1f0f4";