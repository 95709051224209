export default {
    "name": "InventoryLookup",
    "kind": "HoudiniQuery",
    "hash": "4368cb3a44ef07de608a08c53719215426998a6d2a791681da74b878981778e7",

    "raw": `query InventoryLookup($filter: InventoryFilter) {
  inventories(filter: $filter) {
    items {
      id
      store {
        id
        code
        name
      }
      tagNumber
      skuNumber
      inventoryType {
        id
        name
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventories": {
                "type": "InventoryList",
                "keyRaw": "inventories(filter: $filter)",

                "selection": {
                    "fields": {
                        "items": {
                            "type": "Inventory",
                            "keyRaw": "items",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "store": {
                                        "type": "Store",
                                        "keyRaw": "store",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "tagNumber": {
                                        "type": "String",
                                        "keyRaw": "tagNumber",
                                        "visible": true
                                    },

                                    "skuNumber": {
                                        "type": "UInt",
                                        "keyRaw": "skuNumber",
                                        "visible": true
                                    },

                                    "inventoryType": {
                                        "type": "InventoryType",
                                        "keyRaw": "inventoryType",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "InventoryFilter"
        },

        "types": {
            "StringFilter": {
                "eq": "String",
                "gt": "String",
                "gte": "String",
                "lt": "String",
                "lte": "String",
                "ne": "String"
            },

            "InventoryFieldFilter": {
                "name": "String",
                "value": "StringFilter"
            },

            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "Decimal2Filter": {
                "eq": "Decimal2",
                "gt": "Decimal2",
                "gte": "Decimal2",
                "lt": "Decimal2",
                "lte": "Decimal2"
            },

            "IntFilter": {
                "eq": "Int",
                "gt": "Int",
                "gte": "Int",
                "lt": "Int",
                "lte": "Int",
                "ne": "Int"
            },

            "Decimal6Filter": {
                "eq": "Decimal6",
                "gt": "Decimal6",
                "gte": "Decimal6",
                "lt": "Decimal6",
                "lte": "Decimal6",
                "ne": "Decimal6"
            },

            "SmartSearchFilter": {
                "keyword": "String",
                "moduleIds": "Int"
            },

            "InventoryFilter": {
                "additionalFields": "InventoryFieldFilter",
                "assemblyManufacturerId": "Int",
                "assemblyModelId": "Int",
                "category": "String",
                "condition": "String",
                "customFields": "InventoryFieldFilter",
                "dateSold": "DateFilter",
                "defaultVendorCode": "String",
                "defaultVendorId": "Int",
                "description": "String",
                "distributorCorePrice": "Decimal2Filter",
                "distributorPrice": "Decimal2Filter",
                "enteredByUser": "IntFilter",
                "ids": "Int",
                "inventoryTypeId": "IntFilter",
                "isCore": "Boolean",
                "jobberCorePrice": "Decimal2Filter",
                "jobberPrice": "Decimal2Filter",
                "keyword": "String",
                "location": "String",
                "manufacturerId": "Int",
                "modelId": "Int",
                "notes": "String",
                "partNumber": "String",
                "partNumberStripped": "String",
                "quantity": "Decimal6Filter",
                "quantityAvailable": "Decimal6Filter",
                "quantityOnHold": "Decimal6Filter",
                "quantityOnOrder": "Decimal6Filter",
                "replenishable": "Boolean",
                "shippingHeight": "Decimal2Filter",
                "shippingLength": "Decimal2Filter",
                "shippingWidth": "Decimal6Filter",
                "side": "SideEnum",
                "skuNumber": "Int",
                "smartSearchFilters": "SmartSearchFilter",
                "statuses": "InventorySearchStatus",
                "stores": "Int",
                "tagNumber": "String",
                "typeField1": "String",
                "typeField2": "String",
                "typeField3": "String",
                "typeField4": "String",
                "userStatus": "String",
                "vehicleId": "Int",
                "vehicleManufacturerId": "Int",
                "vehicleModelId": "Int",
                "vendorLeadTime": "IntFilter",
                "vin": "String",
                "weight": "Decimal6Filter",
                "year": "IntFilter"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ee6cc966b7a42a90ae08439bab35fb62414a906f176897c35a8299c105f2a1c4";