export default {
    "name": "PartSearchData",
    "kind": "HoudiniQuery",
    "hash": "eb61ae6578286a6b86cff2ef21bbf71cdd7dfcab622e4dd5d87a5194984bebe6",

    "raw": `query PartSearchData($userAccountId: Int!, $filter: InventoryFieldsFilter!) {
  userAccount(id: $userAccountId) {
    authorizedStores {
      id
      name
      code
    }
    id
  }
  session {
    userAccount {
      searchModules {
        default
        module {
          id
          name
        }
      }
      settings {
        searchParts {
          pinnedSearchFields
        }
      }
      id
    }
    id
  }
  inventoryFields(filter: $filter) {
    id
    displayName
    fieldType
    fieldOptions
    searchByRange
    searchMode
    fieldName
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccount": {
                "type": "UserAccount",
                "keyRaw": "userAccount(id: $userAccountId)",

                "selection": {
                    "fields": {
                        "authorizedStores": {
                            "type": "Store",
                            "keyRaw": "authorizedStores",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",

                            "selection": {
                                "fields": {
                                    "searchModules": {
                                        "type": "UserSearchModel",
                                        "keyRaw": "searchModules",

                                        "selection": {
                                            "fields": {
                                                "default": {
                                                    "type": "Boolean",
                                                    "keyRaw": "default",
                                                    "visible": true
                                                },

                                                "module": {
                                                    "type": "SearchModule",
                                                    "keyRaw": "module",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "Int",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "settings": {
                                        "type": "UserSettings",
                                        "keyRaw": "settings",

                                        "selection": {
                                            "fields": {
                                                "searchParts": {
                                                    "type": "UserSettingsSearchPartsCategory",
                                                    "keyRaw": "searchParts",

                                                    "selection": {
                                                        "fields": {
                                                            "pinnedSearchFields": {
                                                                "type": "String",
                                                                "keyRaw": "pinnedSearchFields",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "inventoryFields": {
                "type": "InventoryField",
                "keyRaw": "inventoryFields(filter: $filter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "displayName": {
                            "type": "String",
                            "keyRaw": "displayName",
                            "visible": true
                        },

                        "fieldType": {
                            "type": "InventoryFieldTypeEnum",
                            "keyRaw": "fieldType",
                            "visible": true
                        },

                        "fieldOptions": {
                            "type": "String",
                            "keyRaw": "fieldOptions",
                            "visible": true
                        },

                        "searchByRange": {
                            "type": "Boolean",
                            "keyRaw": "searchByRange",
                            "visible": true
                        },

                        "searchMode": {
                            "type": "InventoryFieldSearchModeEnum",
                            "keyRaw": "searchMode",
                            "visible": true
                        },

                        "fieldName": {
                            "type": "String",
                            "keyRaw": "fieldName",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userAccountId": "Int",
            "filter": "InventoryFieldsFilter"
        },

        "types": {
            "InventoryFieldsFilter": {
                "global": "Boolean",
                "searchable": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b75a5f2cc8e28ebe72cc58953c2bd0a0b707351612b2384948cc4f792682d072";