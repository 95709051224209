export default {
    "name": "UpdateInventoryType",
    "kind": "HoudiniMutation",
    "hash": "efa6c82938b07695a562ac12af9260503bf99065c0ead45a852005eea1f70d63",

    "raw": `mutation UpdateInventoryType($input: InventoryTypeUpdate!) {
  updateInventoryType(input: $input) {
    active
    id
    name
    setId
    vehicle
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateInventoryType": {
                "type": "InventoryType",
                "keyRaw": "updateInventoryType(input: $input)",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "setId": {
                            "type": "Int",
                            "keyRaw": "setId",
                            "nullable": true,
                            "visible": true
                        },

                        "vehicle": {
                            "type": "Boolean",
                            "keyRaw": "vehicle",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "InventoryTypeUpdate"
        },

        "types": {
            "InventoryTypeUpdate": {
                "active": "Boolean",
                "id": "Int",
                "name": "String",
                "partListAuthorityId": "Int",
                "pickable": "Boolean",
                "publicAllowed": "Boolean",
                "requireSerialization": "Boolean",
                "setId": "Int",
                "taxCode": "String",
                "typeLabel1": "String",
                "typeLabel2": "String",
                "typeLabel3": "String",
                "typeLabel4": "String",
                "useManufacturer": "Boolean",
                "vehicle": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=4f23e3198bc7831a7c30f6edd471cc25a755d800f22cb4da71a3ce05d2225a91";