export default {
    "name": "vehicleInventorySettingValues",
    "kind": "HoudiniQuery",
    "hash": "38138ba132b6c993bf0b0a180ebdc691028d814c1115aced9670c29252d2afda",

    "raw": `query vehicleInventorySettingValues {
  settingValues {
    inventory {
      categoryRequired
      conditionRequired
      costVisible
      costEditable
      costRequired
      coreCostVisible
      coreCostEditable
      coreCostRequired
      distributorPriceVisible
      distributorPriceEditable
      distributorCorePriceVisible
      distributorCorePriceEditable
      distributorPriceRequired
      jobberPriceVisible
      jobberPriceEditable
      jobberPriceRequired
      jobberCorePriceVisible
      jobberCorePriceEditable
      jobberCorePriceRequired
      wholesalePriceVisible
      wholesalePriceEditable
      wholesalePriceRequired
      wholesaleCorePriceVisible
      wholesaleCorePriceEditable
      wholesaleCorePriceRequired
      retailPriceVisible
      retailPriceEditable
      retailPriceRequired
      retailCorePriceVisible
      retailCorePriceEditable
      retailCorePriceRequired
      listPriceVisible
      listPriceEditable
      listPriceRequired
    }
    vehicle {
      mileageRequired
      minVinLength
      userStatusRequired
      conditionRequired
      defaultVehicleType
      unitsAreTaxableByDefault
      defaultGlCategory {
        id
        name
      }
      defaultComponentGlCategory {
        id
        name
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "settingValues": {
                "type": "SettingValues",
                "keyRaw": "settingValues",

                "selection": {
                    "fields": {
                        "inventory": {
                            "type": "InventorySettingValues",
                            "keyRaw": "inventory",

                            "selection": {
                                "fields": {
                                    "categoryRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "categoryRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "conditionRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "costVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "costEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "costRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "coreCostRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "coreCostRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "distributorPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "distributorPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobberCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "jobberCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesalePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesalePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "wholesaleCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "wholesaleCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "retailPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retailCorePriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "retailCorePriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceVisible": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceVisible",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceEditable": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceEditable",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "listPriceRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "listPriceRequired",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "vehicle": {
                            "type": "VehicleSettingValues",
                            "keyRaw": "vehicle",

                            "selection": {
                                "fields": {
                                    "mileageRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "mileageRequired",
                                        "visible": true
                                    },

                                    "minVinLength": {
                                        "type": "Int",
                                        "keyRaw": "minVinLength",
                                        "visible": true
                                    },

                                    "userStatusRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "userStatusRequired",
                                        "visible": true
                                    },

                                    "conditionRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "conditionRequired",
                                        "visible": true
                                    },

                                    "defaultVehicleType": {
                                        "type": "Int",
                                        "keyRaw": "defaultVehicleType",
                                        "visible": true
                                    },

                                    "unitsAreTaxableByDefault": {
                                        "type": "Boolean",
                                        "keyRaw": "unitsAreTaxableByDefault",
                                        "visible": true
                                    },

                                    "defaultGlCategory": {
                                        "type": "GlCategory",
                                        "keyRaw": "defaultGlCategory",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "defaultComponentGlCategory": {
                                        "type": "GlCategory",
                                        "keyRaw": "defaultComponentGlCategory",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true,
                                                    "nullable": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=0afd053328392be344d99ad20251d2c848081a0c468d199ed82268cb8be3fa43";