export default {
    "name": "Inventory",
    "kind": "HoudiniQuery",
    "hash": "74af786eeeeb16049db4b8a54b5fefaa145bf31e7255a031e341e50cce83660f",

    "raw": `query Inventory($inventorySerialId: Int!) {
  inventorySerial(id: $inventorySerialId) {
    number
    inventory {
      id
      storeId
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventorySerial": {
                "type": "InventorySerial",
                "keyRaw": "inventorySerial(id: $inventorySerialId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "number": {
                            "type": "String",
                            "keyRaw": "number",
                            "visible": true
                        },

                        "inventory": {
                            "type": "Inventory",
                            "keyRaw": "inventory",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "storeId": {
                                        "type": "UInt",
                                        "keyRaw": "storeId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventorySerialId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1a70a58fc298d018eb9de911e31fad47eee9b7a0d843b76a7442d27836be6ed3";