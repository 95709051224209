export default {
    "name": "BreakdownTemplates",
    "kind": "HoudiniQuery",
    "hash": "08a47599234640d895a03078a0e3667f8f8d1a6a0f09f49968f48fefdb47a642",

    "raw": `query BreakdownTemplates($inventoryTypeId: Int!) {
  inventoryType(id: $inventoryTypeId) {
    breakdownTemplates {
      components {
        integral
        inventoryTypeId
        quantity
        rank
      }
      default
      id
      name
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryType": {
                "type": "InventoryType",
                "keyRaw": "inventoryType(id: $inventoryTypeId)",

                "selection": {
                    "fields": {
                        "breakdownTemplates": {
                            "type": "BreakdownTemplate",
                            "keyRaw": "breakdownTemplates",

                            "selection": {
                                "fields": {
                                    "components": {
                                        "type": "BreakdownTemplateComponent",
                                        "keyRaw": "components",

                                        "selection": {
                                            "fields": {
                                                "integral": {
                                                    "type": "Boolean",
                                                    "keyRaw": "integral",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "inventoryTypeId": {
                                                    "type": "UInt",
                                                    "keyRaw": "inventoryTypeId",
                                                    "visible": true
                                                },

                                                "quantity": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "quantity",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "Int",
                                                    "keyRaw": "rank",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "default": {
                                        "type": "Boolean",
                                        "keyRaw": "default",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryTypeId": "Int"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=71c067f5753ad1a4d476cce03ee2568e99b21e39dbffde83fe40a16980a35c7a";