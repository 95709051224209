import type { AppContext, ResolveParameters } from 'types/common'
import type { SortDirection } from '@isoftdata/svelte-table'
import type { ComponentAllocationsSort$options } from '$houdini'

import { graphql } from '$houdini'
import component from './History.svelte'

const vehicleComponentsQuery = graphql(`
	query VehicleHistoryTabComponents(
		$vehicleId: Int!
		$componentsFilter: InventoryFilter
		$componentAllocationsFilter: ComponentAllocationsFilter
		$pagination: PaginationOptions
		$orderBy: [ComponentAllocationsSort!]
	) {
		vehicle(id: $vehicleId) {
			components(filter: $componentsFilter) {
				id
				status
				storeId
				tagNumber
				quantityOnHand: quantity
				quantityAvailable
				allocatedVehicleCost
				retailPrice
				wholesalePrice
				retailCorePrice
				inventoryType {
					name
				}
				manufacturer {
					name
				}
				model {
					name
				}
				category {
					name
				}
				averageCost
			}
			componentAllocations(filter: $componentAllocationsFilter, pagination: $pagination, orderBy: $orderBy) {
				items {
					documentNumber
					documentType
					documentDate
					documentLine {
						... on SalesOrderLine {
							id
							lookup
							quantity
							cost
							price
							inventory {
								id
								tagNumber
								category {
									id
									name
								}
							}
							inventoryType {
								id
								name
							}
							manufacturer {
								id
								name
							}
							model {
								id
								name
							}
						}
						... on JobPart {
							id
							lookup
							quantity
							cost
							price
							inventory {
								id
								tagNumber
								category {
									id
									name
								}
							}
							inventoryType {
								id
								name
							}
							manufacturer {
								id
								name
							}
							model {
								id
								name
							}
						}
					}
				}
				pageInfo {
					pageNumber
					totalPages
				}
				totalItems
			}
			consumedCost
			remainingCost
			unallocatedCost
			revenue
			totalCost
			netProfit
		}
	}
`)

const allocatedPartsColumnMap = {
	'documentLine[lookup]': 'lookup',
	documentType: 'documentType',
	documentDate: 'documentDate',
	documentNumber: 'documentNumber',
	'documentLine[quantity]': 'quantity',
	'documentLine[cost]': 'cost',
	'documentLine[price]': 'price',
	'documentLine[inventoryType][name]': null,
	'documentLine[manufacturer][name]': 'manufacturerName',
	'documentLine[model][name]': 'modelName',
	'documentLine[inventory][category][name]': 'categoryName',
} as const

type AllowedPartsColumn = keyof typeof allocatedPartsColumnMap
type AllowedPartsColumnValue = (typeof allocatedPartsColumnMap)[AllowedPartsColumn]

function isAllowedPartsColumn(value: string): value is AllowedPartsColumn {
	return value in allocatedPartsColumnMap
}

interface Parameters {
	vehicleId: string
	orderByColumn?: string
	orderByDirection?: SortDirection
}

export default function (appContext: AppContext) {
	const stateName = 'app.vehicle.history'

	appContext.stateRouter.addState({
		name: stateName,
		route: 'history',
		querystringParameters: ['vehicleId', 'orderByColumn', 'orderByDirection'],
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, { vehicleId: vehicleIdParameter, orderByColumn, orderByDirection }: ResolveParameters<Parameters>) {
			const vehicleId = parseInt(vehicleIdParameter, 10) ?? undefined

			if (!vehicleId) {
				throw { redirectTo: { name: 'app.vehicle' } }
			}

			const formattedOrderByColumn: AllowedPartsColumnValue | null = isAllowedPartsColumn(orderByColumn) ? allocatedPartsColumnMap[orderByColumn] : null
			const orderByDirectionTyped = orderByDirection === 'DESC' ? 'DESC' : 'ASC'
			const orderBy: ComponentAllocationsSort$options | undefined = formattedOrderByColumn ? `${formattedOrderByColumn}_${orderByDirectionTyped}` : undefined

			const result = await vehicleComponentsQuery.fetch({
				variables: {
					vehicleId,
					componentsFilter: {
						quantity: {
							gt: '0',
						},
						statuses: ['A', 'C', 'H', 'T'],
					},
					componentAllocationsFilter: {
						consumed: true,
					},
					pagination: {
						pageNumber: 1,
						pageSize: 10,
					},
					orderBy: orderBy ? [orderBy] : undefined,
				},
			})

			return {
				availableParts: result.data?.vehicle?.components ?? [],
				allocatedParts: result.data?.vehicle?.componentAllocations?.items ?? [],
				revenue: result.data?.vehicle?.revenue,
				totalCost: result.data?.vehicle?.totalCost,
				netProfit: result.data?.vehicle?.netProfit,
				remainingCost: result.data?.vehicle?.remainingCost,
				unallocatedCost: result.data?.vehicle?.remainingCost,
				consumedCost: result.data?.vehicle?.consumedCost,
				stateName,
				mediator: appContext.mediator,
				orderByColumn,
				orderByDirection,
			}
		},
	})
}
